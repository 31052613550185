// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { PBSLink } from '../link';
import { Button } from '../button';
import { useModal } from '../../../contexts/modal-context';
import { useDrawer } from '../../../contexts/drawer-context';

const CTAbuttonsCA = ({
	primaryLinkCopy,
	primaryLink,
	primaryLinkOpenForm,
	secondaryLinkCopy,
	secondaryLink,
	modalRef,
	useModalForm,
	drawerRef,
}) => {
	const { setModalRef } = useModal();
	const { setDrawerRef } = useDrawer();

	function checkLinkType(link, linkCopy, type) {
		if (primaryLinkOpenForm === true && type === 'primary') {
			return useModalForm ? (
				<Button
					onClick={() => {
						setModalRef(modalRef);
					}}
					className="w-max transition delay-150 duration-1000 ease-in-out transition-colors"
				>
					{linkCopy}
				</Button>
			) : (
				<Button
					onClick={() => {
						setDrawerRef(drawerRef);
					}}
					className="w-max transition delay-150 duration-1000 ease-in-out transition-colors"
				>
					{linkCopy}
				</Button>
			);
			// Handle other types of links
		}
		if (link?.startsWith('tel:')) {
			return (
				<a
					href={link}
					className="inline-flex items-center transition delay-150 duration-1000 ease-in-out transition-colors px-6 py-4 text-lg leading-6 text-white transition-colors bg-blue-400 border-2 border-blue-400 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-centra-medium focus:outline-0 focus:shadow-focus w-max"
				>
					<span className="rulerclick">{linkCopy}</span>
				</a>
			);
			// Handle telephone link
		}
		if (link?.startsWith('/')) {
			return (
				<PBSLink
					className="justify-center w-full transition delay-150 duration-1000 ease-in-out transition-colors text-blue-400 bg-white sm:w-max hover:text-white"
					to={link}
					variant="White"
				>
					{linkCopy}
				</PBSLink>
			);
			// Handle internal link
		}
	}

	return (
		<div className="flex flex-col lg:flex-row gap-5 mt-3 items-center mx-auto lg:mx-0">
			<div className="rounded-xl">
				{checkLinkType(
					primaryLink,
					primaryLinkCopy,
					'primary',
					primaryLinkOpenForm
				)}
			</div>
			<div className="rounded-xl">
				{checkLinkType(secondaryLink, secondaryLinkCopy, 'secondary')}
			</div>
		</div>
	);
};

export default CTAbuttonsCA;
