import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import loadScript from 'load-script2';
import clsx from 'clsx';

// import { getLocale } from '../../../utils/locale';

const isClient = typeof window === 'object';

const TrustpilotReviews = ({
	className,
	wrapperClassName,
	locale,
	pageName,
}) => {
	const ref = useRef(null);
	const [trustpilot, setTrustpilot] = useState(
		'https://uk.trustpilot.com/review/peninsulagrouplimited.com'
	);
	useEffect(() => {
		if (locale !== 'en-CA') {
			const loadTP = async () => {
				await loadScript(
					'//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
				);
				if (window.Trustpilot) {
					window.Trustpilot.loadFromElement(ref.current, true);
				}
			};
			if (isClient) {
				loadTP();
			}
		}
	}, [locale]);

	useEffect(() => {
		if (locale === 'en-IE') {
			setTrustpilot(
				'https://www.trustpilot.com/review/peninsulagrouplimited.com/ie/'
			);
		} else {
			setTrustpilot(
				'https://uk.trustpilot.com/review/peninsulagrouplimited.com'
			);
		}
	}, [locale]);
	return (
		<section
			className={clsx(
				'px-5 mx-auto lg:px-[5%] py-12 bg-white',
				className
			)}
			aria-label="Trustpilot reviews"
		>
			{locale !== 'en-CA' ? (
				<div
					className={clsx(
						'lg:container lg:mx-auto',
						wrapperClassName
					)}
				>
					{pageName === 'safe-check' ? (
						<div
							ref={ref}
							className="trustpilot-widget"
							data-locale="en-GB"
							data-template-id="53aa8912dec7e10d38f59f36"
							data-businessunit-id="59cc02930000ff0005ac361d"
							data-style-height="140px"
							data-style-width="100%"
							data-theme="light"
							data-tags="Safecheck"
							data-stars="1,2,3,4,5"
							data-review-languages="en"
						>
							<a
								href="https://uk.trustpilot.com/review/peninsulagrouplimited.com"
								target="_blank"
								rel="noreferrer"
							>
								Trustpilot
							</a>
						</div>
					) : (
						<div
							ref={ref}
							className="trustpilot-widget"
							data-locale="en-US"
							data-template-id="54ad5defc6454f065c28af8b"
							data-businessunit-id={
								locale === 'en-IE'
									? '61b0a1df3f5e11737c6abbd8'
									: '59cc02930000ff0005ac361d'
							}
							data-style-height="240px"
							data-style-width="100%"
							data-theme="light"
							data-stars="5"
							data-review-languages="en"
						>
							<a
								href={trustpilot}
								target="_blank"
								rel="noopener noreferrer"
							>
								Trustpilot
							</a>
						</div>
					)}
				</div>
			) : null}
		</section>
	);
};

TrustpilotReviews.defaultProps = {
	className: '',
	wrapperClassName: '',
	locale: '',
	pageName: '',
};

TrustpilotReviews.propTypes = {
	className: PropTypes.string,
	wrapperClassName: PropTypes.string,
	locale: PropTypes.string,
	pageName: PropTypes.string,
};

export { TrustpilotReviews };
