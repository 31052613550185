// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import '../styles/careers.css';

import { useLocation } from '@reach/router';
import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';
import { BreadCrumbs } from '../components/atoms/breadcrumbs';

import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';
import { HrefLang } from '../components/templates/hrefLang';
import { getLocale } from '../utils/locale';

// markup
const JobPost = ({ data, parentPageData, locationSite }) => {
	const {
		internalJobPost,
		jobDescription,
		jobTitle,
		salary,
		location,
		meta,
		contactNumber,
		contactEmail: email,
	} = data.datoCmsJobPost;
	const metaContent = {
		metaInformation: data.datoCmsJobPost.metaInformation,
		fallbackTitle: jobTitle,
		fallbackDescription: data.datoCmsJobPost.longIntroduction,
		canonicalUrl: data.datoCmsJobPost?.canonicalUrl || locationSite.href,
		hreflangs: {
			uk: data.datoCmsJobPost.hreflangEnGb,
			ie: data.datoCmsJobPost.hreflangEnIe,
		},
	};
	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.jobPost,
			location: locationSite,
			data: data.datoCmsJobPost,
			parentPageData,
		}),
		image: getImageSeoSchema(data.datoCmsJobPost?.mainHeader?.primaryImage),
	};
	const url = useLocation();
	const locale = getLocale(url.pathname);
	const contactEmail = () => {
		if (internalJobPost) {
			return locale === 'ie'
				? 'cv@peninsula-ie.com'
				: 'Careers.PBS@peninsula-uk.com';
		}

		return email;
	};

	const ApplyButton = () => (
		<>
			<a
				type="button"
				className="hover:border-brand-blue-400 focus:bg-brand-blue-500 rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white hover:bg-brand-blue-400 text-center lg:text-left mt-8 w-full lg:w-auto"
				href={`mailto:${contactEmail()}?subject=Application%20for%20${jobTitle}%20in%20${location}`}
			>
				Apply
			</a>
			<p className="text-center lg:inline lg:ml-4">
				or call{' '}
				<a href={`tel:${contactNumber.replace(/\s/g, '')}`}>
					{contactNumber}
				</a>
			</p>
		</>
	);

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsJobPost.internal.type,
		data.datoCmsJobPost
	);

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsJobPost.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<div className="px-3 mx-auto max-w-m-screen lg:px-8 flex flex-col md:block content space-y-4">
				<h1 className="md:leading-tight text-2xl-f md:text-5xl md:mb-8">
					{jobTitle}
				</h1>
				<div className="border border-grey-400 rounded-sm w-auto inline-block py-6 px-4 md:float-right md:ml-4 md:!mb-4 md:!mt-0 shadow-lg">
					<h2>Job Detail</h2>
					<p>
						Job Title: <strong>{jobTitle}</strong>
					</p>
					<p>
						Salary: <strong>{salary}</strong>
					</p>
					<p>
						Location: <strong>{location}</strong>
					</p>
					<p>
						Posted:{' '}
						<strong>
							{meta.firstPublishedAt || meta.createdAt}
						</strong>
					</p>
					<ApplyButton />
				</div>
				{/* eslint-disable-next-line react/no-danger */}
				<div dangerouslySetInnerHTML={{ __html: jobDescription }} />

				<ApplyButton />
			</div>
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query jobPost($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsJobPost(locale: { eq: $locale }, slug: { eq: $slug }) {
			internal {
				type
			}
			allSeoLocales: _allSeoLocales {
				locale
			}
			internalJobPost
			jobDescription
			jobTitle
			salary
			contactNumber
			contactEmail
			location
			seo {
				description
				image {
					gatsbyImageData
				}
				title
				twitterCard
			}
			slug
			meta {
				firstPublishedAt(formatString: "DD/MM/YYYY")
				createdAt(formatString: "DD/MM/YYYY")
			}
		}
	}
`;

const WrappedJobPost = ({ data, pageContext, location }) => (
	<DefaultLayout
		className="bg-white"
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<JobPost
			data={data}
			slug={pageContext.slug}
			locale={pageContext.locale}
			locationSite={location}
		/>
	</DefaultLayout>
);

export default WrappedJobPost;
