import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import loadScript from 'load-script2';
import { useFormik, Field, FormikProvider } from 'formik';
import clsx from 'clsx';
import { useMarketo } from '../../../../contexts/marketo-context';
import { useFormValidation } from '../../../../hooks/form-validation';
import { getCookie, sendTrackingData, sleep } from '../../../../utils';
import { useMarketoFormSubmit } from '../../../../hooks/marketo';
import { Button } from '../../../atoms/button';
import { SidebarHeader } from '../../../atoms/sidebar-header';
import { CallUs } from '../../../atoms/call-us-ppc';
import {
	validateEmail,
	validatePhoneNumber,
	validateString,
} from '../../../../utils/marketo';
import { TextInput } from '../../../atoms/text-input';
import { Select } from '../../../atoms/select';
import { RadioButton } from '../../../atoms/radio-button';
import { PBSLink } from '../../../atoms/link';

function PayrollFormikForm({
	formId,
	ctaText,
	pageUrl,
	setFormResponse,
	showClientNumber,
	phoneNumber,
}) {
	const MktoForms2Instances = useMarketo();
	const initialValidations = {
		FirstName: { isValid: true },
		isClient: { isValid: true },
		CanNumber: { isValid: true },
		LastName: { isValid: true },
		Email: { isValid: true },
		Phone: { isValid: true },
		Company: { isValid: true },
		EmployeeCount: { isValid: true },
		IntrestIn: { isValid: true },
		EmployeeType: { isValid: true },
	};
	const [isLoading, setIsLoading] = useState(false);
	const [technicalError, setTechnicalError] = React.useState(false);
	const { validations, validateForm } = useFormValidation(initialValidations);
	const [referralCookie, setReferralCookie] = useState('');
	const [keywordCookie, setKeywordCookie] = useState('');
	const [mediumCookie, setMediumCookie] = useState('');
	const [campaignCookie, setCampaignCookie] = useState('');
	const [msclkidCookie, setMsclkidCookie] = useState('');
	const [msclkidLastCookie, setMsclkidLastCookie] = useState('');
	const [gclidCookie, setGclidCookie] = useState('');
	const [gclidLastCookie, setGclidLastCookie] = useState('');
	const [campaignLastCookie, setCampaignLastCookie] = useState('');
	const [contentLastCookie, setContentLastCookie] = useState('');
	const [mediumLastCookie, setMediumLastCookie] = useState('');
	const [sourceLastCookie, setSourceLastCookie] = useState('');
	const [termLastCookie, setTermLastCookie] = useState('');
	const [googleCookie, setGoogleCookie] = useState('');
	const [socialId, setSocialId] = useState('');
	const [isClient, setIsClient] = useState(null);

	const title = ctaText || 'Register your interest';
	useEffect(() => {
		// eslint-disable-next-line compat/compat
		const urlObj = new URL(pageUrl);
		setSocialId(urlObj.searchParams.get('social_id'));
	}, [pageUrl]);

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: title,
		});
	};

	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);
	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const employeesCountRef = useRef(null);
	const intrestInRef = useRef(null);
	const employeeTypeRef = useRef(null);
	const isClientRef = useRef(null);
	const canNumberRef = useRef(null);

	const initialValues = {
		FirstName: '',
		LastName: '',
		Email: '',
		Phone: '',
		Company: '',
		NumberOfEmployees: '',
		typeofclient: '',
		interestedin: '',
		Client_Account_Number__c: isClient,
		CanNumber: '',
		PersonSource: 'Website - Confirm Webinar Web Lead',
		LeadSource: 'Website - Confirm Webinar Web Lead',
		referalSource: referralCookie,
		keyword: keywordCookie,
		utm_medium: mediumCookie,
		utm_campaign: campaignCookie,
		MSCLKID__c: msclkidCookie,
		MSCLKID_last: msclkidLastCookie,
		GCLID__c: gclidCookie,
		gCLID_last: gclidLastCookie,
		utm_campaign_last__c: campaignLastCookie,
		utm_content_last__c: contentLastCookie,
		utm_medium_last__c: mediumLastCookie,
		utm_source_last__c: sourceLastCookie,
		utm_term_last__c: termLastCookie,
		Google_Cookie_ID__c: googleCookie,
		socialCampaignID: socialId,
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: true,
	});

	const submitHandler = async (event) => {
		setIsLoading(true);
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);

		if (!isValid) {
			setIsLoading(false);
			return;
		}

		const payloadData = {
			...initialValues,
			FirstName: formEntries.FirstName,
			LastName: formEntries.LastName,
			Phone: formEntries.Phone,
			Email: formEntries.Email,
			Company: formEntries.Company,
			NumberOfEmployees: formEntries.EmployeeCount,
			interestedin: formEntries.IntrestIn,
			typeofclient: formEntries.EmployeeType,
			PersonSource: 'Website - Confirm Webinar Web Lead',
		};

		if (showClientNumber) {
			payloadData.Client_Account_Number__c = formEntries.CanNumber;
		}

		await sleep(500);
		handleClickTracking();
		useMarketoFormSubmit(payloadData, formId, MktoForms2Instances)
			.then(() => {
				formik.setStatus('submitted');
				setFormResponse(true);
			})
			.catch(() => {
				formik.setStatus('error');
				setTechnicalError(true);
			})
			.finally(() => {
				handleClickTracking();
				setIsLoading(false);
			});
	};

	useEffect(() => {
		const loadForm = () =>
			loadScript(
				'https://app-lon04.marketo.com/js/forms2/js/forms2.min.js'
			)
				.then(() => {
					MktoForms2Instances.get(
						'https://app-lon04.marketo.com'
					).loadForm(
						'https://app-lon04.marketo.com',
						'023-IMK-845',
						formId
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
		setReferralCookie(getCookie('utm_source'));
		setKeywordCookie(getCookie('utm_term'));
		setMediumCookie(getCookie('utm_medium'));
		setCampaignCookie(getCookie('utm_campaign'));
		setMsclkidCookie(getCookie('msclkid'));
		setMsclkidLastCookie(getCookie('msclkid_last'));
		setGclidCookie(getCookie('gclid'));
		setGclidLastCookie(getCookie('gclid_last'));
		setCampaignLastCookie(getCookie('utm_campaign_last'));
		setContentLastCookie(getCookie('utm_content_last'));
		setMediumLastCookie(getCookie('utm_medium_last'));
		setSourceLastCookie(getCookie('utm_source_last'));
		setTermLastCookie(getCookie('utm_term_last'));
		setGoogleCookie(getCookie('Google_Cookie_ID__c'));
	}, [MktoForms2Instances, formId]);

	useEffect(() => {
		if (isClient === 'yes' && showClientNumber) {
			if (!validations?.CanNumber?.isValid && canNumberRef?.current) {
				return canNumberRef.current.focus();
			}
		}
		if (!validations.FirstName.isValid && firstNameRef.current) {
			return firstNameRef.current.focus();
		}

		if (!validations.LastName.isValid && lastNameRef.current) {
			return lastNameRef.current.focus();
		}

		if (!validations.Email.isValid && emailRef.current) {
			return emailRef.current.focus();
		}

		if (validations.Phone.isValid === false && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}

		if (!validations.Company.isValid && companyRef.current) {
			return companyRef.current.focus();
		}

		if (!validations.EmployeeCount.isValid && employeesCountRef.current) {
			return employeesCountRef.current.focus();
		}

		if (!validations.IntrestIn.isValid && intrestInRef.current) {
			return intrestInRef.current.focus();
		}

		if (!validations.EmployeeType.isValid && employeeTypeRef.current) {
			return employeeTypeRef.current.focus();
		}
	}, [isClient, showClientNumber, validations]);
	return technicalError ? (
		<div className="px-4 pb-10 bg-white">
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</div>
	) : (
		<FormikProvider value={formik}>
			<form
				data-formid={formId}
				data-forminstance="one"
				onSubmit={submitHandler}
				className="flex flex-col justify-between w-full mx-auto overflow-hidden grow "
			>
				<div className="flex flex-col grow bg-white">
					{showClientNumber ? (
						<>
							<fieldset className=" mt-5 flex flex-col pt-5 bg-white lg:pt-6 grow">
								<legend className=" text-brand-blue-400 text-base-f font-centra-medium">
									Are you a Peninsula client?
								</legend>

								<div className="flex flex-wrap gap-3 ml-2 ">
									<RadioButton
										labelText="No"
										id="enquireNo"
										name="isClient"
										value={isClient}
										ref={isClientRef}
										required
										onChange={() => setIsClient('no')}
									/>

									<RadioButton
										labelText="Yes"
										id="enquireYes"
										name="isClient"
										ref={isClientRef}
										value={isClient}
										required
										onChange={() => setIsClient('yes')}
									/>
								</div>
							</fieldset>
							{isClient === 'yes' && showClientNumber && (
								<fieldset className="flex flex-col pt-5 bg-white lg:pt-6 grow">
									<Field
										name="CanNumber"
										validate={validateString}
									>
										{({ field }) => (
											<div className="w-full ">
												<label
													htmlFor="CanNumber"
													className="text-brand-blue-400 lg:text-base-f font-centra-medium"
												>
													Your CAN
												</label>
												<TextInput
													id="CanNumber"
													name="CanNumber"
													aria-required="true"
													className="w-full mb-3 wrapper-small"
													ref={canNumberRef}
													showValidation={
														!validations?.CanNumber
															?.isValid &&
														!canNumberRef?.current
															?.value
													}
													validationMessage={
														!validations?.CanNumber
															?.isValid
															? 'Please enter your CAN'
															: null
													}
													{...field}
													space={false}
													placeholder="Account number"
													validateOnBlur
												/>
											</div>
										)}
									</Field>
								</fieldset>
							)}
							{isClient === 'no' && showClientNumber && (
								<div className="mt-2 text-base font-bold">
									This form is for Peninsula clients only. If
									you are not a Peninsula client, please call
									us on {phoneNumber} or
									<PBSLink
										to="/payroll/register-your-interest-in-payroll-prospect"
										variant="Link"
										className="!text-base underline"
									>
										{' '}
										visit our website to find out more about
										our services.
									</PBSLink>
								</div>
							)}
						</>
					) : null}
					<fieldset
						className={clsx(
							'flex flex-col pt-5 bg-white   grow ',
							showClientNumber && isClient === 'no' && 'opacity-5'
						)}
					>
						<legend className="sr-only">
							Enter your personal details
						</legend>
						<div className="flex flex-col lg:flex-row lg:gap-6">
							<Field name="FirstName" validate={validateString}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="FirstName"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											First name&#42;
										</label>
										<TextInput
											id="FirstName"
											name="FirstName"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={firstNameRef}
											disabled={
												showClientNumber &&
												isClient === 'no'
											}
											showValidation={
												!validations.FirstName
													.isValid &&
												!firstNameRef.current.value
											}
											validationMessage={
												!validations.FirstName.isValid
													? 'Please enter your first name'
													: null
											}
											{...field}
											space={false}
											placeholder="Jane"
											validateOnBlur
										/>
									</div>
								)}
							</Field>

							<Field name="LastName" validate={validateString}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="LastName"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Last name&#42;
										</label>
										<TextInput
											id="LastName"
											name="LastName"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={lastNameRef}
											disabled={
												showClientNumber &&
												isClient === 'no'
											}
											showValidation={
												!validations.LastName.isValid &&
												!lastNameRef.current.value
											}
											validationMessage={
												!validations.LastName.isValid
													? 'Please enter your last name'
													: null
											}
											{...field}
											space={false}
											placeholder="Smith"
											validateOnBlur
										/>
									</div>
								)}
							</Field>
						</div>
						<div className="flex flex-col lg:flex-row lg:gap-6">
							<Field name="Company" validate={validateString}>
								{({ field }) => (
									<div className="w-full ">
										<label
											htmlFor="Company"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Company name&#42;
										</label>
										<TextInput
											id="Company"
											name="Company"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={companyRef}
											disabled={
												showClientNumber &&
												isClient === 'no'
											}
											showValidation={
												!validations.Company.isValid &&
												!companyRef.current.value
											}
											validationMessage={
												!validations.Company.isValid
													? 'Please enter your company'
													: null
											}
											{...field}
											space={false}
											placeholder="Company LTD"
											validateOnBlur
										/>
									</div>
								)}
							</Field>
						</div>
						<div className="flex flex-col lg:flex-row lg:gap-6">
							<Field name="Email" validate={validateEmail}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="Email"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Email&#42;
										</label>
										<TextInput
											id="Email"
											type="email"
											name="Email"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={emailRef}
											disabled={
												showClientNumber &&
												isClient === 'no'
											}
											showValidation={
												!validations.Email.isValid &&
												!emailRef.current.value
											}
											validationMessage="Please enter your email address"
											{...field}
											space={false}
											placeholder="jane.smith@gmail.com"
											validateOnBlur
										/>
									</div>
								)}
							</Field>
							<Field name="Phone" validate={validatePhoneNumber}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="Phone"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Phone&#42;
										</label>
										<TextInput
											id="Phone"
											name="Phone"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={phoneNumberRef}
											disabled={
												showClientNumber &&
												isClient === 'no'
											}
											showValidation={
												!validations.Phone.isValid ===
													true &&
												phoneNumberRef.current
											}
											validationMessage="Please enter a phone number"
											{...field}
											space={false}
											placeholder="07997 210 007"
											type="tel"
											validateOnBlur
										/>
									</div>
								)}
							</Field>
						</div>

						<Field name="EmployeeCount" validate={validateString}>
							{({ field }) => (
								<div className="w-full">
									<label
										htmlFor="EmployeeCount"
										className="text-brand-blue-400 lg:text-base-f font-centra-medium"
									>
										How many people do you employ&#42;
									</label>
									<TextInput
										id="EmployeeCount"
										name="EmployeeCount"
										aria-required="true"
										type="number"
										min="1"
										className="w-full mb-3 wrapper-small"
										ref={employeesCountRef}
										disabled={
											showClientNumber &&
											isClient === 'no'
										}
										showValidation={
											!validations.EmployeeCount
												.isValid &&
											!employeesCountRef.current.value
										}
										validationMessage={
											!validations.EmployeeCount.isValid
												? 'Number of employees is required'
												: null
										}
										{...field}
										space={false}
										placeholder="5"
										validateOnBlur
									/>
								</div>
							)}
						</Field>
						<Field name="IntrestIn" validate={validateString}>
							{({ field }) => (
								<div className="w-full -mb-4 ">
									<label
										htmlFor="IntrestIn"
										className="text-brand-blue-400 lg:text-base-f font-centra-medium"
									>
										I&apos;m interested in&#42;
									</label>
									<Select
										name="IntrestIn"
										id="IntrestIn"
										className="mb-9"
										aria-required="true"
										disabled={
											showClientNumber &&
											isClient === 'no'
										}
										placeholder="Please make a selection"
										options={[
											{
												value: 'Payroll Managed Service (Bureau)',
												label: 'Payroll Managed Service (Bureau)',
											},
											{
												value: 'Payroll Software',
												label: 'Payroll Software',
											},
											{
												value: 'Unsure',
												label: 'Unsure',
											},
										]}
										validationMessage="Area of interest is required"
										showValidation={
											!validations?.IntrestIn?.isValid
										}
										ref={intrestInRef}
										{...field}
									/>
								</div>
							)}
						</Field>
						<Field name="EmployeeType" validate={validateString}>
							{({ field }) => (
								<div className="w-full ">
									<label
										htmlFor="EmployeeType"
										className="text-brand-blue-400 lg:text-base-f font-centra-medium"
									>
										Employee Type&#42;
									</label>
									<Select
										name="speakTo"
										id="intrestIn"
										className="mb-9"
										aria-required="true"
										disabled={
											showClientNumber &&
											isClient === 'no'
										}
										placeholder="Please make a selection"
										options={[
											{
												value: 'All or mostly salaried',
												label: 'All or mostly salaried',
											},
											{
												value: 'All or mostly casual',
												label: 'All or mostly casual',
											},
											{
												value: 'A mixture of salaried and casual',
												label: 'A mixture of salaried and casual',
											},
										]}
										validationMessage="Employee type is required"
										showValidation={
											!validations?.EmployeeType?.isValid
										}
										ref={employeeTypeRef}
										{...field}
									/>
								</div>
							)}
						</Field>
					</fieldset>
				</div>

				<div className="flex flex-col items-center bg-white  lg:flex-row lg:justify-between">
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								formik.isSubmitting || isLoading
									? 'justify-center loading'
									: null
							)}
							disabled={
								formik.isSubmitting ||
								isLoading ||
								(isClient === 'no' && showClientNumber)
							}
						>
							<span
								className={clsx(
									isLoading || formik.isSubmitting
										? 'invisible'
										: null
								)}
							>
								{title}
							</span>
							{isLoading || formik.isSubmitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
				</div>
			</form>
		</FormikProvider>
	);
}
PayrollFormikForm.defaultProps = {
	formId: 0,
	ctaText: 'Submit',
	pageUrl: '',
	setFormResponse: undefined,
	showClientNumber: false,
	phoneNumber: '',
};

PayrollFormikForm.propTypes = {
	formId: PropTypes.number,
	ctaText: PropTypes.string,
	pageUrl: PropTypes.string,
	setFormResponse: PropTypes.func,
	showClientNumber: PropTypes.bool,
	phoneNumber: PropTypes.string,
};

export default PayrollFormikForm;
