import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { useScreenResizer } from '../../../contexts/screen-resize-context';
import { CallUs } from '../../atoms/call-us-ppc';
import { Logo } from '../../atoms/logo-ppc';
import { SideNav } from '../../molecules/side-nav';

import { sendTrackingData } from '../../../utils';
import { getLocale } from '../../../utils/locale';
import { PeninsulaNow } from '../peninsula-now';
import useModal from '../../../hooks/modal';
import ModalWrapper from '../../glu/molecules/modal-wrapper';
import { PeninsulaModalForm } from '../modal-peninsula-now';
import usePeninsulaNow from '../../../hooks/peninsula-now';

const HeaderPpcCA = ({
	siteTitle,
	isStorybook,
	headerContent,
	siteSettings,
	mobileOnlyHamburger,
	homepageUrl,
}) => {
	const navRef = useRef(null);
	const { width } = useScreenResizer();
	const [sideLinks, setSideLinks] = useState([]);

	if (!isStorybook) {
		useEffect(() => {
			if (width > 1024) {
				setSideLinks(headerContent.sideNavigation);
			} else {
				setSideLinks([
					headerContent.primaryNavigation,
					headerContent.sideNavigation,
				]);
			}
		}, [width, headerContent]);
	}

	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', trackData);
	};
	const location = useLocation();
	const localePath = getLocale(location.pathname);
	const ref = React.useRef();
	const { isModalOpen, setModalOpen } = useModal(ref);
	const openModal = () => {
		setModalOpen(true);
		handleClickTracking({
			event_name: 'navigation',
			click_action: 'open modal',
			click_type: 'icon',
			click_text: 'Menu',
		});
	};
	const { isOnline } = usePeninsulaNow();
	return (
		<header
			className="bg-white sticky !important top-0 z-50"
			style={{ position: 'sticky' }}
		>
			<section className="relative flex items-center justify-between w-full gap-2 p-4 mx-auto max-w-m-screen">
				<div className="flex items-center header-left">
					<Link to="/ca/">
						<Logo
							width={126}
							height={36}
							title={siteTitle}
							url={homepageUrl}
						/>
					</Link>
				</div>
				<div className="flex items-center header-right">
					{localePath === '' ? (
						<div className={clsx('card', isOnline ? 'flip' : '')}>
							<PeninsulaNow
								pageName="ppc"
								setModal={openModal}
								className={clsx(
									!isOnline && 'hidden',
									'front cursor-pointer'
								)}
							/>

							<div className={clsx(isOnline && 'hidden', 'back')}>
								<CallUs
									number={siteSettings.phoneNumber}
									className={`text-right ${
										!mobileOnlyHamburger
											? ''
											: 'lg:border-none'
									}  border-brand-pale-500 focus:outline-0 focus:shadow-focus focus:rounded-xs`}
									onClickProp={() =>
										handleClickTracking({
											event_name: 'navigation',
											click_type: 'tel',
										})
									}
								/>
							</div>
						</div>
					) : (
						<CallUs
							number={siteSettings.phoneNumber}
							className={`text-right ${
								!mobileOnlyHamburger ? '' : 'lg:border-none'
							}  border-brand-pale-500 focus:outline-0 focus:shadow-focus focus:rounded-xs`}
							onClickProp={() =>
								handleClickTracking({
									event_name: 'navigation',
									click_type: 'tel',
								})
							}
						/>
					)}
					{isModalOpen && (
						<ModalWrapper>
							<div
								className="relative xl:w-7/12 lg:w-10/12 m-auto opacity-100 h-auto w-full md:rounded-md shadow-xl bg-white overflow-auto"
								ref={ref}
							>
								<button
									type="button"
									onClick={() => setModalOpen(false)}
									className="absolute md:hidden flex top-[5px] text-2xl bg-tranparent font-bold right-[10px] opacity-100 border-2 border-brand-pale-200 h-9 w-9 flex items-center justify-center rounded-full"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										x="0px"
										y="0px"
										width="20"
										height="20"
										viewBox="0 0 50 50"
										className="fill-current text-brand-pale-200 font-bold"
									>
										<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z" />
									</svg>
								</button>
								<PeninsulaModalForm isOnline={isOnline} />
							</div>
						</ModalWrapper>
					)}
					<div
						className={`${
							!mobileOnlyHamburger ? 'block' : 'lg:hidden'
						}`}
					/>
				</div>
			</section>
			<SideNav
				navRef={navRef}
				links={isStorybook ? headerContent.sideNavigation : sideLinks}
				signInCopy={headerContent.signInCopy}
			/>
		</header>
	);
};

HeaderPpcCA.defaultProps = {
	siteTitle: 'Peninsula Homepage',
	isStorybook: false,
	mobileOnlyHamburger: false,
	homepageUrl: '/',
	siteSettings: '',
};

HeaderPpcCA.propTypes = {
	siteTitle: PropTypes.string,
	isStorybook: PropTypes.bool,
	headerContent: PropTypes.shape({
		primaryNavigation: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				link: PropTypes.string,
				treeChildren: PropTypes.arrayOf(
					PropTypes.shape({
						title: PropTypes.string.isRequired,
						subText: PropTypes.string.isRequired,
						link: PropTypes.string.isRequired,
						icon: PropTypes.string.isRequired,
					})
				),
			})
		).isRequired,
		sideNavigation: PropTypes.oneOfType([
			PropTypes.arrayOf(
				PropTypes.shape({
					link: PropTypes.string.isRequired,
					title: PropTypes.string.isRequired,
				})
			),
			PropTypes.arrayOf(
				PropTypes.arrayOf(
					PropTypes.shape({
						title: PropTypes.string.isRequired,
						link: PropTypes.string,
						treeChildren: PropTypes.arrayOf(
							PropTypes.shape({
								title: PropTypes.string.isRequired,
								subText: PropTypes.string.isRequired,
								link: PropTypes.string.isRequired,
								icon: PropTypes.string.isRequired,
							})
						),
					})
				),
				PropTypes.arrayOf(
					PropTypes.shape({
						link: PropTypes.string.isRequired,
						title: PropTypes.string.isRequired,
					})
				)
			),
		]),
		sideNavigationOpenCopy: PropTypes.string.isRequired,
		sideNavigationCloseCopy: PropTypes.string.isRequired,
		signInCopy: PropTypes.string,
	}).isRequired,
	mobileOnlyHamburger: PropTypes.bool,
	homepageUrl: PropTypes.string,
	siteSettings: PropTypes.string,
};

export { HeaderPpcCA };
