import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Newspaper from '../../../../assets/newspaper.svg';
import { buildLink } from '../../../../utils/locale';
import { PBSLink } from '../../../atoms/link';

const articleLink = (category, slug) =>
	category[0]?.slug
		? `/resource-hub/${category[0]?.slug}/${slug}/`
		: `/resource-hub/${slug}/`;

const articleLinkIE = (category, slug) =>
	`/elearning/${category.slug}/${slug}/`;

const ArticleNav = ({ articleData, locale }) => (
	<div className="flex article-nav">
		<div className="flex mx-auto overflow-hidden max-w-m-screen whitespace-nowrap">
			<div className={clsx('items-center flex')}>
				<h2 className="flex items-center px-3 py-4 text-white text-xl">
					{locale === 'en-IE' ? (
						'Latest videos'
					) : (
						<>
							<span className="text-brand-red-400 flash-1 font-bold">
								ARE
							</span>

							<span className="ml-2 text-brand-red-400 flash-2 font-bold">
								YOU
							</span>

							<span className="ml-2 text-brand-red-400 flash-3 font-bold">
								READY?
							</span>
						</>
					)}
				</h2>
			</div>

			<div className="py-4 overflow-hidden">
				<div className="flex flex-row gap-20 overflow-visible text-white animate-loop whitespace-nowrap">
					{articleData.map((article) => (
						<div key={article.title}>
							<img
								src={Newspaper}
								className="inline-block mr-2"
								alt="Newspaper icon"
							/>
							<span>{article.title}</span>
							{locale === 'en-IE' ? (
								<PBSLink
									to={buildLink(
										locale,
										articleLinkIE(
											article.category,
											article.slug
										)
									)}
									target="_blank"
									variant="Unset"
									rel="noreferrer"
									className="ml-3 text-base underline"
								>
									View video
								</PBSLink>
							) : (
								<a
									href={buildLink(
										locale,
										articleLink(
											article.categories,
											article.slug
										)
									)}
									target="_blank"
									rel="noreferrer"
									className="ml-3 text-base underline"
								>
									View article
								</a>
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	</div>
);

// Define prop types
ArticleNav.propTypes = {
	articleData: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			category: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
			slug: PropTypes.string.isRequired,

			categories: PropTypes.arrayOf(
				PropTypes.shape({
					// Define the shape of the objects within the categories array if needed
					name: PropTypes.string,
					// Add other properties as required
				})
			),
		})
	).isRequired,
	locale: PropTypes.string.isRequired,
};

export default ArticleNav;
