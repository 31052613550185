// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';
import openQuote from './quotes/open_quote.svg';
import closeQuote from './quotes/close_quote.svg';
import { useModal } from '../../../contexts/modal-context';
import { Button } from '../../atoms/button';

const TestimonialsVideoCampaignCA = (
	{ testimonialsVideoCampaignCA, modalRef },
	className
) => {
	const { title, subText, testimonialCards, primaryLinkCopy } =
		testimonialsVideoCampaignCA;
	const { setModalRef } = useModal();
	return (
		<div className={`w-full bg-[#EDF4FF] ${className}`}>
			<div className="flex flex-col items-center px-6 pb-0 pt-8 lg:px-10 lg:pt-20 lg:pb-16 mx-auto lg:max-w-m-screen bg-[#EDF4FF]">
				{/* title */}
				<div className="pb-5">
					{createHeadingHighlightCA({
						headings: title.value.document.children[0].children,
						variant: 'red',
						className: 'text-center',
					})}
				</div>
				{/* subText */}
				<div className="text-center text-[20px] font-centra-light pb-10 lg:pb-16">
					{subText}
				</div>
				{/* cards */}
				<div className="grid grid-flow-row lg:grid-flow-rol lg:grid-cols-3 px-2 gap-5 font-castledown-regular">
					{testimonialCards.map((elm) => (
						<div
							key={elm}
							className="col-span-1 h-fit bg-white rounded-[.75rem] border-1 border-[#dddddd] p-5 text-3xl-f text-[16px] leading-[1.35rem] font-centra-no1 relative shadow-sm"
						>
							<div className="font-centra-book">
								<span className="inline">
									<img
										src={openQuote}
										className="inline pb-1 pr-2 -mt-2"
										alt=""
									/>
									{elm.content}
									<img
										src={closeQuote}
										className="inline pl-2 pb-1"
										alt=""
									/>
								</span>
							</div>
							<div className="flex flex-row justify-between items-center mt-5">
								<div className="flex flex-col font-centra-no1 font-bold">
									<div className="font-castledown-regular text-[#3954E0]">
										{elm.name},
									</div>
									<div className="text-[#1f237a] max-w-[250px]">
										{elm.job}
									</div>
								</div>

								<div>
									<img
										src={elm.profile.url}
										alt=""
										className="min-h-[74px] min-w-[74px]"
									/>
								</div>
							</div>
						</div>
					))}
				</div>
				<div className="pb-10 pt-10 lg:pt-16">
					<Button
						onClick={() => setModalRef(modalRef)}
						className="hidden md:block transition delay-150 duration-1000 mx-auto hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 whitespace-nowrap"
					>
						{primaryLinkCopy}
					</Button>
					{/* for mobile devices */}
					<Button
						onClick={() => {
							window.location.href = '#adviceform';
						}}
						className="block md:hidden transition delay-150 duration-1000 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 whitespace-nowrap"
					>
						{primaryLinkCopy}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default TestimonialsVideoCampaignCA;
