// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import {
	StructuredText,
	renderNodeRule,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { isHeading } from 'datocms-structured-text-utils';
import clsx from 'clsx';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import loadScript from 'load-script2';
import useModal from '../../hooks/modal';
import ModalWrapper from '../../components/glu/molecules/modal-wrapper';
import { CertificateForm } from '../../components/glu/atoms/certificateform/CertificateForm';
import { GLULayout } from '../../layouts/glu';
import PageSection from '../../components/glu/organisms/page-section';
import TiltedCardContainer from '../../components/glu/organisms/tilted-card';
import { CardList } from '../../components/glu/molecules/course-list';
import CatchUpWebinars from '../../components/glu/organisms/catch-up-webinars';

import {
	getBreadcrumbs,
	getBreadcrumLocale,
	pageTypes,
} from '../../utils/url-helpers';
import VideoPlayer from '../../components/glu/molecules/video-player';
import { Head } from '../../components/templates/head';
import {
	getOrganizationSeoSchema,
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../../utils/seo-schema';
import { BreadCrumbs } from '../../components/atoms/breadcrumbs';
import { CopyButton } from '../../components/glu/atoms/copy-button';
import { SocialShareButton } from '../../components/glu/atoms/social-share-button';
import { BannerNav } from '../../components/glu/organisms/banner-nav';
import Spinner from '../../assets/spinner.inline.svg';
import { UnlockAccessForm } from '../../components/glu/organisms/unlock-access-form';
import useFindCookie from '../../hooks/useCookie';
import ArticleNav from '../../components/glu/organisms/article-nav';
import { buildLink } from '../../utils/locale';
import Certificate from '../../components/glu/atoms/certificate/Certificate';

const GluCourse = ({ data, location }) => {
	const {
		title,
		content,
		category,
		date,
		formattedDate,
		timeStart,
		timeEnd,
		videoEmbed,
		sessions,
		locale,
		pdfDownload,
		isWebinarCatchUp,
	} = data.datoCmsGluCourse;
	const ref = useRef();
	const { nodes: articles } = data.allDatoCmsBlogPage;
	const { isModalOpen, setModalOpen } = useModal(ref);
	const { eLearningTitle, eLearningSessionCards } = data.datoCmsGluHomepage;
	const { mainLogo } = data.allDatoCmsGluLogo.nodes[0];

	const metaContent = {
		noIndex: data.datoCmsGluCourse.noIndex,
		noFollow: data.datoCmsGluCourse.noFollow,
		metaInformation: data.datoCmsGluCourse.metaTags,
		fallbackTitle: data.datoCmsGluCourse.eLearningTitle,
		fallbackDescription: '',
		canonicalUrl: data.datoCmsGluCourse?.canonicalUrl || location?.href,
	};

	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			data: data.datoCmsGluCourse,
			pageType: pageTypes.gluCourse,
			location,
		}),
		image: getImageSeoSchema(mainLogo),
	};
	const [videoFinished, setVideoFinished] = useState();
	const [scriptReady, setScriptReady] = useState(false);
	const { hasCookie, findingCookie, setFindingCookie, setHasCookie } =
		useFindCookie({
			name: 'elearningContent',
		});
	const [consentResponse, setConsentResponse] = React.useState(false);
	React.useEffect(() => {
		if (consentResponse || hasCookie) {
			setFindingCookie(false);
			setHasCookie(true);
		}
	}, [consentResponse, hasCookie, setFindingCookie, setHasCookie]);
	useEffect(() => {
		const loadVideoScript = () =>
			loadScript('https://player.vimeo.com/api/player.js')
				.then(() => {
					setScriptReady(true);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadVideoScript);
		} else {
			setTimeout(loadVideoScript);

			setTimeout(() => {
				loadVideoScript();
			}, 100);
		}
	}, []);

	const videosWatchedList = useMemo(() => [], []);

	const showCertificateButton =
		videosWatchedList.includes(videoEmbed?.providerUid) || videoFinished;
	const bannerTitle = 'Love it, Share it';
	const { locale: urlLocale } = data.datoCmsGluCourse;
	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsGluCourse.internal.type,
		data.datoCmsGluCourse
	);
	const [response, setResponse] = useState('');

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{bannerTitle && urlLocale === 'en-IE' && (
				<BannerNav title={bannerTitle} />
			)}

			{locale === 'en' && articles.length > 0 && (
				<ArticleNav articleData={articles} locale={locale} />
			)}

			<div className="bg-brand-pale-400 w-100">
				<BreadCrumbs breadCrumbItems={breadCrumbItems} glu />
			</div>

			{findingCookie ? (
				<div className="flex flex-row justify-center py-10">
					<Spinner
						width="24"
						height="24"
						fill="currentColor"
						className="ml-2 animate-spin"
					/>
				</div>
			) : (
				<>
					{hasCookie || consentResponse ? (
						<>
							<section className="bg-brand-pale-400">
								<div className="px-4 mx-auto bg-brand-pale-400 max-w-m-screen">
									{isWebinarCatchUp && (
										<p>
											<a
												href={buildLink(
													locale,
													'/elearning/webinars'
												)}
												className="underline"
											>
												Webinars
											</a>
											<span className="mx-4">/</span>
											{title}
										</p>
									)}
									<h1 className=" text-4xl font-bold">
										{title}
									</h1>

									<div className="lg:flex lg:gap-8">
										{videoEmbed?.providerUid && (
											<div className="mb-8 py-10 lg:mb-0 lg:basis-1/2 aspect-video">
												<VideoPlayer
													videoEmbed={videoEmbed}
													setVideoFinished={
														setVideoFinished
													}
													scriptReady={scriptReady}
													videosWatchedList={
														videosWatchedList
													}
													setIsGlu
												/>
											</div>
										)}
										<div className="py-10 lg:basis-1/2">
											{content?.value && (
												<StructuredText
													data={content}
													customNodeRules={[
														renderNodeRule(
															isHeading,
															({
																node,
																children,
																key,
															}) => {
																const HeadingTag = `h${node.level}`;
																return (
																	<HeadingTag
																		className="mb-5 text-2xl font-bold lg:text-3xl font-castledown-regular"
																		key={
																			key
																		}
																	>
																		{
																			children
																		}
																	</HeadingTag>
																);
															}
														),
													]}
												/>
											)}
											{date && (
												<p className="flex items-center my-6 font-bold text-blue-400">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="18"
														height="17"
														fill="none"
														className="inline-block mr-2"
													>
														<path
															fill="#3954E0"
															d="M16.401 1.812h-2.038v-.549a.587.587 0 0 0-.59-.589.587.587 0 0 0-.588.59v.548h-8.37v-.549a.587.587 0 0 0-.59-.589.587.587 0 0 0-.588.59v.548H1.598A1.6 1.6 0 0 0 0 3.41v11.422a1.6 1.6 0 0 0 1.598 1.598h14.804A1.6 1.6 0 0 0 18 14.832V3.41c0-.878-.717-1.598-1.599-1.598ZM1.598 2.989h2.039v.548c0 .327.262.59.589.59a.587.587 0 0 0 .589-.59V2.99h8.37v.548c0 .327.263.59.59.59a.587.587 0 0 0 .588-.59V2.99h2.039a.42.42 0 0 1 .42.42v1.653H1.177V3.41c0-.23.189-.421.42-.421ZM16.4 15.256H1.598a.42.42 0 0 1-.42-.42V6.239h15.644v8.596a.42.42 0 0 1-.42.421Z"
														/>
													</svg>
													<time
														dateTime={`${date}T${timeStart}`}
													>
														{formattedDate}
													</time>
													{timeStart ? (
														<p>
															<span className="mx-2">
																|
															</span>
															{timeStart} -{' '}
															{timeEnd}
														</p>
													) : null}
												</p>
											)}
											<div className="flex flex-col gap-x-3 gap-y-1 sm:flex-row">
												<div className="mt-5">
													{showCertificateButton ? (
														<>
															<button
																type="button"
																onClick={() =>
																	setModalOpen(
																		(
																			prev
																		) =>
																			!prev
																	)
																}
																className="hover:[transform:scale(1.1)] hover:[ -webkit-transform: scale(1.1)] hover:[-ms-transform: scale(1.1)] relative z-10 flex items-center bg-brand-blue-400 text-white gap-2 px-3 py-1 text-black transition-all border-2 rounded-md w-max hover:bg-brand-blue-400 hover:text-white"
															>
																<p className="flash-effect">
																	Click Here
																	To -
																</p>
																Get Certificate
															</button>
															<div className="mt-5">
																{pdfDownload?.filename ? (
																	<a
																		href={
																			pdfDownload.url
																		}
																		target="_blank"
																		download={
																			pdfDownload.filename
																		}
																		className="hover:[transform:scale(1.1)] hover:[ -webkit-transform: scale(1.1)] hover:[-ms-transform: scale(1.1)] relative z-10 flex items-center bg-brand-blue-400 text-white gap-2 px-3 py-1 text-black transition-all border-2 rounded-md w-max hover:bg-brand-blue-400 hover:text-white"
																		rel="noreferrer"
																	>
																		<p>
																			Click
																			Here
																			To -
																			Download
																			slides
																		</p>
																	</a>
																) : null}
															</div>
														</>
													) : null}
												</div>
											</div>
											{/* // <a
													// 	href={
													// 		certificate?.url
													// 	}
													// 	target="_blank"
													// 	download={
													// 		certificate?.filename
													// 	}
													// 	className="relative z-10 flex items-center gap-2 px-3 py-1 text-black transition-all border-2 rounded-md w-max hover:bg-grey-500 hover:text-white"
													// 	rel="noreferrer"
													// >
													// 	<p>
													// 		Download
													// 		certificate
													// 	</p>
													// </a> */}
											{category?.slug !==
												'health-and-safety' && (
												<>
													<div className="text-brand-blue-400  items-center text-[20px] py-2">
														<p className="text-base">
															Access your HR
															policies on the link
															below
														</p>
													</div>
													<a
														href="https://app.brighthr.com/document/peninsulaSuppliedDocuments?lvl=1"
														target="_blank"
														rel="noreferrer"
														className="text-blue-400 underline inline-flex items-center text-[20px] gap-5"
													>
														BrightHR - Policies
													</a>
												</>
											)}
											<SocialShareButton
												text="Love it, Share it"
												hasIcon
											/>
											<CopyButton
												text="Share this answer by copying the link"
												url={
													typeof window !==
													'undefined'
														? window.location
														: ''
												}
											/>

											{isModalOpen && (
												<ModalWrapper>
													<div
														className="relative m-auto mx-8 overflow-auto rounded-sm w-7/8 sm:w-3/4 lg:w-1/2 xl:w-1/3 md:mx-0"
														ref={ref}
													>
														<div
															className={clsx(
																'card ',
																response &&
																	'flip'
															)}
														>
															{response === '' ? (
																<CertificateForm
																	setResponse={
																		setResponse
																	}
																	pageUrl={
																		typeof window !==
																		'undefined'
																			? window
																					.location
																					.href
																			: ''
																	}
																/>
															) : (
																<Certificate
																	title={
																		title
																	}
																	response={
																		response
																	}
																/>
															)}
														</div>
													</div>
												</ModalWrapper>
											)}
										</div>
									</div>
								</div>
							</section>
							{sessions.length > 0 ? (
								<section className="px-4 py-12 mx-auto max-w-m-screen">
									<h3 className="mb-5 text-2xl font-bold lg:text-3xl font-castledown-regular">
										More on demand sessions
									</h3>
									<ul
										className={clsx(
											'w-full grid gap-x-5 gap-y-2 sm:gap-y-s-f md:gap-y-base-f grid-flow-row',
											'lg:grid-cols-2 pb-4 sm:pb-5'
										)}
									>
										{sessions?.map((session) => (
											<CardList
												cardContent={session}
												key={session.id}
												locale={locale}
											/>
										))}
									</ul>
								</section>
							) : null}

							<PageSection
								titleBlock={eLearningTitle.value}
								logo={mainLogo}
							>
								<TiltedCardContainer
									cards={eLearningSessionCards}
									filter={category?.title}
									locale={locale}
								/>
								<CatchUpWebinars />
							</PageSection>
						</>
					) : (
						<div className="flex flex-row justify-center py-10  items-center w-full">
							<div className="w-full lg:max-w-xl lg:shadow-xl ">
								<UnlockAccessForm
									pageUrl={
										typeof window !== 'undefined'
											? window.location.href
											: ''
									}
									locale={locale}
									setConsentResponse={setConsentResponse}
									className="!rounded-none"
									headerContent="Your expert e-learning content is waiting for you"
									subTextContent="Whether you're a client or not, access your e-learning sessions by sharing your details:"
								/>
							</div>
						</div>
					)}
				</>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedGluCourse = ({ data, location }) => (
	<GLULayout locale={data.datoCmsGluCourse.locale}>
		<GluCourse data={data} location={location} />
	</GLULayout>
);

export default WrappedGluCourse;

export const query = graphql`
	query GluCourse($slug: String, $locale: String!) {
		datoCmsGluCourse(slug: { eq: $slug }, locale: { eq: $locale }) {
			noIndex
			locale
			internal {
				type
			}
			metaTags {
				title
				description
				image {
					url
				}
				twitterCard
			}
			slug
			title
			timeToWatchInMinutes
			date
			formattedDate: date(formatString: "MMM DD YYYY")
			timeStart
			timeEnd
			category {
				slug
				title
			}
			content {
				value
			}
			category {
				title
				slug
			}
			videoEmbed {
				thumbnailUrl
				width
				height
				providerUid
				provider
				title
			}
			pdfDownload {
				url
				filename
			}
			certificate {
				url
				filename
			}
			sessions {
				category {
					slug
				}
				intro
				slug
				title
				timeToWatchInMinutes
				date
				formattedDate: date(formatString: "DD[/]MM[/]YY")
			}
		}
		datoCmsGluHomepage(locale: { eq: $locale }) {
			eLearningTitle {
				value
			}
			eLearningSessionCards {
				title
				image {
					gatsbyImageData
					alt
				}
				description {
					value
				}
				ctaLink
				ctaText
			}
		}
		allDatoCmsBlogPage(
			limit: 5
			sort: { order: DESC, fields: meta___createdAt }
			filter: { locale: { eq: $locale } }
		) {
			nodes {
				title
				slug
				categories {
					slug
				}
			}
		}
		allDatoCmsGluLogo {
			nodes {
				mainLogo {
					url
					alt
				}
			}
		}
	}
`;
