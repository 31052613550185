import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

const Footer = ({ footerContent }) => (
	<footer className="font-centra-light">
		<div className="p-5 text-sm text-white md:p-10 bg-brand-blue-400">
			<div className="mx-auto lg:container">
				<div className="flex flex-col gap-8 lg:gap-20 lg:flex-row">
					<div className="flex flex-wrap">
						<p className="w-full mt-4 leading-6 lg:w-12/12">
							{footerContent.footerCopyright}
						</p>
					</div>
					<div className="flex flex-wrap items-center gap-4 shrink-0">
						<>
							<StaticImage
								src="../../../assets/ppc-awards-footer.png"
								alt="Canadian Awards"
								width={550}
							/>
						</>
					</div>
				</div>
			</div>
		</div>
	</footer>
);

Footer.propTypes = {
	footerContent: PropTypes.shape({
		footerNavigation: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				treeChildren: PropTypes.arrayOf(
					PropTypes.shape({
						link: PropTypes.string.isRequired,
						title: PropTypes.string.isRequired,
					})
				).isRequired,
			})
		).isRequired,
		footerAddressTitle: PropTypes.string.isRequired,
		footerAddress: PropTypes.string.isRequired,
		footerSocialTitle: PropTypes.string.isRequired,
		footerInternationalSitesCopy: PropTypes.string.isRequired,
		footerInternationalSites: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				link: PropTypes.string.isRequired,
			})
		).isRequired,
		footerCopyright: PropTypes.string.isRequired,
	}).isRequired,
};

export { Footer };
