import React from 'react';

export const createHeadingHighlightCA = ({
	headings,
	variant = 'red',
	className,
}) => {
	if (variant === 'blue') {
		return (
			<div className={className}>
				{headings.map((item) =>
					// eslint-disable-next-line no-nested-ternary
					item.marks ? (
						<span
							key={`heading-text-${item.value}-${Math.random()}`}
							className="text-white"
						>
							<p className="rotate-6 inline-block px-2 mr-2 bg-blue-400 text-center font-bold font-castedown-regular">
								{item.value}
							</p>
						</span>
					) : item.value.includes('\n') ? (
						// Check for new lines and handle them
						item.value.split('\n').map((line, index) =>
							// We don't want the first one as it'll be part of the original text
							index > 0 ? (
								<span
									key={`heading-text-${line}-${Math.random()}`}
								>
									<br />
									{line}
								</span>
							) : (
								<span key={`heading-text-${line}`}>{line}</span>
							)
						)
					) : (
						<span key={`heading-text-${item.value}`}>
							{item.value}
						</span>
					)
				)}
			</div>
		);
	}

	if (variant === 'black') {
		return (
			<div className={className}>
				{headings.map((item) =>
					// eslint-disable-next-line no-nested-ternary
					item.marks ? (
						<span
							key={`heading-text-${item.value}-${Math.random()}`}
							className="text-3xl md:text-5xl"
						>
							{item.value}
						</span>
					) : item.value.includes('\n') ? (
						item.value.split('\n').map((line, index) =>
							index > 0 ? (
								<span
									className="text-[#1D2951]"
									key={`heading-text-${line}-${Math.random()}`}
								>
									<br />
									{line}
								</span>
							) : (
								<span
									className="text-[#1D2951]"
									key={`heading-text-${line}`}
								>
									{line}
								</span>
							)
						)
					) : (
						<span
							className="text-[#1D2951]"
							key={`heading-text-${item.value}`}
						>
							{item.value}
						</span>
					)
				)}
			</div>
		);
	}

	if (variant === 'red') {
		return (
			<div
				className={`text-3xl md:text-5xl font-castledown-regular leading-[1.2] md:leading-[1.3] ${className}`}
			>
				{headings.map((item) =>
					// eslint-disable-next-line no-nested-ternary
					item.marks ? (
						<span
							key={`heading-text-${item.value}-${Math.random()}`}
							className="text-[#E30138] pb-1 md:bg-[length:100%_8px]"
						>
							{item.value}
						</span>
					) : item.value.includes('\n') ? (
						// Check for new lines and handle them
						item.value.split('\n').map((line, index) =>
							// We don't want the first one as it'll be part of the original text
							index > 0 ? (
								<span
									className="text-[#1D2951]"
									key={`heading-text-${line}-${Math.random()}`}
								>
									<br />
									{line}
								</span>
							) : (
								<span
									className="text-[#1D2951]"
									key={`heading-text-${line}`}
								>
									{line}
								</span>
							)
						)
					) : (
						<span
							className="text-[#1D2951]"
							key={`heading-text-${item.value}`}
						>
							{item.value}
						</span>
					)
				)}
			</div>
		);
	}

	if (variant === 'white') {
		return (
			<div className={`text-2xl ${className}`}>
				{headings.map((item) =>
					// eslint-disable-next-line no-nested-ternary
					item.marks ? (
						<span
							key={`heading-text-${item.value}-${Math.random()}`}
							className="text-red"
						>
							{item.value}
						</span>
					) : item.value.includes('\n') ? (
						item.value.split('\n').map((line, index) =>
							index > 0 ? (
								<span
									className="text-[#1D2951]"
									key={`heading-text-${line}-${Math.random()}`}
								>
									<br />
									{line}
								</span>
							) : (
								<span
									className="text-[#1D2951]"
									key={`heading-text-${line}`}
								>
									{line}
								</span>
							)
						)
					) : (
						<span
							className="text-white"
							key={`heading-text-${item.value}`}
						>
							{item.value}
						</span>
					)
				)}
			</div>
		);
	}

	if (variant === 'purple') {
		return (
			<div className={`text-[16px] ${className}`}>
				{headings.map((item) =>
					// eslint-disable-next-line no-nested-ternary
					item.marks ? (
						<span
							key={`heading-text-${item.value}-${Math.random()}`}
							className="text-[#9CAAF0]"
						>
							{item.value}
						</span>
					) : item.value.includes('\n') ? (
						item.value.split('\n').map((line, index) =>
							index > 0 ? (
								<span
									className="text-[#9CAAF0]"
									key={`heading-text-${line}-${Math.random()}`}
								>
									<br />
									{line}
								</span>
							) : (
								<span
									className="text-[#9CAAF0]"
									key={`heading-text-${line}`}
								>
									{line}
								</span>
							)
						)
					) : (
						<span
							className="text-white"
							key={`heading-text-${item.value}`}
						>
							{item.value}
						</span>
					)
				)}
			</div>
		);
	}
	if (variant === '') {
		return (
			<div className={className}>
				{headings.map((item) =>
					// eslint-disable-next-line no-nested-ternary
					item.marks ? (
						<span
							key={`heading-text-${item.value}-${Math.random()}`}
							className=""
						>
							{item.value}
						</span>
					) : item.value.includes('\n') ? (
						item.value.split('\n').map((line, index) =>
							index > 0 ? (
								<span
									className=""
									key={`heading-text-${line}-${Math.random()}`}
								>
									<br />
									{line}
								</span>
							) : (
								<span className="" key={`heading-text-${line}`}>
									{line}
								</span>
							)
						)
					) : (
						<span className="" key={`heading-text-${item.value}`}>
							{item.value}
						</span>
					)
				)}
			</div>
		);
	}
};
