import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { Button } from '../../atoms/button';
import { PBSLink } from '../../atoms/link';
import { TextInput } from '../../atoms/text-input';
import { Textarea } from '../../atoms/textarea';

import { eventFormStepOneErrors, eventFormStepOneValues } from '../../../types';
import { filterObject } from '../../../utils';
import { RadioButton } from '../../atoms/radio-button';

const EventFormFields = ({
	values,
	errors,
	handleChange,
	firstStepsKeys,
	locale,
	isSubmitting,
}) => {
	const refs = {};
	firstStepsKeys.forEach((key) => {
		refs[key] = undefined;
	});
	const inputRefs = useRef(refs);
	useEffect(() => {
		const firstStepsErrors = filterObject(firstStepsKeys, errors);
		if (Object.keys(firstStepsErrors).length > 0) {
			const inputRef =
				inputRefs.current[
					Object.keys(firstStepsErrors).find(
						(key) => firstStepsErrors[key] !== false
					)
				];
			return inputRef?.focus();
		}
	}, [errors, firstStepsKeys]);
	return (
		<div className="block px-4 py-10">
			{locale === 'ie' ? (
				<>
					<fieldset className="flex flex-col bg-white grow space-y-5 mb-5">
						<legend className=" text-brand-blue-400 text-base-f font-centra-medium">
							Are you a Peninsula client?
						</legend>

						<div className="flex flex-wrap gap-2 ">
							<RadioButton
								defaultChecked={values.isClient === 'no'}
								labelText="No"
								id="eventisClient"
								name="isClient"
								value={values.isClient}
								ref={(node) => {
									inputRefs.current.isClient = node;
								}}
								onChange={() =>
									handleChange({
										target: {
											name: 'isClient',
											value: 'no',
										},
									})
								}
								showValidation={errors.isClient}
								validationMessage={
									errors.isClient
										? 'Please select an option'
										: ''
								}
							/>

							<RadioButton
								defaultChecked={values.isClient === 'yes'}
								labelText="Yes"
								id="eventisClient"
								name="isClient"
								ref={(node) => {
									inputRefs.current.isClient = node;
								}}
								value={values.isClient}
								onChange={() =>
									handleChange({
										target: {
											name: 'isClient',
											value: 'yes',
										},
									})
								}
								showValidation={errors.isClient}
								validationMessage={
									errors.isClient
										? 'Please select an option'
										: ''
								}
							/>
						</div>
						{errors.isClient && (
							<p className="text-brand-red-500">
								Please select an option
							</p>
						)}
					</fieldset>
					{values.isClient === 'yes' && locale === 'ie' ? (
						<TextInput
							id="eventCAN"
							name="CAN"
							labelText="Your CAN"
							aria-required="true"
							className="w-full mb-3 wrapper-small"
							ref={(node) => {
								inputRefs.current.CAN = node;
							}}
							value={values.CAN}
							onChange={handleChange}
							showValidation={
								values.isClient === 'yes' && locale === 'ie'
									? errors.CAN
									: false
							}
							validationMessage={
								errors.CAN ? 'Please enter your CAN' : ''
							}
							space={false}
						/>
					) : null}
				</>
			) : null}
			<fieldset className={clsx('flex flex-col bg-white grow')}>
				<legend className="sr-only">Enter your personal details</legend>

				<TextInput
					id="eventFirstName"
					name="FirstName"
					onChange={handleChange}
					value={values.FirstName}
					labelText="First name"
					aria-required="true"
					className="w-full wrapper-small"
					ref={(node) => {
						inputRefs.current.FirstName = node;
					}}
					showValidation={errors.FirstName}
					validationMessage={
						errors.FirstName ? 'Please enter your first name' : ''
					}
				/>

				<TextInput
					id="eventLastName"
					name="LastName"
					onChange={handleChange}
					value={values.LastName}
					labelText="Last name"
					aria-required="true"
					className="w-full wrapper-small"
					ref={(node) => {
						inputRefs.current.LastName = node;
					}}
					showValidation={errors.LastName}
					validationMessage={
						errors.LastName ? 'Please enter your last name' : ''
					}
				/>

				<TextInput
					id="eventCompany"
					name="Company"
					onChange={handleChange}
					value={values.Company}
					labelText="Company"
					aria-required="true"
					className="w-full wrapper-small"
					ref={(node) => {
						inputRefs.current.Company = node;
					}}
					showValidation={errors.Company}
					validationMessage={
						errors.Company ? 'Please enter your company' : ''
					}
				/>

				<TextInput
					id="eventEmail"
					type="email"
					name="Email"
					onChange={handleChange}
					value={values.Email}
					labelText="Email address"
					aria-required="true"
					className="w-full wrapper-small"
					ref={(node) => {
						inputRefs.current.Email = node;
					}}
					showValidation={!!errors.Email}
					validationMessage={errors.Email ? errors.Email : ''}
				/>
				{locale === 'en' && (
					<TextInput
						id="eventPhone"
						type="text"
						name="Phone"
						onChange={handleChange}
						value={values.Phone}
						labelText="Phone number"
						aria-required="true"
						className="w-full wrapper-small"
						ref={(node) => {
							inputRefs.current.Phone = node;
						}}
						showValidation={locale === 'en' ? errors.Phone : false}
						validationMessage={errors.Phone ? errors.Phone : ''}
					/>
				)}

				<hr className="mt-1 mb-10 border-blue-300" />

				<Textarea
					id="additionalCommentsSeminars"
					name="additionalCommentsSeminars"
					onChange={handleChange}
					value={values.additionalCommentsSeminars}
					labelText="Additional comments"
					className="mb-6"
					inputClassName="w-full !mb-2 border-blue-500 border-solid wrapper-small bg-brand-pale-300"
					rows={6}
					ref={(node) => {
						inputRefs.current.additionalCommentsSeminars = node;
					}}
				/>
			</fieldset>

			<p className="mb-14 text-base-f">
				View our{' '}
				<PBSLink
					to={`https://www.peninsulagrouplimited.com${
						locale === 'ie' ? '/ie/' : '/'
					}privacy-policy/`}
					variant="Link"
				>
					privacy notice
				</PBSLink>
				.
			</p>
			<Button
				id="eventSubmit"
				type="submit"
				className={clsx(isSubmitting ? 'justify-center loading' : null)}
			>
				<span className={clsx(isSubmitting ? 'invisible' : null)}>
					Book my place
				</span>
				{isSubmitting ? (
					<span className="sr-only" aria-live="assertive">
						Submitting your details, please wait...
					</span>
				) : null}
			</Button>
		</div>
	);
};

EventFormFields.defaultProps = {
	values: {},
	errors: {},
};

EventFormFields.propTypes = {
	values: eventFormStepOneValues,
	errors: eventFormStepOneErrors,
	handleChange: PropTypes.func.isRequired,
	firstStepsKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	locale: PropTypes.string.isRequired,
};

export { EventFormFields };
