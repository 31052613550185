import React from 'react';
import PropTypes from 'prop-types';
import { renderNodeRule, renderMarkRule, StructuredText } from 'react-datocms';
import {
	isParagraph,
	isLink,
	isRoot,
	isList,
} from 'datocms-structured-text-utils';
import clsx from 'clsx';
import { PBSLink } from '../../atoms/link';
import { structuredTextContent } from '../../../types';

function PayrollHeader({ content, title }) {
	return (
		<div className="flex flex-col justify-center items-start">
			<h1 className="text-2xl font-castledown-bold text-center text-black pb-3">
				{title}
			</h1>
			<div className="text-base text-start text-grey-700">
				<StructuredText
					data={content}
					customNodeRules={[
						renderNodeRule(isList, ({ node, children, key }) => {
							let ListTag;
							let listClassName;

							if (node.style === 'bulleted') {
								ListTag = 'ul';
								listClassName = 'list-disc';
							}
							if (node.style === 'numbered') {
								ListTag = 'ol';
								listClassName = 'list-decimal';
							}

							return (
								<ListTag
									className={clsx(
										'my-4 md:my-5 text-xl font-centra-light list-inside',
										listClassName
									)}
									key={key + node.style}
								>
									{children}
								</ListTag>
							);
						}),
						renderNodeRule(isLink, ({ node, children, key }) => (
							<PBSLink to={node.url} variant="Link" key={key}>
								{children}
							</PBSLink>
						)),
						renderNodeRule(
							isParagraph,
							({
								adapter: { renderNode },
								node,
								children,
								key,
								ancestors,
							}) => {
								if (node.children[0].type === 'inlineItem') {
									return (
										<React.Fragment key={key}>
											{children}
										</React.Fragment>
									);
								}
								if (isRoot(ancestors[0])) {
									return renderNode(
										'p',
										{
											key,
											className:
												'mb-4 md:mb-5 text-xl font-centra-light last-of-type:mb-0',
										},
										children
									);
								}
								return (
									<React.Fragment key={key}>
										{children}
									</React.Fragment>
								);
							}
						),
					]}
					customMarkRules={[
						renderMarkRule('strong', ({ children, key }) => (
							<span className="font-centra-book" key={key}>
								{children}
							</span>
						)),
					]}
				/>
			</div>
		</div>
	);
}

PayrollHeader.defaultProps = {
	title: '',
};

PayrollHeader.propTypes = {
	content: PropTypes.arrayOf(structuredTextContent).isRequired,
	title: PropTypes.string,
};
export default PayrollHeader;
