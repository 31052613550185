import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLocation } from '@reach/router';
import { Button } from '../../../../../atoms/button';
import { CallUs } from '../../../../../atoms/call-us';
import { SidebarHeader } from '../../../../../atoms/sidebar-header-v2-ca';
import { TextInput } from '../../../../../atoms/text-input-v2-ca';

import { useFormValidation } from '../../../../../../hooks/form-validation-modal-ca';
import { useSalesforceApiCA } from '../../../../../../hooks/salesforce-ca';
import {
	sleep,
	sendTrackingData,
	getCookie,
	pageUrlFomater,
} from '../../../../../../utils';

import { useSalesforceAzureApi } from '../../../../../../hooks/salesforce-azure-ca';

const initialValidations = {
	firstName: { isValid: true },
	lastName: { isValid: true },
	companyName: { isValid: true },
	email: { isValid: true },
	phoneNumber: { isValid: true },
	callFrom: { isValid: true },
	callTo: { isValid: true },
	jobTitle: { isValid: true },
};

const AdviceModal = ({ setReference, customFormField, isMobile = false }) => {
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);
	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const [isError, setIsError] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const jobTitleRef = useRef(null);

	const { validations, validateForm } = useFormValidation(initialValidations);
	const { fetchSalesforceCA } = useSalesforceApiCA();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const sideBarHeaderText =
		customFormField?.customFormTitle || 'Schedule a FREE advice call';
	const sideBarSubHeaderText =
		customFormField?.customFormSubtitle ||
		'Need immediate help with an issue? Let us know how to get in touch with you.';
	const submitButtonText =
		customFormField?.customFormButton || sideBarHeaderText;

	const { fetchSalesforceAzure } = useSalesforceAzureApi();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-form',
			click_type: 'primary',
			click_title: sideBarHeaderText,
			click_text: submitButtonText,
		});
	};

	const handleCallbackTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: sideBarHeaderText,
		});
	};
	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);
	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);
		if (!isValid) return;

		setSubmitting(true);

		const payloadData = {
			firstName: formEntries.firstName,
			lastName: formEntries.lastName,
			company: formEntries.companyName,
			phoneNumber: formEntries.phoneNumber,
			email: formEntries.email,
			leadSource: 'Website',
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
			pageUrl,
			jobTitle: formEntries.jobTitle,
			utmCampaign: getCookie('utm_campaign'),
			utmMedium: getCookie('utm_medium'),
			utmSource: getCookie('utm_source'),
			utmTerm: getCookie('utm_term'),
		};

		const response = await fetchSalesforceCA(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		if (response.status !== 200) {
			localStorage.removeItem('formEmail');
			localStorage.setItem('formEmail', formEntries.email);
			await fetchSalesforceAzure(payloadData);
		}

		await sleep(500);
		setSubmitting(false);
		handleClickTracking();

		switch (response.status) {
			case 200:
				setReference(response.body.identifier);
				handleCallbackTracking();
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	useEffect(() => {
		if (!validations.firstName.isValid && firstNameRef.current) {
			return firstNameRef.current.focus();
		}

		if (!validations.lastName.isValid && lastNameRef.current) {
			return lastNameRef.current.focus();
		}

		if (!validations.companyName.isValid && companyRef.current) {
			return companyRef.current.focus();
		}

		if (!validations.email.isValid && emailRef.current) {
			return emailRef.current.focus();
		}

		if (!validations.phoneNumber.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}

		if (!validations.jobTitle.isValid && jobTitleRef.current) {
			return jobTitleRef.current.focus();
		}
	}, [validations]);

	return isError ? (
		<>
			<div className="flex flex-col justify-between w-full mx-auto grow max-w-sm">
				<SidebarHeader
					heading="We've run into a technical error with your submission"
					text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
					ariaLive="assertive"
				/>
				<CallUs className="p-4" />
			</div>
		</>
	) : (
		<>
			<form
				onSubmit={submitHandler}
				className="flex flex-col justify-between w-full mx-auto grow max-w-sm"
			>
				<SidebarHeader
					heading={sideBarHeaderText}
					text={sideBarSubHeaderText}
				/>
				<div className="flex flex-col grow">
					<fieldset
						className="flex flex-col px-4 pt-6 bg-white grow"
						disabled={submitting}
					>
						<legend className="sr-only">
							Enter your personal details
						</legend>
						<TextInput
							labelText="First name"
							id="callbackFirstName"
							name="firstName"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your first name"
							showValidation={!validations.firstName.isValid}
							ref={firstNameRef}
						/>

						<TextInput
							labelText="Last name"
							id="callbackLastName"
							name="lastName"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your last name"
							showValidation={!validations.lastName.isValid}
							ref={lastNameRef}
						/>

						<TextInput
							labelText="Company"
							id="callbackCompany"
							name="companyName"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your company name"
							showValidation={!validations.companyName.isValid}
							ref={companyRef}
						/>

						<TextInput
							labelText="Job title"
							id="callbackJobTitle"
							name="jobTitle"
							aria-required="false"
							className="w-full wrapper-small"
							validationMessage="Please enter your job title"
							showValidation={!validations.jobTitle.isValid}
							ref={jobTitleRef}
						/>

						<TextInput
							labelText="Email address"
							id="callbackEmail"
							name="email"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your email address"
							showValidation={!validations.email.isValid}
							ref={emailRef}
						/>

						<TextInput
							labelText="Phone number"
							type="tel"
							id="callbackPhoneNumber"
							name="phoneNumber"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter a phone number"
							showValidation={!validations.phoneNumber.isValid}
							ref={phoneNumberRef}
						/>
					</fieldset>
				</div>
				<div className="p-4 bg-white max-w-md">
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								submitting && 'justify-center loading'
							)}
						>
							<span
								className={clsx(
									submitting ? 'invisible' : null
								)}
							>
								{isMobile
									? 'Speak to an expert'
									: 'Talk to us today'}
							</span>
							{submitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
						<div className="mt-4 mb-0 text-xs">
							View our privacy policy{' '}
							<a
								target="_self"
								href="/ca/privacy-policy"
								className="hover:bg-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center text-xs transition-colors focus:outline-0 focus:shadow-focus hover:bg-transparent focus:bg-transparent text-blue-400 decoration-1 hover:text-brand-blue-400 focus:shadow-focus focus:outline-focus text-center"
							>
								here
							</a>
							.
						</div>
					</div>
				</div>
			</form>
		</>
	);
};

AdviceModal.defaultProps = {
	customFormField: '',
};

AdviceModal.propTypes = {
	setReference: PropTypes.func.isRequired,
	customFormField: PropTypes.node,
	isMobile: PropTypes.bool.isRequired,
};

export { AdviceModal };
