// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { parseMarkdown } from '../utils/parse-md';
import { DefaultLayout } from '../layouts/default';
import { parseMarkdownUK } from '../utils/parse-md-uk';
import { HrefLang } from '../components/templates/hrefLang';
import { Head } from '../components/templates/head';
import { pageTypes } from '../utils/url-helpers';
import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';

// markup
const LegalDocumentPage = ({ location, data }) => {
	const containerStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '30vh',
		width: '100vw',
		backgroundImage: `url(https://www.datocms-assets.com/64336/1712342621-hero_privacy.jpg)`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	};

	const contentMD = data.datoCmsLegalDocument.content;
	const { locale } = data.datoCmsLegalDocument;
	const parsedMD =
		locale === 'en' ? parseMarkdownUK(contentMD) : parseMarkdown(contentMD);
	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.legalDocuments,
			location,
			data: data.datoCmsLegalDocument,
		}),
	};
	const metaContent = {
		noIndex: data.datoCmsLegalDocument.noIndex,
		noFollow: data.datoCmsLegalDocument.noFollow,
		metaInformation: data.datoCmsLegalDocument.metaInformation,
		fallbackTitle: data.datoCmsLegalDocument.title,
		fallbackDescription: data.datoCmsLegalDocument.longIntroduction || '',
		canonicalUrl: data.datoCmsLegalDocument?.canonicalUrl || location?.href,
	};
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsLegalDocument.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<div style={containerStyle}>
				<div className="text-center text-white lg:text-4xl text-3xl m-2">
					<h1>{data.datoCmsLegalDocument.title}</h1>
				</div>
			</div>
			<div className="max-w-7xl mx-auto text-lg lg:text-justify md:p-8 p-3 leading-normal flex flex-wrap overflow-auto !sm:text-sm">
				<div
					dangerouslySetInnerHTML={{ __html: parsedMD }}
					className="break-words table td legal"
				/>
			</div>
		</GoogleReCaptchaProvider>
	);
};

const WrappedLegalDocument = ({ location, data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<LegalDocumentPage data={data} location={location} />
	</DefaultLayout>
);

export default WrappedLegalDocument;

export const query = graphql`
	query LegalDocumentQuery($locale: String!, $slug: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsLegalDocument(locale: { eq: $locale }, slug: { eq: $slug }) {
			id
			noFollow
			noIndex
			originalId
			slug
			title
			content
			locale
			canonicalUrl
			metaInformation {
				description
				title
			}
			allSeoLocales: _allSlugLocales {
				locale
			}
		}
	}
`;
