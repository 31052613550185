// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import loadScript from 'load-script2';
import { render as toPlainText } from 'datocms-structured-text-to-plain-text';

import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';

import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessForm } from '../components/organisms/success-form';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { AdviceBanner } from '../components/organisms/advice-banner';
import { EventMap } from '../components/organisms/event-map';
import { CardContainer } from '../components/organisms/card-container';
import { EventForm } from '../components/organisms/event-form';
import { RteContent } from '../components/organisms/rte-content';
import { FeefoTestimonials } from '../components/molecules/feefo';
import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';

import { ResourceHeader } from '../components/molecules/resource-header';
import { EventDetails } from '../components/molecules/event-details';

import { Drawer } from '../components/atoms/drawer';
import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { EventSuccess } from '../components/atoms/event-success';

import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { sendTrackingData } from '../utils';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';

import { useDrawer } from '../contexts/drawer-context';
import { useMarketo } from '../contexts/marketo-context';
import { EventDate } from '../components/atoms/event-date';
import { LOCALE_PATHS } from '../utils/locale';
import { HrefLang } from '../components/templates/hrefLang';

const EventPage = ({ data, location }) => {
	const { drawerRef } = useDrawer();
	const MktoForms2Instances = useMarketo();

	const adviceFormRef = useRef(null);

	const { locale } = data.datoCmsEventPage;
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const formId = LOCALE_PATHS[locale] === 'ie' ? 6778 : 1055;
	const eventFormRef = useRef(null);
	const [technicalError, setTechnicalError] = useState(false);
	const [eventResponse, setEventResponse] = useState(false);

	const {
		noIndex,
		noFollow,
		metaInformation,
		canonicalUrl,
		originalId,
		title,
		speaker,
		thumbnail,
		content,
		adviceBanner,
		eventLocation,
		mainHeader,
		eventDateMachine,
		eventDate,
		eventTime,
		eventLength,
		eventDateTime,
		similarEvents,
		shortDescription,
		fallbackTitle,
		fallbackDescription,
	} = data.datoCmsEventPage;

	const { subText } = data.datoCmsSiteSpecificSetting;

	const metaContent = {
		noIndex,
		noFollow,
		metaInformation,
		fallbackTitle,
		fallbackDescription,
		canonicalUrl: canonicalUrl || location.href,
	};

	let titleAddress = toPlainText(eventLocation.address); // Ensure there is a title
	if (!titleAddress.includes(',')) {
		const addressStr = titleAddress.replace(
			/(<p[^>]+?>|<p>|(<\/p>)(?!.*\\)$)/gim, // Strip all <p> tags and ending </p>
			''
		);
		titleAddress = addressStr.replace(/(\s?<\/p>)/gim, ', '); // Remove remaining </p> tags and any whitespace before and replace with comma
	} else {
		titleAddress = titleAddress.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, ' ');
	}

	const resourceHeaderContent = {
		id: originalId,
		title: titleAddress,
		speaker,
		categories: [{ name: 'Human resources' }],
	};

	const rteEventContent = {
		...content,
	};

	const adviceBannerContent = {
		...adviceBanner,
		title: adviceBanner?.title.value.document.children,
		ctaLink: adviceBanner?.ctaLink,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.event,
			location,
			data: data.datoCmsEventPage,
		}),
		image: getImageSeoSchema(mainHeader?.primaryImage),
	};

	const eventFormClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'event-form',
			click_type: 'primary',
			...trackData,
		});
	};

	useEffect(() => {
		const loadForm = () =>
			loadScript(
				'https://app-lon04.marketo.com/js/forms2/js/forms2.min.js'
			)
				.then(() => {
					MktoForms2Instances.get(
						'https://app-lon04.marketo.com'
					).loadForm(
						'https://app-lon04.marketo.com',
						'023-IMK-845',
						formId
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, [formId, MktoForms2Instances]);

	const eventFormProps = {
		formId,
		locale,
		MktoForms2Instances,
		promoCode: data.datoCmsEventPage.promotionCode,
		setEventResponse,
		setTechnicalError,
		eventFormClickTracking,
		technicalError,
		pageUrl: location.href,
	};
	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsEventPage.internal.type,
		data.datoCmsEventPage
	);

	breadCrumbItems[
		breadCrumbItems.length - 1
	].title = `${eventLocation.venueName} ${eventDate}`;
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsEventPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />

			<BreadCrumbs breadCrumbItems={breadCrumbItems} />

			<section className="px-3 mx-auto max-w-m-screen mb-14 md:mb-20 lg:px-8 md:grid md:grid-cols-[1fr_32%] md:gap-12 lg:gap-28">
				<div>
					<EventDate date={eventDate} time={eventTime} />

					<ResourceHeader
						className="!px-0"
						content={resourceHeaderContent}
						variant="Event"
						description={rteEventContent}
						heading={data.datoCmsEventPage.heading}
					/>
					<EventDetails
						className="md:hidden mb-14"
						image={thumbnail}
						date={eventDateMachine}
						startDateTime={eventDateTime}
						description={shortDescription}
						title={title}
						dateOrdinal={eventDate}
						time={eventTime}
						length={eventLength}
						pageUrl={location.href}
						location={eventLocation}
						drawerRef={eventFormRef}
					/>
					<RteContent
						containerClassName="md:flex-1"
						content={rteEventContent}
						drawerRef={adviceFormRef}
						buttonFormRef={eventFormRef}
						borderTop
						borderBottom
					/>
					<EventMap location={eventLocation} />
				</div>
				<EventDetails
					className="hidden md:block"
					image={thumbnail}
					date={eventDateMachine}
					dateOrdinal={eventDate}
					time={eventTime}
					length={eventLength}
					pageUrl={location.href}
					location={eventLocation}
					drawerRef={eventFormRef}
				/>
			</section>
			{similarEvents.length > 0 ? (
				<div className="mt-2xl-f md:mt-3xl-f">
					<CardContainer
						cardContainerContent={{
							cards: similarEvents,
							id: 'related-events-container',
							title: 'Similar events',
							subText,
							to: '/events',
							toAll: '/events',
							buttonCopy: 'View all events',
							viewAllTextOverride: true,
						}}
						variant="ViewAllScrollable"
						cardVariant="Events"
					/>
				</div>
			) : null}

			<BreadCrumbs breadCrumbItems={breadCrumbItems} />

			<FeefoTestimonials locale={locale} />

			<AdviceBanner
				drawerRef={adviceFormRef}
				adviceBannerContent={adviceBannerContent}
			/>

			<TrustpilotReviews
				locale={locale}
				className="!pb-0"
				wrapperClassName="border-b-1 border-blue-200 pb-xl-f md:pb-2xl-f"
			/>
			<Drawer
				label="Book your place"
				ref={eventFormRef}
				open={drawerRef === eventFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{!eventResponse ? (
					<EventForm {...eventFormProps} />
				) : (
					<EventSuccess
						id={originalId}
						location={eventLocation}
						startDateTime={eventDateTime}
						time={eventTime}
						dateOrdinal={eventDate}
						description={
							shortDescription ||
							rteEventContent?.value?.document?.children[0]
								?.children[0]?.value
						}
						title={title}
						eventResponse
					/>
				)}
			</Drawer>

			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query event($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsSiteSpecificSetting {
			subText: relatedEventsSubText
		}
		datoCmsEventPage(slug: { eq: $slug }, locale: { eq: $locale }) {
			title
			heading
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			originalId
			id
			internal {
				type
			}
			locale
			promotionCode
			speaker {
				name
				image {
					alt
					url
					gatsbyImageData(width: 44, height: 44, layout: FIXED)
				}
				jobTitle
				bio {
					value
				}
				linkedinUrl
				facebookUrl
				twitterUrl
				emailAddress
			}
			thumbnail {
				alt
				url
				gatsbyImageData(
					layout: CONSTRAINED
					aspectRatio: 1.3
					placeholder: DOMINANT_COLOR
					width: 800
				)
			}
			meta {
				updatedAt
				updatedAtOrdinal: updatedAt(formatString: "MMM Do YYYY")
			}
			content {
				value
				links {
					__typename
					... on DatoCmsButton {
						id: originalId
						buttonCopy
						link
						openForm
					}
				}
				blocks {
					__typename
					... on DatoCmsTickListContainer {
						id: originalId
						listItems {
							text
							subText
							id
						}
					}
				}
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			eventLocation {
				venueName
				venueLocation {
					latitude
					longitude
				}
				address {
					value
				}
			}
			eventTime
			eventDate: dateTime(formatString: "MMM Do YYYY")
			eventDateMachine: dateTime(formatString: "YYYY-MM-DD")
			eventDateTime: dateTime
			eventLength
			shortDescription
			similarEvents {
				id
				internal {
					type
				}
				title
				slug
				eventTime
				eventDate: dateTime(formatString: "MMM Do YYYY")
				eventDateTime: dateTime
				eventLocation {
					address {
						value
					}
					venueName
				}
				shortDescription
			}
		}
	}
`;

const WrappedEventPage = ({ data, location }) => (
	<DefaultLayout
		className="bg-white"
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<EventPage data={data} location={location} />
	</DefaultLayout>
);

export default WrappedEventPage;
