import { render } from 'datocms-structured-text-to-html-string';
import { getBreadcrumLocale, getBreadcrumbs } from './url-helpers';

export const getOrganizationSeoSchema = ({ data, url }) => {
	if (!data || !url) return;

	return {
		'@context': 'https://schema.org',
		'@type': 'Organization',
		url,
		'logo': data.datoCmsSiteSpecificSetting?.logo?.url,
	};
};

export const getImageSeoSchema = (image) => {
	if (!image?.url) return;

	return {
		'@context': 'https://schema.org/',
		'@type': 'ImageObject',
		'contentUrl': image?.url,
		'creditText': 'Peninsula',
	};
};

export const getBreadcrumbsSeoSchema = ({
	pageType,
	location,
	data,
	parentPageData,
}) => {
	const breadCrumbItems = getBreadcrumbs(pageType, data, parentPageData);
	const breadCrumbItemsLength = breadCrumbItems.length - 1;
	const showBreadcrumbsLocale = getBreadcrumLocale(location);
	const domainUrl = 'https://www.peninsulagrouplimited.com';
	const itemListElement = breadCrumbItems.map((item, index) => {
		const itemObj = {
			'@type': 'ListItem',
			'position': item.hierarchyPosition,
			'name': item.title,
		};

		if (index !== breadCrumbItemsLength) {
			itemObj.item = `${domainUrl}/${showBreadcrumbsLocale}${item.url}`;
		}
		return itemObj;
	});

	return {
		'@context': 'https://schema.org',
		'@type': 'BreadcrumbList',
		itemListElement,
	};
};

export const getFAQSeoSchema = (faqQuestions) => {
	if (!faqQuestions) return;

	const mainEntity = faqQuestions.map(({ question, answer }) => ({
		'@type': 'Question',
		'name': question,
		'acceptedAnswer': {
			'@type': 'Answer',
			'text': render({
				type: 'root',
				children: answer,
			}),
		},
	}));

	return {
		'@context': 'https://schema.org/',
		'@type': 'FAQPage',
		mainEntity,
	};
};

export const getLocalBusinessSeoSchema = ({ locations, url }) => {
	if (!locations.length) return;

	const hqCountry = 'United Kingdom';
	const hqCity = 'Manchester';
	const hqGeoCoordinates = {
		latitude: 53.4893675,
		longitude: -2.2411016,
	};

	const ukLocation = locations.find(
		(location) => location.name === hqCountry
	);

	if (!ukLocation) return;

	const hqLocation = ukLocation?.locations.find(
		(location) => location.name === hqCity
	);
	const otherLocations = ukLocation?.locations.filter(
		(location) => location.name !== hqCity
	);

	const [hqName, ...hqAddress] = hqLocation.address.split('\n');

	const addressSchema = [
		'streetAddress',
		'addressRegion',
		'postalCode',
		'addressCountry',
	];

	const hqAddressObj = addressSchema.map((schemaItem, index) => ({
		[schemaItem]: hqAddress[index],
	}));

	const otherDepartments = otherLocations.map(
		({ name, image, contactDetails }) => ({
			'@type': name,
			'image': [image.url],
			name,
			'telephone': contactDetails[0]?.phoneNumber,
		})
	);

	return {
		'@context': 'https://schema.org/',
		'@type': 'LocalBusiness',
		'image': [hqLocation.image.url],
		'name': hqName,
		'address': {
			'@type': 'PostalAddress',
			...Object.assign({}, ...hqAddressObj),
		},
		'geo': {
			'@type': 'GeoCoordinates',
			...hqGeoCoordinates,
		},
		url,
		'telephone': hqLocation.contactDetails[0]?.phoneNumber,
		'department': otherDepartments,
	};
};

export const getBlogsSchema = (blog) => {
	if (!blog) return;

	const pageUrl = `https://www.peninsulagrouplimited.com/ca/resource-hub/${
		blog.categories?.[0]?.slug || ''
	}/${blog.slug || ''}`;
	const dateModifiedISO = blog.meta?.updatedAt?.split('T')[0];

	return {
		'@context': 'https://schema.org',
		'@type': 'BlogPosting',
		'mainEntityOfPage': {
			'@type': 'WebPage',
			'@id': pageUrl,
		},
		'headline': blog.title || '',
		'image':
			blog.thumbnail?.url ||
			'https://www.datocms-assets.com/64336/1718702923-peninsula-logo.png',
		'author': {
			'@type': 'Person',
			'name': blog.author?.name || '',
			'jobTitle': blog.author?.jobTitle || '',
		},
		'publisher': {
			'@type': 'Organization',
			'name': 'Peninsula Canada',
			'url': 'https://www.peninsulagrouplimited.com/ca/',
			'logo': {
				'@type': 'ImageObject',
				'url': 'https://www.datocms-assets.com/64336/1718702923-peninsula-logo.png',
			},
		},
		'datePublished': blog.created || '',
		'dateModified': dateModifiedISO || '',
	};
};
