// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
	StructuredText,
	renderNodeRule,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { isHeading } from 'datocms-structured-text-utils';
import clsx from 'clsx';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { ShareButton } from '../components/glu/atoms/share-button';
import { CardList } from '../components/glu/molecules/course-list';

import { getBreadcrumLocale, pageTypes } from '../utils/url-helpers';
import VideoPlayer from '../components/glu/molecules/video-player';
import { Head } from '../components/templates/head';
import {
	getOrganizationSeoSchema,
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { DefaultLayout } from '../layouts/default';
import { LOCALE_PATHS, buildLink } from '../utils/locale';
import { getCookie } from '../utils';
import { UnlockAccessForm } from '../components/glu/organisms/unlock-access-form';

const WebinarOnDemand = ({ data, location }) => {
	const [hasSignedUp, setHasSignedUp] = useState(false);
	const [consentResponse, setConsentResponse] = useState('');
	const {
		slug,
		title,
		content,
		date,
		formattedDate,
		timeStart,
		timeEnd,
		videoEmbed,
		sessions,
	} = data.datoCmsOnDemandWebinar;

	const { mainLogo } = data.allDatoCmsGluLogo.nodes[0];
	const locale = LOCALE_PATHS[data.datoCmsOnDemandWebinar.locale];
	const metaContent = {
		noIndex: data.datoCmsOnDemandWebinar.noIndex,
		noFollow: data.datoCmsOnDemandWebinar.noFollow,
		metaInformation: data.datoCmsOnDemandWebinar.metaTags,
		fallbackTitle: data.datoCmsOnDemandWebinar.eLearningTitle,
		fallbackDescription: '',
		canonicalUrl:
			data.datoCmsOnDemandWebinar?.canonicalUrl || location?.href,
	};

	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			data: data.datoCmsOnDemandWebinar,
			pageType: pageTypes.liveWebinar,
			location,
		}),
		image: getImageSeoSchema(mainLogo),
	};
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const urlParams = new URLSearchParams(window.location.search);
			const myParam = urlParams.get('session');
			if (
				myParam === 'attended-session' &&
				locale !== 'ie' &&
				locale !== 'ca'
			) {
				setHasSignedUp(true);
			}
		}
	}, [locale]);

	React.useEffect(() => {
		if (getCookie('webinarContent')) {
			setHasSignedUp(true);
		}
	}, []);
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<section className="bg-brand-pale-400">
				<div className="px-4 py-12 mx-auto bg-brand-pale-400 max-w-m-screen">
					<p>
						<a
							href={buildLink(locale, 'webinars')}
							className="underline"
						>
							Webcasts
						</a>
						<span className="mx-4">/</span>
						{title}
					</p>

					<h1 className="my-12 text-4xl font-bold">{title}</h1>

					{consentResponse || hasSignedUp ? (
						<div className="lg:flex lg:gap-8">
							{videoEmbed?.providerUid && (
								<div className="mb-8 lg:mb-0 lg:basis-1/2 aspect-video">
									<VideoPlayer videoEmbed={videoEmbed} />
								</div>
							)}
							<div className="lg:basis-1/2">
								{content?.value && (
									<StructuredText
										data={content}
										customNodeRules={[
											renderNodeRule(
												isHeading,
												({ node, children, key }) => {
													const HeadingTag = `h${node.level}`;
													return (
														<HeadingTag
															className="mb-5 text-2xl font-bold lg:text-3xl font-castledown-regular"
															key={key}
														>
															{children}
														</HeadingTag>
													);
												}
											),
										]}
									/>
								)}
								{date && (
									<p className="flex items-center my-6 font-bold text-blue-400">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="18"
											height="17"
											fill="none"
											className="inline-block mr-2"
										>
											<path
												fill="#3954E0"
												d="M16.401 1.812h-2.038v-.549a.587.587 0 0 0-.59-.589.587.587 0 0 0-.588.59v.548h-8.37v-.549a.587.587 0 0 0-.59-.589.587.587 0 0 0-.588.59v.548H1.598A1.6 1.6 0 0 0 0 3.41v11.422a1.6 1.6 0 0 0 1.598 1.598h14.804A1.6 1.6 0 0 0 18 14.832V3.41c0-.878-.717-1.598-1.599-1.598ZM1.598 2.989h2.039v.548c0 .327.262.59.589.59a.587.587 0 0 0 .589-.59V2.99h8.37v.548c0 .327.263.59.59.59a.587.587 0 0 0 .588-.59V2.99h2.039a.42.42 0 0 1 .42.42v1.653H1.177V3.41c0-.23.189-.421.42-.421ZM16.4 15.256H1.598a.42.42 0 0 1-.42-.42V6.239h15.644v8.596a.42.42 0 0 1-.42.421Z"
											/>
										</svg>
										<time dateTime={`${date}T${timeStart}`}>
											{formattedDate}
										</time>
										<span className="mx-2">|</span>
										{timeStart &&
											`${timeStart} - ${timeEnd}`}
									</p>
								)}
								<div className="mt-5">
									<ShareButton slug={slug} pbsWebinar />
								</div>
							</div>
						</div>
					) : (
						<div className="mx-auto sm:w-3/4 lg:w-1/2">
							<UnlockAccessForm
								pageUrl={
									typeof window !== 'undefined'
										? window.location.href
										: ''
								}
								locale={locale}
								webinar
								setConsentResponse={setConsentResponse}
							/>
						</div>
					)}
				</div>
			</section>
			{sessions.length > 0 && (consentResponse || hasSignedUp) ? (
				<section className="px-4 py-12 mx-auto max-w-m-screen">
					<h3 className="mb-5 text-2xl font-bold lg:text-3xl font-castledown-regular">
						More on demand sessions
					</h3>
					<ul
						className={clsx(
							'w-full grid gap-x-5 gap-y-2 sm:gap-y-s-f md:gap-y-base-f grid-flow-row',
							'lg:grid-cols-2 pb-4 sm:pb-5'
						)}
					>
						{sessions?.map((session) => (
							<CardList cardContent={session} key={session.id} />
						))}
					</ul>
				</section>
			) : null}
		</GoogleReCaptchaProvider>
	);
};

const WrappedWebinarOnDemand = ({ data, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<WebinarOnDemand data={data} location={location} />
	</DefaultLayout>
);

export default WrappedWebinarOnDemand;

export const query = graphql`
	query OnDemandWebinar($slug: String, $locale: String) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsOnDemandWebinar(slug: { eq: $slug }, locale: { eq: $locale }) {
			noIndex
			metaTags {
				title
				description
				image {
					url
				}
				twitterCard
			}
			slug
			title
			locale
			timeToWatchInMinutes
			date
			formattedDate: date(formatString: "MMM DD YYYY")
			timeStart
			timeEnd
			content {
				value
			}
			videoEmbed {
				thumbnailUrl
				width
				height
				providerUid
				provider
				title
			}
			sessions {
				category {
					slug
				}
				intro
				slug
				title
				timeToWatchInMinutes
				date
				formattedDate: date(formatString: "DD[/]MM[/]YY")
			}
		}
		datoCmsGluHomepage {
			eLearningTitle {
				value
			}
			eLearningSessionCards {
				title
				image {
					gatsbyImageData
					alt
				}
				description {
					value
				}
				ctaLink
				ctaText
			}
		}
		allDatoCmsGluLogo {
			nodes {
				mainLogo {
					url
					alt
				}
			}
		}
	}
`;
