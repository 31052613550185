// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';

const BottomContentVideoCampaignCA = (
	{ bottomContentVideoCampaignCAContent },
	className
) => {
	const {
		bottomSectionTitle,
		bottomSectionPrimaryLinkCopy,
		bottonSectionImage,
	} = bottomContentVideoCampaignCAContent;
	return (
		<div className={`w-full bg-white ${className}`}>
			<div className="px-6 pt-10 pb-0 sm:pb-10 lg:p-12 lg:py-28 bg-white mx-auto lg:max-w-m-screen relative">
				<div
					className="flex flex-row bg-[#1F2E7A] justify-center items-center rounded-[.75rem] max-h-[279px] px-10 py-20 lg:px-16 shadow-sm"
					// style={{
					// 	background:
					// 		'linear-gradient(186deg, rgba(32,47,125,1) 0%, rgba(34,49,131,1) 12%, rgba(57,84,224,1) 50%, rgba(32,48,126,1) 87%, rgba(31,46,122,1) 100%)',
					// }}
				>
					<div className="flex flex-col text-2xl 2xl:text-4xl lg:py-0 text-white font-castledown-regular">
						<div className="text-center -mt-3 lg:text-left">
							{bottomSectionTitle}
						</div>
						<div className="flex items-center mt-5 mx-auto lg:mx-0">
							<a
								href="tel:1(833)247-3652"
								className="transition delay-150 duration-1000 inline-flex items-center px-6 py-4 mt-2 text-lg leading-6 text-white transition-colors bg-blue-400 border-2 border-blue-400 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-centra-medium focus:outline-0 focus:shadow-focus w-max"
							>
								{bottomSectionPrimaryLinkCopy}
							</a>
						</div>
					</div>
					<img
						src={bottonSectionImage.url}
						alt=""
						className="mb-28 hidden lg:block"
					/>
				</div>
			</div>
		</div>
	);
};

export default BottomContentVideoCampaignCA;
