import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLocation } from '@reach/router';
import { Button } from '../../../../../atoms/button';
import { PBSLink } from '../../../../../atoms/link';
import { CallUs } from '../../../../../atoms/call-us';
import { SidebarHeader } from '../../../../../atoms/sidebar-header';
import { TextInput } from '../../../../../atoms/text-input';

import { useFormValidation } from '../../../../../../hooks/form-validation-ca';
import { useSalesforceApiCA } from '../../../../../../hooks/salesforce-ca';

import {
	sleep,
	sendTrackingData,
	getCookie,
	pageUrlFomater,
} from '../../../../../../utils';

import { useSalesforceAzureApi } from '../../../../../../hooks/salesforce-azure-ca';

const initialValidations = {
	firstName: { isValid: true },
	lastName: { isValid: true },
	companyName: { isValid: true },
	numberOfEmployees: { isValid: true },
	emailAddress: { isValid: true },
	phoneNumber: { isValid: true },
	postcode: { isValid: true },
};

const PricingFormCA = ({
	formTitle,
	setReference,
	setResponse,
	employeeCount,
}) => {
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);
	const jobTitleRef = useRef(null);
	const noOfEmployeesRef = useRef(null);
	const emailAddressRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const postCodeRef = useRef(null);
	const [isError, setIsError] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const [employees, setEmployees] = useState(employeeCount);

	const { validations, validateForm } = useFormValidation(initialValidations);
	const { fetchSalesforceCA } = useSalesforceApiCA();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const getServiceEnquiry = (title) => {
		if (title === 'Peninsula HR and Health & Safety') {
			return 'Peninsula Combined';
		}
		return title;
	};

	const leadSourceDetail = 'Pricing Request';

	const submitButtonText = 'Get my quote';

	const { fetchSalesforceAzure } = useSalesforceAzureApi();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'pricing-form',
			click_type: 'primary',
			click_title: formTitle,
			click_text: submitButtonText,
		});
	};

	const handleCallbackTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: formTitle,
		});
	};
	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);
	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) return;

		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);

		if (!isValid) return;

		setSubmitting(true);

		const payloadData = {
			firstName: formEntries.firstName,
			lastName: formEntries.lastName,
			company: formEntries.companyName,
			jobTitle: formEntries.jobTitle,
			numberOfEmployees: formEntries.numberOfEmployees,
			email: formEntries.emailAddress,
			phoneNumber: formEntries.phoneNumber,
			postcode: formEntries.postcode,
			leadSource: 'Website',
			leadSourceDetails: leadSourceDetail,
			serviceEnquiry: getServiceEnquiry(formTitle),
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
			pageUrl,
			utmCampaign: getCookie('utm_campaign'),
			utmMedium: getCookie('utm_medium'),
			utmSource: getCookie('utm_source'),
			utmTerm: getCookie('utm_term'),
		};

		const response = await fetchSalesforceCA(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		if (response.status !== 200) {
			localStorage.removeItem('formEmail');
			localStorage.setItem('formEmail', formEntries.emailAddress);
			await fetchSalesforceAzure(payloadData);
		}

		await sleep(500);
		setSubmitting(false);
		handleClickTracking();

		switch (response.status) {
			case 200:
				setResponse('Thanks for your interest in Peninsula');
				setReference(response.body.identifier);
				setIsError(false);
				handleCallbackTracking();
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	useEffect(() => {
		if (!validations.firstName.isValid && firstNameRef.current) {
			return firstNameRef.current.focus();
		}

		if (!validations.lastName.isValid && lastNameRef.current) {
			return lastNameRef.current.focus();
		}

		if (!validations.companyName.isValid && companyRef.current) {
			return companyRef.current.focus();
		}

		if (
			!validations.numberOfEmployees.isValid &&
			noOfEmployeesRef.current
		) {
			return noOfEmployeesRef.current.focus();
		}

		if (!validations.emailAddress.isValid && emailAddressRef.current) {
			return emailAddressRef.current.focus();
		}

		if (!validations.phoneNumber.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}

		if (!validations.postcode.isValid && postCodeRef.current) {
			return postCodeRef.current.focus();
		}
	}, [validations]);

	useEffect(() => {
		setEmployees(employeeCount);
	}, [employeeCount]);

	return isError ? (
		<>
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</>
	) : (
		<>
			<form
				onSubmit={submitHandler}
				className="flex flex-col justify-between w-full mx-auto grow"
			>
				<SidebarHeader
					heading={formTitle}
					text="Provide us with a few details and we’ll give you an estimated price."
				/>

				<div className="flex flex-col px-4 pt-10 bg-white grow pb-7">
					<TextInput
						labelText="First name"
						id="pricingFirstName"
						name="firstName"
						aria-required="true"
						className="w-full wrapper-small"
						validationMessage="Please enter your first name"
						showValidation={!validations.firstName.isValid}
						ref={firstNameRef}
					/>

					<TextInput
						labelText="Last name"
						id="pricingLastName"
						name="lastName"
						aria-required="true"
						className="w-full wrapper-small"
						validationMessage="Please enter your last name"
						showValidation={!validations.lastName.isValid}
						ref={lastNameRef}
					/>

					<TextInput
						labelText="Company"
						id="pricingCompany"
						name="companyName"
						aria-required="true"
						className="w-full wrapper-small"
						validationMessage="Please enter your company name"
						showValidation={!validations.companyName.isValid}
						ref={companyRef}
					/>

					<TextInput
						labelText="Job title - optional"
						id="pricingJobTitle"
						name="jobTitle"
						aria-required="false"
						className="w-full wrapper-small"
						ref={jobTitleRef}
					/>

					<TextInput
						type="number"
						labelText="Number of employees"
						id="pricingNumberOfEmployees"
						name="numberOfEmployees"
						aria-required="true"
						className="w-full wrapper-small"
						validationMessage="Please enter the number of employees within your company"
						showValidation={!validations.numberOfEmployees.isValid}
						ref={noOfEmployeesRef}
						value={employees !== 0 ? employees : ''}
						onChange={(e) => setEmployees(e.target.value)}
					/>

					<TextInput
						type="email"
						labelText="Email address"
						id="pricingEmail"
						name="emailAddress"
						aria-required="true"
						className="w-full wrapper-small"
						validationMessage="Please enter your email address"
						showValidation={!validations.emailAddress.isValid}
						ref={emailAddressRef}
					/>

					<TextInput
						labelText="Phone number"
						type="tel"
						id="pricingPhoneNumber"
						name="phoneNumber"
						aria-required="true"
						className="w-full wrapper-small"
						validationMessage="Please enter a phone number"
						showValidation={!validations.phoneNumber.isValid}
						ref={phoneNumberRef}
					/>

					<TextInput
						labelText="Postcode"
						id="pricingPostcode"
						name="postcode"
						aria-required="true"
						className="w-full wrapper-small max-w-[200px]"
						validationMessage="Please enter a postcode"
						showValidation={!validations.postcode.isValid}
						ref={postCodeRef}
						space={false}
					/>

					<p className="mt-6 text-base-f">
						View our{' '}
						<PBSLink to="/ca/privacy-policy" variant="Link">
							privacy notice
						</PBSLink>
						.
					</p>
				</div>

				<div className="px-4 py-12 bg-white">
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								submitting && 'justify-center loading'
							)}
						>
							<span
								className={clsx(
									submitting ? 'invisible' : null
								)}
							>
								{submitButtonText}
							</span>
							{submitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
				</div>
			</form>
		</>
	);
};

PricingFormCA.defaultProps = {
	employeeCount: 0,
};

PricingFormCA.propTypes = {
	formTitle: PropTypes.string.isRequired,
	setReference: PropTypes.func.isRequired,
	setResponse: PropTypes.func.isRequired,
	employeeCount: PropTypes.number,
};

export { PricingFormCA };
