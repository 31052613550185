/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { useLocation } from '@reach/router';
import { DrawerProvider } from '../../contexts/drawer-context';
import { ModalProvider } from '../../contexts/modal-context';
import { ScreenResizerProvider } from '../../contexts/screen-resize-context';
import { MarketoProvider } from '../../contexts/marketo-context';

import { MasterLayout } from '../master';

import { Header } from '../../components/organisms/header';
import { HeaderCA } from '../../components/organisms/header-ca';
import { Footer } from '../../components/organisms/footer';

import { Newsletter } from '../../components/molecules/newsletter';

import { getLocale } from '../../utils/locale';
import { Modal } from '../../components/organisms/modal-popup';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

const DefaultLayout = ({
	children,
	className,
	siteSettings,
	navigation,
	footerData,
	pageName,
	defaultLocale,
}) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
			datoCmsSiteSpecificSetting {
				sideNavigationOpenCopy
				sideNavigationCloseCopy
				signInCopy
				footerAddressTitle
				footerSocialTitle
				footerInternationalSitesCopy
			}
		}
	`);

	const globalSiteSettings = {
		...siteSettings,
	};

	const headerContent = {
		...data.datoCmsSiteSpecificSetting,
		...navigation,
	};

	const footerContent = {
		...data.datoCmsSiteSpecificSetting,
		...footerData,
	};

	const marketoCampaignPopup = true;
	const location = useLocation();
	const { pathname } = location;
	const dontusePage = '/ca/pricing/';

	const [localeCA, setLocaleCA] = useState(defaultLocale);

	useEffect(() => {
		setLocaleCA(getLocale(pathname));
	}, [pathname]);

	return (
		<ScreenResizerProvider>
			<ModalProvider>
				<DrawerProvider>
					<MarketoProvider>
						<MasterLayout>
							{localeCA === 'ca' ? (
								<HeaderCA
									siteTitle={data.site.siteMetadata.title}
									headerContent={headerContent}
									siteSettings={globalSiteSettings}
									pageName={pageName}
								/>
							) : (
								<Header
									siteTitle={data.site.siteMetadata.title}
									headerContent={headerContent}
									siteSettings={globalSiteSettings}
									pageName={pageName}
								/>
							)}
							<main id="main" className={className}>
								{children}
							</main>
							<Newsletter />
							<Footer
								pageName={pageName}
								footerContent={footerContent}
								siteTitle={data.site.siteMetadata.title}
							/>
							{locale === 'ca' &&
							pathname !== dontusePage &&
							marketoCampaignPopup ? (
								<Modal />
							) : null}
						</MasterLayout>
					</MarketoProvider>
				</DrawerProvider>
			</ModalProvider>
		</ScreenResizerProvider>
	);
};

export const query = graphql`
	fragment siteSettings on DatoCmsSiteSpecificSetting {
		phoneNumber
	}
	fragment navItems on DatoCmsSiteSpecificSetting {
		phoneNumber
		primaryNavigation {
			title
			link
			treeChildren {
				title
				subText
				link
				icon
				position
				locale
			}
		}
		sideNavigation {
			title
			link
		}
	}
	fragment footerItems on DatoCmsSiteSpecificSetting {
		footerAddress
		footerNavigation {
			treeChildren {
				title
				link
				position
			}
			title
		}

		footerInternationalSites {
			title
			link
		}
		footerCopyright
	}
`;

DefaultLayout.defaultProps = {
	className: '',
	pageName: '',
	siteSettings: {},
	navigation: {},
	footerData: {},
	defaultLocale: 'en',
};

DefaultLayout.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	pageName: PropTypes.string,
	siteSettings: PropTypes.objectOf(PropTypes.string),
	navigation: PropTypes.objectOf(PropTypes.arrayOf),
	footerData: PropTypes.objectOf(PropTypes.arrayOf),
	defaultLocale: PropTypes.string,
};

export { DefaultLayout };
