/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
// eslint-disable-next-line import/no-extraneous-dependencies
import styled, { keyframes } from 'styled-components';
import { titleProp } from '../../../types';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';
import * as headingStyles from './index.module.css';
import { ClientLogosCA } from '../../atoms/client-logos-ca';
import CTAbuttonsCA from '../../atoms/cta-buttons-ca';

const HeroMediaVideoCampaignCA = ({
	heroVideoCampaignCAContent,
	location,
	className,
	modalRef,
	drawerRef,
	useModalForm,
}) => {
	const {
		primaryHeaderText,
		secondaryHeaderText,
		primaryLinkCopy,
		primaryLink,
		primaryLinkOpenForm,
		secondaryLinkCopy,
		secondaryLink,
		primaryVideo,
		clientLogosBannerDisplayOnPage,
	} = heroVideoCampaignCAContent;

	const titleText = createHeadingHighlightCA({
		headings: primaryHeaderText.value.document.children[0].children,
		variant: 'red',
		className: 'md:leading-[1]',
	});

	const fadeIn = keyframes`
	0% {
        opacity: 0;
    }
	40% {
        opacity: 0;
    }
    75% {
        opacity: .75;
    }	
    100% {
        opacity: 1;
    }
  `;

	const BackgroundFadeInDiv = styled.div`
		animation: ${fadeIn} 2s linear;
	`;

	return (
		<div
			aria-label="video-campaign-page-ca"
			className={`h-full relative xs:pb-4 2xl:-mb-5 ${className}`}
		>
			<BackgroundFadeInDiv
				id="hero-video-campaign-ca"
				className="absolute top-0 left-0 z-0 h-full w-full bg-[#FCFBF2]"
			/>
			<div
				aria-label="video-campaign-page-ca"
				className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-10 items-center auto-rows-max px-6 pt-10 pb-5 lg:px-12 md:py-20 lg:py-28 xl:pt-16 xl:pb-24 gap-5 2xl:pt-[3rem] 2xl:pb-[8.5rem] relative h-fit mx-auto lg:max-w-m-screen"
			>
				{/* left panel */}
				<div className="flex flex-col items-left justify-center gap-5 row-span-10 lg:col-span-4 z-10">
					<div
						className={clsx(
							'inline font-castledown-regular xl:mx-0 inline text-4xl md:text-4xl lg:text-[2rem] xl:text-[2.5rem] 2xl:text-[2.75rem]',
							'max-h-2/5 flex items-center xl:leading-[1.1] '
						)}
					>
						{titleText}
					</div>
					<div
						className={clsx(
							'leading-[1.2] block',
							`text-[22px] md:text-2xl font-castledown-regular ${headingStyles.heading}
						leading-[1.2] xl:leading-[1.3]  
						`,
							'max-h-1/5 flex items-center'
						)}
					>
						{secondaryHeaderText}
					</div>
					<CTAbuttonsCA
						primaryLinkCopy={primaryLinkCopy}
						primaryLink={primaryLink}
						primaryLinkOpenForm={primaryLinkOpenForm}
						secondaryLinkCopy={secondaryLinkCopy}
						secondaryLink={secondaryLink}
						modalRef={modalRef}
						useModalForm={useModalForm}
						drawerRef={drawerRef}
					/>
				</div>
				{/* right panel */}
				<div className="flex items-center justify-center row-span-10 md:mt-5 lg:m-0 lg:block lg:col-span-6 z-10 ml-auto justify-center">
					{primaryVideo?.url && (
						<video width="960" height="540" controls>
							<source src={primaryVideo?.url} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					)}
				</div>
			</div>
			{clientLogosBannerDisplayOnPage && (
				<>
					<ClientLogosCA
						pathname={location.pathname}
						className="-mt-28 -mb-10 lg:-mb-0 lg:-mt-28 xl:-mt-[7rem] xl:-mb-5 2xl:-mt-[10rem] 2xl:-mb-[1.25rem] relative z-0"
					/>
					<ClientLogosCA
						pathname={location.pathname}
						className="relative -mt-5 -mb-5 block lg:hidden"
						isMobile
					/>
				</>
			)}
		</div>
	);
};

HeroMediaVideoCampaignCA.defaultProps = {
	secondaryHeaderText: '',
};

HeroMediaVideoCampaignCA.propTypes = {
	primaryHeaderText: titleProp.isRequired,
	secondaryHeaderText: PropTypes.string,
	primaryLinkCopy: PropTypes.string.isRequired,
};

export { HeroMediaVideoCampaignCA };
