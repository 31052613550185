import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { getLocale } from '../../../../utils/locale';

const ShareButton = ({ slug, link, hideTextMobile, pbsWebinar }) => {
	const copyLinkRef = useRef(null);
	const location = useLocation();
	const locale = getLocale(location.pathname);

	const copyLink = () => {
		if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
			const { origin } = window.location;
			const originalValue = copyLinkRef.current.textContent;

			navigator.clipboard.writeText(
				link ||
					(pbsWebinar
						? `${origin}/${
								locale === 'ie' ? `${locale}/` : ``
						  }webinars/on-demand/${slug}/`
						: `${origin}${slug}`)
			);

			copyLinkRef.current.textContent = 'Link copied to clipboard';

			setTimeout(() => {
				copyLinkRef.current.textContent = originalValue;
			}, 1800);
		}
	};

	return (
		<button
			onClick={() => copyLink()}
			type="button"
			className="relative z-10 flex items-center gap-2 px-3 py-1 text-black transition-all border-2 rounded-md w-max hover:bg-grey-500 hover:text-white"
		>
			<p
				className={`${hideTextMobile ? 'hidden sm:block' : 'block'}`}
				ref={copyLinkRef}
			>
				Copy share link
			</p>{' '}
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="18"
				height="18"
				fill="none"
				viewBox="0 0 13 13"
			>
				<path
					fill="currentColor"
					d="M3.594 5.295c.22-.22.463-.4.722-.543 1.443-.796 3.294-.373 4.222 1.034L7.415 6.91a1.692 1.692 0 0 0-1.917-.975c-.299.067-.584.217-.816.45L2.53 8.535a1.678 1.678 0 0 0 0 2.37 1.678 1.678 0 0 0 2.37 0l.663-.663c.604.24 1.251.335 1.89.287l-1.465 1.465a3.215 3.215 0 1 1-4.546-4.547l2.152-2.153Zm3.418-3.418L5.547 3.342a4.267 4.267 0 0 1 1.89.287l.663-.663a1.678 1.678 0 0 1 2.37 0 1.678 1.678 0 0 1 0 2.37L8.317 7.488c-.655.656-1.72.65-2.37 0a1.784 1.784 0 0 1-.363-.525L4.461 8.085c.118.18.24.334.398.491a3.22 3.22 0 0 0 4.546 0l2.152-2.152a3.214 3.214 0 1 0-4.545-4.547Z"
				/>
			</svg>
		</button>
	);
};

export { ShareButton };

ShareButton.defaultProps = {
	slug: null,
	link: null,
	hideTextMobile: false,
	pbsWebinar: false,
};

ShareButton.propTypes = {
	slug: PropTypes.string,
	link: PropTypes.string,
	hideTextMobile: PropTypes.bool,
	pbsWebinar: PropTypes.bool,
};
