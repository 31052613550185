import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as modalStyles from './index.module.css';

const Modal = () => {
	const [modal, setModal] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setModal(!modal);
		}, 3000);
		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toggleModal = () => {
		setModal(!modal);
		window.sessionStorage.setItem('marketoCampaignPopupShown', true);
	};

	if (modal) {
		document.body.classList.add(`${modalStyles.activemodal}`);
	} else {
		document.body.classList.remove(`${modalStyles.activemodal}`);
	}

	const data = useStaticQuery(graphql`
		query {
			datoCmsMarketoCampaignPopup {
				id
				name
				popUpBanner {
					url
					alt
				}
				ctaLink
				marketoCampaignPopupDisplayOnPage
			}
		}
	`);

	const popUpBannerURL = data.datoCmsMarketoCampaignPopup.popUpBanner.url;
	const popUpBannerAlt = data.datoCmsMarketoCampaignPopup.popUpBanner.alt;
	const { ctaLink } = data.datoCmsMarketoCampaignPopup;
	const { marketoCampaignPopupDisplayOnPage } =
		data.datoCmsMarketoCampaignPopup;

	return (
		<>
			{modal &&
				marketoCampaignPopupDisplayOnPage &&
				!window.sessionStorage.getItem('marketoCampaignPopupShown') && (
					<div className="modal" role="presentation">
						<div
							className={`${modalStyles.overlay}`}
							onClick={() => {
								toggleModal();
							}}
							onKeyDown={() => {
								toggleModal();
							}}
							role="presentation"
						/>

						<div
							className={`${modalStyles.modalcontent}`}
							role="banner"
						>
							<a
								className=""
								href={ctaLink}
								target="_blank"
								onClick={() => {
									toggleModal();
								}}
								onKeyDown={() => {
									toggleModal();
								}}
								rel="noreferrer"
							>
								<img
									src={popUpBannerURL}
									alt={popUpBannerAlt}
									className={`${modalStyles.campaignimage}`}
									width="314"
									height="334"
								/>
							</a>

							<button
								className={`${modalStyles.closemodal} text-white`}
								type="button"
								onClick={() => {
									toggleModal();
								}}
								onKeyDown={() => {
									toggleModal();
								}}
							>
								X
							</button>
						</div>
					</div>
				)}
		</>
	);
};

export { Modal };
