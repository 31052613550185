// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import Cookies from 'js-cookie';
import React, { useEffect, useRef } from 'react';

const VideoPlayer = ({
	videoEmbed,
	autoPlay = false,
	setVideoFinished,
	scriptReady = false,
	videosWatchedList,
	privateVideoHash,
	locale,
	setIsGlu,
}) => {
	const ref = useRef();
	useEffect(() => {
		if (
			scriptReady &&
			videoEmbed.provider === 'vimeo' &&
			setVideoFinished &&
			ref.current &&
			!videosWatchedList.includes(videoEmbed?.providerUid)
		) {
			// eslint-disable-next-line no-undef,
			const player = new Vimeo.Player(ref.current);
			const videoList =
				videosWatchedList.length > 0
					? [...videosWatchedList, videoEmbed.providerUid]
					: [videoEmbed.providerUid];
			if (setIsGlu) {
				player.on('timeupdate', ({ duration, seconds }) => {
					if (duration - seconds <= 30) {
						setVideoFinished(true);
					} else {
						setVideoFinished(false);
					}
					return Cookies.set('videoPlayed', videoList);
				});
				let curtime = 0;
				player.on('timeupdate', (data) => {
					if (data.seconds < curtime + 1 && data.seconds > curtime) {
						curtime = data.seconds;
					}
				});

				player.on('seeked', (data) => {
					if (data.seconds > curtime) {
						player.setCurrentTime(curtime);
					}
				});
			} else {
				player.on('ended', () => {
					setVideoFinished(true);
					return Cookies.set('videoPlayed', videoList);
				});
			}
		}
	}, [
		scriptReady,
		setVideoFinished,
		videoEmbed.provider,
		videoEmbed.providerUid,
		videosWatchedList,
		setIsGlu,
	]);

	return (
		<div className="mb-8 lg:b-0 lg:basis-1/2 aspect-video">
			<iframe
				title={videoEmbed.title || ''}
				src={
					videoEmbed.provider === 'youtube'
						? `https://www.youtube.com/embed/${
								videoEmbed.providerUid
						  }${autoPlay ? '?autoplay=1' : ''}`
						: `https://player.vimeo.com/video/${
								videoEmbed.providerUid
						  }${
								privateVideoHash && locale === 'en'
									? '?h=9ace720501&'
									: '?'
						  }api=1&player_id=PLAYER1&portrait=0&byline=0${
								autoPlay ? '&autoplay=1' : ''
						  }`
				}
				allow="fullscreen; picture-in-picture"
				className="w-full h-full"
				width={videoEmbed.width}
				height={videoEmbed.height}
				id="vimeo-player"
				ref={ref}
			/>
		</div>
	);
};
export default VideoPlayer;
