// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, {
	useRef,
	useLayoutEffect,
	useState,
	useCallback,
	useEffect,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// eslint-disable-next-line import/no-extraneous-dependencies
import { format } from 'date-fns';
import { PBSLink } from '../../../atoms/link';
import { Card } from '../../molecules/course-card';

import { sendTrackingData } from '../../../../utils';
import { LikeButton } from '../../atoms/like-button';
import { TextInput } from '../../../atoms/text-input';
import { Button } from '../../../atoms/button';
import { useFilters } from '../../../../hooks/filters';
import { Select } from '../../../atoms/select';
import ArrowIcon from '../../../../assets/arrow.inline.svg';
import { buildLink } from '../../../../utils/locale';
import GreyBullet from '../../../../assets/grey-bullet.svg';

const isClient = typeof window === 'object';

const CardContainer = ({
	cardContainerContent,
	cardVariant,
	location,
	data,
	associatedCategory,
	webinarsOnly = false,
	datoQueryName,
	pbsWebinar = false,
	locale,
}) => {
	const { id, title } = cardContainerContent;
	const searchRef = useRef(null);
	const containerRef = useRef();
	const queryLocale = {
		'en': 'en',
		'ie': 'en_IE',
		'en-IE': 'en_IE',
		'ca': 'en_CA',
		'en-CA': 'en_CA',
	};
	const webinarQuery = webinarsOnly ? `{ eq: "true" }` : `{ eq: "null" }`;
	const ANDQuery = associatedCategory
		? `, AND: {associatedCategory: {eq: "${associatedCategory}"}, isWebinarCatchUp: ${webinarQuery}, hideInOnDemandLibrary: { eq: "null" }, hideInOnDemandLibrary: { eq: "false" }}`
		: `, AND: {isWebinarCatchUp: ${webinarQuery},  hideInOnDemandLibrary: { eq: "null" }, hideInOnDemandLibrary: { eq: "false" }}`;
	const searchFilterBy = `{OR: [{title: {matches: {pattern: "^(?=.*${searchRef?.current?.value}).*", caseSensitive: false}}}, {intro: {matches: {pattern: "^(?=.*${searchRef?.current?.value}).*", caseSensitive: false}}}]${ANDQuery}, slug: {neq: null}},`;

	const courseQuery = ({ queryName, filter, skip, search }) => ` {
		${queryName}(first: 10, orderBy: ${filter}, skip: ${skip}, locale: ${
		queryLocale[locale] || 'en'
	}, filter: ${search})  {
			id
			title
			slug
			intro
			${
				!pbsWebinar
					? `
					isWebinarCatchUp
					category {
						title
						slug
					}`
					: ``
			}
			timeToWatchInMinutes
			date
		}
        _${queryName}Meta(locale: ${
		queryLocale[locale] || 'en'
	}, filter: ${search})  {
            count
        }
	}`;
	const itemLimit = 10;

	const {
		loading,
		error,
		pagination,
		setPagination,
		filter,
		setFilter,
		items,
		totalSearchCount,
	} = useFilters({
		queryName: datoQueryName || 'allGluCourses',
		search: searchFilterBy,
		query: courseQuery,
		data,
		itemLimit,
		customFilter: webinarsOnly ? '_createdAt_DESC' : 'title_ASC',
		searchRef,
	});

	const { currentPage, totalPages } = pagination;
	const nextPage = currentPage + 1;
	const prevPage = currentPage - 1;

	const disablePrev = currentPage === 0;
	const disableNext = nextPage === totalPages;
	const hasPagination = Object.keys(pagination).length;
	const hasFilters = filter && typeof setFilter === 'function';
	const disabledStyles = 'hover:text-grey-400 text-grey-400 no-underline';
	const [autoFocus, setAutoFocus] = useState(false);
	// eslint-disable-next-line no-shadow
	const onClick = ({ e, currentPage, disabled }) => {
		e.preventDefault();
		if (disabled) return;
		if (isClient) {
			const url = new URL(window.location);
			url.searchParams.set('p', currentPage + 1);
			// eslint-disable-next-line no-unused-expressions
			currentPage === 0
				? url.searchParams.delete('p')
				: url.searchParams.set('p', currentPage + 1);
			window.history.pushState({}, '', url);
		}

		setPagination({
			...pagination,
			currentPage,
		});
	};

	const [value, setValue] = useState('');

	const handleOnChange = (text) => {
		setValue(text);
		setAutoFocus(true);
		window.history.replaceState({ search: text }, '', '?p=1');
	};

	const [currentPageRef, setCurrentPageRef] = useState(
		pagination.currentPage
	);

	const doScroll = useCallback(() => {
		if (pagination.currentPage !== currentPageRef) {
			setTimeout(() => {
				window.scrollTo({
					top: containerRef?.current?.offsetTop - 190,
					left: 0,
					behavior: 'smooth',
				});
				setCurrentPageRef(pagination.currentPage);
			}, 500);
		}
	}, [pagination.currentPage, currentPageRef]);

	useLayoutEffect(() => {
		doScroll();
	}, [items, doScroll]);

	useEffect(() => {
		if (searchRef.current?.value === '') {
			setAutoFocus(false);
		}
	}, []);

	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: `course-card`,
			click_type: 'block',
			click_title: title,
			...trackData,
		});
	};

	const Wrapper = ({ className, children }) => {
		Wrapper.defaultProps = {
			className: '',
		};

		Wrapper.propTypes = {
			className: PropTypes.string,
			children: PropTypes.node.isRequired,
		};

		return (
			<section
				className={clsx(
					'px-3 mx-auto max-w-m-screen lg:px-8',
					className
				)}
				aria-labelledby={id}
			>
				{children}
			</section>
		);
	};

	const WrapperNonScrollable = ({ className, children }) => {
		WrapperNonScrollable.defaultProps = {
			className: '',
		};

		WrapperNonScrollable.propTypes = {
			className: PropTypes.string,
			children: PropTypes.node.isRequired,
		};

		return (
			<ul
				className={clsx(
					'w-full grid gap-x-10 gap-y-5 sm:gap-y-s-f grid-flow-row',
					'lg:grid-cols-2 pb-4 sm:pb-5 pt-5',
					className
				)}
			>
				{children}
			</ul>
		);
	};

	const renderCard = (card, index) => {
		const fullSlug = card.category?.slug
			? `${card.category?.slug}/${card.slug}`
			: card.slug;
		if (cardVariant === 'courseList') {
			return (
				<li
					key={`${card.id}-${card.title}${Math.random()}`}
					className="w-full pb-6 border-blue-200 border-b-1"
				>
					<div className="flex flex-col w-full h-full sm:items-center gap-y-4 gap-x-6 sm:flex-row">
						<div className="flex items-start w-full h-full lg:items-center">
							<div className="flex flex-col justify-between w-full h-full">
								<div>
									<h3 className="mb-2 text-xl font-bold">
										{card.title}
									</h3>
									{card.intro && <p>{card.intro}</p>}
								</div>
								{card.date || card.timeToWatchInMinutes ? (
									<ul className="flex flex-row gap-3 pt-3">
										{card.date ? (
											<li className="flex flex-row items-center">
												<img
													src={GreyBullet}
													className="mr-3"
													alt="Peninsla HR & Health and Safety Support"
												/>
												{format(
													new Date(card.date),
													'dd/MM/yyyy'
												)}
											</li>
										) : null}
										{card.timeToWatchInMinutes ? (
											<li className="flex flex-row items-center">
												<img
													src={GreyBullet}
													className="mr-3"
													alt="Peninsla HR & Health and Safety Support"
												/>
												{card.timeToWatchInMinutes} mins
											</li>
										) : null}
									</ul>
								) : null}
							</div>
						</div>
						<div className="flex gap-4">
							{!pbsWebinar && (
								<LikeButton likes={132} courseId={card.id} />
							)}
						</div>

						<div className="w-full my-4 sm:w-fit sm:my-0">
							<PBSLink
								size="Small"
								to={buildLink(
									locale,
									`${
										pbsWebinar
											? '/webinars/on-demand/'
											: '/elearning/'
									}${fullSlug}`
								)}
								className="justify-center w-full ml-auto sm:w-fit"
							>
								Watch
							</PBSLink>
						</div>
					</div>
				</li>
			);
		}
		return (
			<li key={`${card.id}-${card.title}${Math.random()}`}>
				<Card
					index={index}
					cardContent={card}
					onClickProp={handleClickTracking}
					location={location}
					loading={loading}
					locale={locale}
				/>
			</li>
		);
	};

	const renderFilterBar = () => (
		<div className="flex flex-col items-center justify-between lg:flex-row">
			<div className="flex flex-col order-2 mb-s-f sm:mb-0 sm:items-end lg:order-1">
				<Select
					id="sort"
					name="sort"
					aria-required="true"
					labelOption={false}
					labelWrapperClassName="sm:!mb-0 sm:self-center sm:mr-3"
					labelClassName="!text-sm"
					selectClassName="sm:w-72 !text-sm"
					className="w-full sm:flex sm:justify-end"
					value={filter}
					options={[
						{
							value: `title_ASC`,
							label: 'Sort by: Title',
						},
						{
							value: `_createdAt_DESC`,
							label: 'Sort by: Latest first',
						},
					]}
					onChange={(e) => {
						setFilter(e.target.value);
					}}
				/>
				{error && (
					<div className="mt-2 text-brand-red-400">{error}</div>
				)}
			</div>
			<div className="relative flex flex-col order-1 lg:order-2">
				<TextInput
					id="search"
					name="search"
					labelText="Search by keyword..."
					showLabel={false}
					aria-required="true"
					className="w-full wrapper-small [&>input]:pr-[107px] md:[&>input]:pr-[140px]"
					validationMessage="Please a valid keyword"
					variant="RoundedThick"
					ref={searchRef}
					placeholder="Search by keyword..."
					inputValue={value}
					inputOnChange={handleOnChange}
					autoFocus={autoFocus}
				/>
				<Button
					type="submit"
					className={clsx(
						'absolute bottom-[1.5rem] leading-[1.46rem] md:leading-[1.61rem] right-[-2px]'
					)}
				>
					<span>Search</span>
				</Button>
			</div>
		</div>
	);

	const renderPaginationBar = () => (
		<div className="flex flex-col sm:flex-row">
			<span className="flex flex-row items-end justify-start text-sm">
				{totalSearchCount} results
			</span>
			<div className="flex items-center gap-2 mx-auto mt-10 max-w-[380px] w-full md:w-1/2 lg:w-1/3 md:max-w-none">
				<div className="w-1/3">
					<PBSLink
						variant="Link"
						className={clsx(
							disablePrev && disabledStyles,
							'align-middle',
							'focus-visible:outline-none'
						)}
						onClick={(e) =>
							onClick({
								e,
								currentPage: prevPage,
								disabled: disablePrev,
							})
						}
					>
						<ArrowIcon
							viewBox="0 0 33 34"
							className="w-4 h-auto mr-3 pt-[1px]"
						/>
						Previous
					</PBSLink>
				</div>
				<div className="inline w-1/3 text-center text-base-f">
					<p>
						<span className="font-centra-medium">{nextPage} </span>
						of {totalPages}
					</p>
				</div>
				<div className="w-1/3 text-right">
					<PBSLink
						variant="Link"
						className={clsx(
							disableNext && disabledStyles,
							'align-middle',
							'focus-visible:outline-none'
						)}
						onClick={(e) => {
							onClick({
								e,
								currentPage: nextPage,
								disabled: disableNext,
							});
						}}
					>
						Next
						<ArrowIcon
							viewBox="0 0 33 34"
							className={clsx(
								'w-4 h-auto ml-3 pt-[1px] -scale-x-100'
							)}
						/>
					</PBSLink>
				</div>
			</div>
		</div>
	);
	return (
		<Wrapper className="mt-14 mb-2xl-f md:mb-20">
			<div className="flex flex-col justify-center lg:mx-auto">
				<div ref={containerRef}>
					<h2 className="mb-10 text-3xl font-bold">
						{webinarsOnly
							? 'Webinar Catch-Ups'
							: 'On demand sessions'}
					</h2>

					{!hasFilters && error ? (
						<div className="text-brand-red-400">{error}</div>
					) : null}

					{hasFilters ? renderFilterBar() : null}
				</div>

				{items.length > 0 ? (
					<>
						<WrapperNonScrollable>
							{items.map(renderCard)}
						</WrapperNonScrollable>
						{hasPagination ? renderPaginationBar() : null}
					</>
				) : (
					<div className="flex flex-col items-center justify-center m-auto h-72">
						<h3 className="text-xl font-bold">No search results</h3>
						<p>
							No results matched this search. Try editing your
							search or look for something else.
						</p>
					</div>
				)}
			</div>
		</Wrapper>
	);
};

export { CardContainer };
