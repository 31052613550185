import React from 'react';
import PropTypes from 'prop-types';
import { TextWithImage } from '../../atoms/text-with-image';
import { HeadingTagLine } from '../../atoms/heading-tag-line';

import {
	datoGatsbyImageProp,
	titleProp,
	structuredTextContent,
	refProp,
} from '../../../types';

const TextWithImageContainer = ({
	textWithImageContainerContent,
	showOnlyFirst,
	drawerRef,
}) => {
	const { id } = textWithImageContainerContent;
	let textWithImages = [textWithImageContainerContent];

	if (
		!Object.prototype.hasOwnProperty.call(
			textWithImageContainerContent,
			'content'
		)
	) {
		const {
			title,
			firstContent,
			firstImage,
			secondContent,
			secondImage,
			buttonCopy,
			buttonLink,
			showForm,
		} = textWithImageContainerContent;

		const elementOne = {
			titleProps: {
				id,
				title,
				headingClassNames:
					'text-left text-3xl md:!text-4xl md:!leading-normal lg:max-w-[470px]',
			},
			as: HeadingTagLine,
			content: firstContent,
			image: firstImage,
			className: 'items-center mb-8 lg:mb-2xl-f',
		};

		const elementTwo = {
			content: secondContent,
			image: secondImage,
			buttonLink,
			buttonCopy,
			showForm,
			drawerRef,
			className: 'items-center',
		};

		textWithImages = showOnlyFirst
			? [elementOne]
			: [elementOne, elementTwo];
	}

	return (
		<section
			className="px-4 mx-auto md:px-8 max-w-m-screen my-xl-f xl:my-2xl-f"
			aria-labelledby={id}
		>
			{textWithImages.map((elContent, index) => {
				let flipSecond = false;
				if (textWithImages.length > 1) {
					flipSecond = true;
				}
				return (
					<TextWithImage
						// eslint-disable-next-line react/no-array-index-key
						key={`${id}${index}`}
						textWithImageContent={elContent}
						{...(flipSecond && index === 1 ? { flip: true } : null)}
						drawerRef={drawerRef}
					/>
				);
			})}
		</section>
	);
};

TextWithImageContainer.defaultProps = {
	showOnlyFirst: false,
};
TextWithImageContainer.propTypes = {
	textWithImageContainerContent: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.oneOfType([titleProp, PropTypes.string]).isRequired,
		buttonCopy: PropTypes.string,
		buttonLink: PropTypes.string,
		showForm: PropTypes.bool,
		content: structuredTextContent,
		firstContent: structuredTextContent,
		secondContent: structuredTextContent,
		image: datoGatsbyImageProp,
		firstImage: datoGatsbyImageProp,
		secondImage: datoGatsbyImageProp,
	}).isRequired,
	showOnlyFirst: PropTypes.bool,
	drawerRef: refProp.isRequired,
};

export { TextWithImageContainer };
