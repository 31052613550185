import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { clsx } from 'clsx';
import { getLocale } from '../../../utils/locale';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

// ctaMode will return number only.
export const CallUs = ({
	className,
	altMobile,
	onClickProp,
	number,
	ctaMode = false,
	ctaLabel,
}) => {
	const data = useStaticQuery(graphql`
		query {
			datoCmsSiteSpecificSetting {
				phoneNumberCopy
			}
		}
	`);

	const [fallBackNumber, setFallBackNumber] = useState('');
	const defaultCtaLabel = 'Call at';

	useEffect(() => {
		if (!number) {
			if (locale === 'ca') {
				setFallBackNumber('1 (833) 247-3652');
			} else {
				setFallBackNumber('0800 158 2313');
			}
		}
	}, [number]);

	return ctaMode === true ? (
		<a
			href={`tel:${
				number?.replaceAll(' ', '') ||
				fallBackNumber?.replaceAll(' ', '')
			}`}
			onClick={() =>
				onClickProp({
					click_text: number,
				})
			}
			className="inline-flex items-center px-6 py-4 mb-2 mr-2 text-lg leading-6 text-white transition-colors bg-blue-400 border-2 border-blue-400 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-centra-medium focus:outline-0 focus:shadow-focus w-max"
		>
			{ctaLabel || defaultCtaLabel} {number || fallBackNumber}
		</a>
	) : (
		<div className="bg-[#EDF3FF] w-full pb-1 pt-2 sm:pb-6 sm:pt-7">
			<div className="mx-auto w-fit">
				<a
					href={`tel:${
						number?.replaceAll(' ', '') ||
						fallBackNumber?.replaceAll(' ', '')
					}`}
					onClick={() =>
						onClickProp({
							click_text: number,
						})
					}
					className={clsx(
						'text-3xl',
						'sm:text-xl',
						'lg:text-4xl',
						'phone-number',
						'font-centra-medium',
						'text-brand-red-400',
						locale !== 'ca' ? 'rulerclick' : '',
						'focus:outline-0 focus:shadow-focus',
						altMobile
							? 'inline-block leading-7 bg-white border-2 border-blue-400 rounded-lg pt-1 pb-2 px-4 lg:p-0 lg:bg-transparent lg:border-0 text-center lg:text-left'
							: 'flex flex-col leading-none',
						className
					)}
				>
					<span className={locale !== 'ca' ? 'rulerclick' : ''}>
						{number || fallBackNumber}
					</span>
					<span
						className={clsx(
							'text-lg text-black md:text-sm font-centra-book',
							altMobile ? 'flex items-center' : 'inline-block'
						)}
					>
						<span className="inline-block mb-1 ml-1 mr-2 bg-blue-400 blue-dot shrink-0" />
						{data.datoCmsSiteSpecificSetting.phoneNumberCopy}
					</span>
				</a>
			</div>
		</div>
	);
};

CallUs.defaultProps = {
	className: '',
	altMobile: false,
	onClickProp: () => {},
};

CallUs.propTypes = {
	className: PropTypes.string,
	altMobile: PropTypes.bool,
	onClickProp: PropTypes.func,
	number: PropTypes.string.isRequired,
	ctaMode: PropTypes.bool.isRequired,
	ctaLabel: PropTypes.string.isRequired,
};
