import React from 'react';
import { PBSLink } from '../../../atoms/link';

function PayrollFooter() {
	return (
		<div className="flex flex-col gap-1 mt-5">
			<div className="inline-flex items-center text-base gap-1 ">
				View our privacy notice
				<PBSLink
					to="/legal/privacy-policy"
					variant="Link"
					className="!text-base underline"
				>
					here
				</PBSLink>
			</div>
			<div className="inline-flex items-center text-base gap-1  ">
				You can manage your preferences any time
				<PBSLink
					to="/legal/cookies-policy"
					variant="Link"
					className="!text-base underline"
				>
					here
				</PBSLink>
			</div>
		</div>
	);
}

export default PayrollFooter;
