import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import award1 from '../../../assets/ppc-review-1.png';
import award2 from '../../../assets/ppc-review-2.png';
import award3 from '../../../assets/ppc-review-3.png';

const TrustpilotBusinessReview = () => (
	<div className="flex flex-col justify-center max-w-[235px] text-center shrink-0 w-full" />
);

const TrustpilotBusinessReviewHorizontal = ({ className }) => (
	<div
		className={clsx(
			'flex',
			'flex-wrap',
			'items-center',
			'w-full',
			'mt-8',
			className
		)}
	>
		<div className="flex flex-col mx-auto md:mx-0 gap-8 lg:gap-20 lg:flex-row">
			<div className="flex flex-wrap items-center gap-3 lg:gap-6 shrink-0">
				<img
					src={award1}
					alt="CANADIAN SME NATIONAL BUSINESS AWARDS 2023."
					className="w-[90px] md:w-[150px]"
				/>
				<img
					src={award2}
					alt="FRANCHISE CANADA."
					className="w-[90px] md:w-[150px]"
				/>
				<img
					src={award3}
					alt="GOOGLE REVIEW."
					className="w-[108px] md:w-[180px]"
				/>
			</div>
		</div>
	</div>
);

TrustpilotBusinessReviewHorizontal.defaultProps = {
	className: '',
};

TrustpilotBusinessReviewHorizontal.propTypes = {
	className: PropTypes.string,
};

export { TrustpilotBusinessReview, TrustpilotBusinessReviewHorizontal };
