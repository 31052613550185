// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useLocation } from '@reach/router';
import { DefaultLayout } from '../layouts/default';
import PageSection from '../components/glu/organisms/page-section';
import SessionCardContainer from '../components/glu/organisms/session-card-container';
import SessionCardContainerCA from '../components/glu/organisms/session-card-container-ca';
import { CardContainer } from '../components/glu/organisms/course-container';
import { Head } from '../components/templates/head';
import {
	getOrganizationSeoSchema,
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { getBreadcrumLocale, pageTypes } from '../utils/url-helpers';
import { Testimonials } from '../components/organisms/testimonials';
import { getLocale } from '../utils/locale';
import { HrefLang } from '../components/templates/hrefLang';
import { Drawer } from '../components/atoms/drawer';
import { useDrawer } from '../contexts/drawer-context';
import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessForm } from '../components/organisms/success-form';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';

const WebinarLibrary = ({ data, location }) => {
	const { drawerRef } = useDrawer();
	const [reference, setReference] = React.useState('');
	const [response, setResponse] = React.useState('');
	const { liveWebinarSessions, title } = data.datoCmsWebinarsPage;
	const courseData = data.allDatoCmsOnDemandWebinar;
	const [updatedWebinars, setUpdatedWebinars] = useState(liveWebinarSessions);
	const metaContent = {
		noIndex: data.datoCmsWebinarsPage.noIndex,
		noFollow: data.datoCmsWebinarsPage.noFollow,
		metaInformation: data.datoCmsWebinarsPage.seoMeta,
		fallbackTitle: data.datoCmsWebinarsPage.eLearningTitle,
		fallbackDescription: '',
		canonicalUrl: data.datoCmsWebinarsPage?.canonicalUrl || location?.href,
	};
	const adviceFormRef = useRef(null);
	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.webinar,
			data: data.datoCmsWebinarsPage,
			location,
		}),
		image: getImageSeoSchema(data?.headingImage?.url),
	};
	const testimonialsContent = {
		...data.datoCmsWebinarsPage.testimonials,
		title: data.datoCmsWebinarsPage.testimonials.title.value.document
			.children,
		slides: data.datoCmsWebinarsPage.testimonials.slides.map((slide) => ({
			...slide,
			tags: slide.tags.split(','),
		})),
	};
	const locationUrl = useLocation();
	const locale = getLocale(locationUrl.pathname);

	const updateHighlightedWebinar = (webinars) => {
		const updatedWebinarsObject = { ...webinars };
		const { highlightedLiveWebinar, upcomingLiveWebinars } =
			updatedWebinarsObject;

		const currentTime = new Date();
		const highlightedWebinarTime = new Date(
			highlightedLiveWebinar.dateAndTime
		);
		const timeDifferenceInMinutes =
			(currentTime - highlightedWebinarTime) / (1000 * 60);

		if (timeDifferenceInMinutes > 60) {
			const [firstWebinar, ...remainingWebinars] = upcomingLiveWebinars;

			updatedWebinarsObject.highlightedLiveWebinar = firstWebinar;
			updatedWebinarsObject.upcomingLiveWebinars = remainingWebinars;
		}

		return updatedWebinarsObject;
	};

	// Update webinars on page load
	useEffect(() => {
		const updatedWebinarsData =
			updateHighlightedWebinar(liveWebinarSessions);
		setUpdatedWebinars(updatedWebinarsData);
	}, [liveWebinarSessions]);

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsWebinarsPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<PageSection theme="White">
				<h1 className="mb-10 text-3xl lg:text-5xl">{title}</h1>
				{liveWebinarSessions.upcomingLiveWebinars.length > 0 &&
				locale !== 'ca' ? (
					<SessionCardContainer
						liveWebinarSessions={liveWebinarSessions}
						pbsWebinar
						locale={locale}
					/>
				) : (
					<SessionCardContainerCA
						liveWebinarSessions={updatedWebinars}
						pbsWebinar
						locale={locale}
					/>
				)}
				{courseData.totalCount > 0 ? (
					<CardContainer
						cardContainerContent={{
							id: 'course-container',
						}}
						cardVariant="courseList"
						data={courseData}
						webinarsOnly
						pbsWebinar
						locale={locale}
						datoQueryName="allOnDemandWebinars"
					/>
				) : null}
				<div className="pt-5">
					<Testimonials
						drawerRef={adviceFormRef}
						testimonialsContent={testimonialsContent}
					/>
				</div>
			</PageSection>

			{locale !== 'ca' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedWebinarLibrary = ({ data, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<WebinarLibrary data={data} location={location} />
	</DefaultLayout>
);

export default WrappedWebinarLibrary;

export const query = graphql`
	query webinarLibraryPage($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsWebinarsPage(locale: { eq: $locale }) {
			noIndex
			allSeoLocales: _allSeoMetaLocales {
				locale
			}
			noFollow
			title
			seoMeta {
				title
				description
				image {
					url
				}
				twitterCard
			}
			liveWebinarSessions {
				upcomingWebinarsCopy {
					value
				}
				highlightedLiveWebinar {
					title
					dateAndTime
					timeEnd
					timeZone
					slug
					shortenedContent {
						value
					}
				}
				image {
					gatsbyImageData(placeholder: DOMINANT_COLOR)
					alt
				}
				upcomingLiveWebinars {
					title
					dateAndTime
					timeEnd
					timeZone
					slug
					shortenedContent {
						value
					}
				}
			}
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
		}
		allDatoCmsOnDemandWebinar(
			limit: 1
			filter: {
				locale: { eq: $locale }
				hideInOnDemandLibrary: { ne: true }
			}
		) {
			totalCount
		}
	}
`;
