import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { CallUs } from '../../atoms/call-us';
import { SidebarHeader } from '../../atoms/sidebar-header';

import { useMarketoFormSubmit } from '../../../hooks/marketo';
import {
	filterObject,
	getCookie,
	sendTrackingData,
	sleep,
} from '../../../utils';

import { EventFormFields } from './form';

import { validateEmail, validateString } from '../../../utils/marketo';

import { eventFormStepOneErrors, eventFormStepOneValues } from '../../../types';
import { LOCALE_PATHS } from '../../../utils/locale';

// These should appear in order displayed on FE
const firstStepsKeys = [
	'FirstName',
	'LastName',
	'Company',
	'Email',
	'additionalCommentsSeminars',
];

const FormikEventForm = (props) => {
	const {
		values,
		errors,
		handleSubmit,
		handleChange,
		validateForm,
		technicalError,
		eventFormClickTracking,
		pageUrl,
		locale,
		isSubmitting,
		MktoForms2Instances,
	} = props;
	const urlLocale = LOCALE_PATHS[locale];
	const containerRef = useRef();
	const title = 'Book a place at this event';
	const subText =
		'Our HR and health & safety seminars are complimentary and include a free consultation.';
	useEffect(() => {
		if (urlLocale === 'ie') {
			firstStepsKeys.unshift('CAN');
			firstStepsKeys.unshift('isClient');
		} else if (urlLocale === 'en') {
			firstStepsKeys.unshift('Phone');
		}
	}, [urlLocale]);
	useEffect(() => {
		values.MktoForms2Instances = MktoForms2Instances;
		values.referalSource = getCookie('utm_source');
		values.keyword = getCookie('utm_term');
		values.utm_medium = getCookie('utm_medium');
		values.utm_campaign = getCookie('utm_campaign');
		values.MSCLKID__c = getCookie('msclkid');
		values.MSCLKID_last = getCookie('msclkid_last');
		values.GCLID__c = getCookie('gclid');
		values.gCLID_last = getCookie('gclid_last');
		values.utm_campaign_last__c = getCookie('utm_campaign_last');
		values.utm_content_last__c = getCookie('utm_content_last');
		values.utm_medium_last__c = getCookie('utm_medium_last');
		values.utm_source_last__c = getCookie('utm_source_last');
		values.utm_term_last__c = getCookie('utm_term_last');
		values.Google_Cookie_ID__c = getCookie('Google_Cookie_ID__c');
	}, [MktoForms2Instances, values]);

	useEffect(() => {
		// eslint-disable-next-line compat/compat
		const urlObj = new URL(pageUrl);
		values.socialCampaignID = urlObj.searchParams.get('social_id');
		values.inviteSource = urlObj.searchParams.get('utm_content');
	}, [pageUrl, values]);
	return technicalError ? (
		<>
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</>
	) : (
		<form
			data-forminstance="one"
			className="flex flex-col justify-between w-full mx-auto grow"
			onSubmit={handleSubmit}
			ref={containerRef}
		>
			<SidebarHeader heading={title} text={subText} />

			<div className="flex flex-col grow">
				<EventFormFields
					values={values}
					errors={errors}
					locale={urlLocale}
					handleChange={handleChange}
					validateForm={validateForm}
					firstStepsKeys={firstStepsKeys}
					handleClickTracking={eventFormClickTracking}
					isSubmitting={isSubmitting}
				/>
			</div>
		</form>
	);
};

const EventForm = withFormik({
	mapPropsToValues: (props) => ({
		FirstName: '',
		LastName: '',
		Email: '',
		Phone: '',
		Company: '',
		isClient: '',
		CAN: '',
		additionalCommentsSeminars: '',
		PersonSource:
			LOCALE_PATHS[props.locale] === 'ie'
				? 'Website - ROI Confirm Seminar Web Lead'
				: 'Website - Confirm Seminar Web Lead',
		LeadSource:
			LOCALE_PATHS[props.locale] === 'ie'
				? 'Website - ROI Confirm Seminar Web Lead'
				: 'Website - Confirm Seminar Web Lead',
		formId: props.formId,
		MktoForms2Instances: props.MktoForms2Instances,
		technicalError: props.technicalError,
		setTechnicalError: props.setTechnicalError,
		eventFormClickTracking: props.eventFormClickTracking,
		Promotion_Code__c: props.promoCode,
		setEventResponse: props.setEventResponse,
		pageUrl: props.pageUrl,
		socialCampaignID: '',
		inviteSource: '',
		referalSource: '',
		keyword: '',
		utm_medium: '',
		utm_campaign: '',
		GCLID__c: '',
		gCLID_last: '',
		utm_campaign_last__c: '',
		utm_content_last__c: '',
		utm_medium_last__c: '',
		utm_source_last__c: '',
		utm_term_last__c: '',
		Google_Cookie_ID__c: '',
	}),
	validate: (values) => {
		let errors = {};

		const firstSteps = filterObject(firstStepsKeys, values);

		Object.keys(firstSteps).forEach((key) => {
			if (key === 'Email') {
				errors.Email = validateEmail(firstSteps[key]);
			}
			if (key === 'isClient' || key === 'Phone') {
				errors[key] =
					firstSteps[key] !== 'yes' && firstSteps[key] !== 'no';
			}
			if (key === 'CAN' || values.isClient === 'no') {
				errors.CAN = false;
			}
			if (key === 'additionalCommentsSeminars') {
				errors[key] = false;
			} else {
				errors[key] = validateString(firstSteps[key]);
			}
		});

		const firstStepsErrors = filterObject(firstStepsKeys, errors);
		if (Object.values(firstStepsErrors).every((value) => value === false)) {
			errors = {};
		}
		return errors;
	},
	handleSubmit: async (values, { setSubmitting, setStatus }) => {
		const {
			formId,
			MktoForms2Instances,
			setEventResponse,
			technicalError,
			setTechnicalError,
			eventFormClickTracking,
			pageUrl,
			...data
		} = values;
		if (values.isClient === 'yes') {
			data.Client_Account_Number__c = data.CAN;
			data.areyouaPeninsulaclient =
				values.isClient === 'yes' ? 'Yes' : 'No';
		} else {
			data.areyouaPeninsulaclient = 'No';
		}
		await sleep(500);

		eventFormClickTracking({
			click_title: 'Book a place at this event',
			click_text: 'Book my place',
		});

		useMarketoFormSubmit(data, formId, MktoForms2Instances)
			.then(() => {
				setStatus('submitted');
				setEventResponse(true);
			})
			.catch(() => {
				setStatus('error');
				setTechnicalError(true);
			})
			.finally(() => {
				const gtmStartedEvent = window.dataLayer?.find(
					(element) => element['gtm.start']
				);
				if (!gtmStartedEvent) return;

				sendTrackingData('arrange_a_callback', {
					form_name: 'Book a place at this event',
				});
			});
		setSubmitting(false);
	},
	validateOnChange: false,
	validateOnBlur: false,
	displayName: 'EventForm',
})(FormikEventForm);

FormikEventForm.defaultProps = {
	values: {},
	errors: {},
	handleChange: () => {},
	handleSubmit: () => {},
	validateForm: () => {},
	technicalError: false,
	eventFormClickTracking: () => {},
	isSubmitting: false,
};

FormikEventForm.propTypes = {
	values: PropTypes.shape({
		eventFormStepOneValues,
		Promotion_Code__c: PropTypes.string.isRequired,
		Invite_Source__c: PropTypes.string,
		MSCLKID__c: PropTypes.string,
		MSCLKID_last: PropTypes.string,
		GCLID__c: PropTypes.string,
		gCLID_last: PropTypes.string,
		Google_Cookie_ID__c: PropTypes.string,
		MktoForms2Instances: PropTypes.instanceOf(Map),
		PersonSource: PropTypes.string,
		dietaryRequirements: PropTypes.string,
		doyourequireasyaccess: PropTypes.string,
		eventFormClickTracking: PropTypes.func,
		formId: PropTypes.number,
		keyword: PropTypes.string,
		referalSource: PropTypes.string,
		setEventResponse: PropTypes.func,
		setTechnicalError: PropTypes.func,
		socialCampaignID: PropTypes.string,
		inviteSource: PropTypes.string,
		technicalError: PropTypes.bool,
		utm_campaign: PropTypes.string,
		utm_campaign_last__c: PropTypes.string,
		utm_content_last__c: PropTypes.string,
		utm_medium: PropTypes.string,
		utm_medium_last__c: PropTypes.string,
		utm_source_last__c: PropTypes.string,
		utm_term_last__c: PropTypes.string,
	}),
	errors: PropTypes.shape({
		eventFormStepOneErrors,
	}),
	handleChange: PropTypes.func,
	handleSubmit: PropTypes.func,
	validateForm: PropTypes.func,
	technicalError: PropTypes.bool,
	eventFormClickTracking: PropTypes.func,
	pageUrl: PropTypes.string.isRequired,
	locale: PropTypes.string.isRequired,
	isSubmitting: PropTypes.bool,
	MktoForms2Instances: PropTypes.instanceOf(Map).isRequired,
};

export { EventForm };
