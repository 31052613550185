import React from 'react';
import PropTypes from 'prop-types';
import { shareContent } from '../../../../utils/share-content';

function SocialShareButton({ text, hasIcon }) {
	const hasShareContentSetup =
		typeof window !== 'undefined' ? !!navigator.share : '';
	const shareSocialLink =
		typeof window !== 'undefined' ? window.location : '';
	const content = {
		title: `Peninsula Elearning UK`,
		text: `Check out this awesome content.`,
		url: shareSocialLink,
	};
	const browserAllowsShare = hasShareContentSetup;
	return (
		<div className="flex flex-row space-x-3 mt-5">
			<div className="flex items-center gap-5 cursor-pointer">
				<div className="w-[48px] h-[48px] bg-[#3954E0] rounded-full items-center justify-center flex text-white">
					<svg
						width="28"
						height="28"
						viewBox="0 0 25 25"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M19.3398 14.8064C17.5838 14.8064 16.0368 15.7174 15.1448 17.0904L11.7518 15.3944C12.1278 14.6104 12.3398 13.7324 12.3398 12.8064C12.3398 11.8804 12.1288 11.0024 11.7518 10.2184L15.1448 8.5224C16.0368 9.8954 17.5838 10.8064 19.3398 10.8064C22.0968 10.8064 24.3398 8.5634 24.3398 5.8064C24.3398 3.0494 22.0968 0.806396 19.3398 0.806396C16.5828 0.806396 14.3398 3.0494 14.3398 5.8064C14.3398 6.0944 14.3658 6.3764 14.4128 6.6514L10.5818 8.5664C9.49584 7.4794 7.99484 6.8054 6.33984 6.8054C3.03084 6.8054 0.339844 9.4964 0.339844 12.8054C0.339844 16.1144 3.03084 18.8054 6.33984 18.8054C7.99484 18.8054 9.49584 18.1314 10.5818 17.0444L14.4128 18.9594C14.3658 19.2344 14.3398 19.5164 14.3398 19.8054C14.3398 22.5624 16.5828 24.8054 19.3398 24.8054C22.0968 24.8054 24.3398 22.5624 24.3398 19.8054C24.3398 17.0484 22.0968 14.8054 19.3398 14.8054V14.8064ZM19.3398 2.8064C20.9938 2.8064 22.3398 4.1524 22.3398 5.8064C22.3398 7.4604 20.9938 8.8064 19.3398 8.8064C17.6858 8.8064 16.3398 7.4604 16.3398 5.8064C16.3398 4.1524 17.6858 2.8064 19.3398 2.8064ZM6.33984 16.8064C4.13384 16.8064 2.33984 15.0124 2.33984 12.8064C2.33984 10.6004 4.13384 8.8064 6.33984 8.8064C8.54584 8.8064 10.3398 10.6004 10.3398 12.8064C10.3398 15.0124 8.54584 16.8064 6.33984 16.8064ZM19.3398 22.8064C17.6858 22.8064 16.3398 21.4604 16.3398 19.8064C16.3398 18.1524 17.6858 16.8064 19.3398 16.8064C20.9938 16.8064 22.3398 18.1524 22.3398 19.8064C22.3398 21.4604 20.9938 22.8064 19.3398 22.8064Z"
							fill="white"
						/>
					</svg>
				</div>
				{browserAllowsShare && (
					<button
						type="button"
						onClick={() =>
							shareContent(hasShareContentSetup, content)
						}
						className="inline-flex items-center text-[15px] font-extrabold gap-5"
					>
						{text}
						{hasIcon && (
							<svg
								width="18"
								height="18"
								viewBox="0 0 12 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M5.33027 1.20077L5.625 1.41585L5.91973 1.20077C6.30971 0.916192 7.03881 0.5 7.96875 0.5C9.50344 0.5 10.7505 1.74713 10.7505 3.28125C10.7505 4.95552 9.89754 6.79522 8.18197 8.59033C6.99259 9.83226 5.81305 10.6183 5.62523 10.7414C5.43734 10.6183 4.25724 9.83214 3.06822 8.59003C1.35284 6.79502 0.5 4.95543 0.5 3.28125C0.5 1.74708 1.74708 0.5 3.28125 0.5C4.21119 0.5 4.94029 0.916192 5.33027 1.20077Z"
									fill="transparent"
									stroke="currentColor"
									className="fill-black"
								/>
							</svg>
						)}
					</button>
				)}
			</div>
			{!browserAllowsShare && (
				<a
					href={`mailto:?subject=${content.text} - ${content.title}&body=${shareSocialLink}`}
					target="_blank"
					className="inline-flex items-center text-[15px] font-extrabold gap-5"
					rel="noreferrer"
				>
					{text}
					{hasIcon && (
						<svg
							width="18"
							height="18"
							viewBox="0 0 12 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M5.33027 1.20077L5.625 1.41585L5.91973 1.20077C6.30971 0.916192 7.03881 0.5 7.96875 0.5C9.50344 0.5 10.7505 1.74713 10.7505 3.28125C10.7505 4.95552 9.89754 6.79522 8.18197 8.59033C6.99259 9.83226 5.81305 10.6183 5.62523 10.7414C5.43734 10.6183 4.25724 9.83214 3.06822 8.59003C1.35284 6.79502 0.5 4.95543 0.5 3.28125C0.5 1.74708 1.74708 0.5 3.28125 0.5C4.21119 0.5 4.94029 0.916192 5.33027 1.20077Z"
								fill="transparent"
								stroke="currentColor"
								className="fill-black"
							/>
						</svg>
					)}
				</a>
			)}
		</div>
	);
}

export { SocialShareButton };

SocialShareButton.defaultProps = {
	text: '',
	hasIcon: true,
};

SocialShareButton.propTypes = {
	text: PropTypes.string,
	hasIcon: PropTypes.bool,
};
