import React from 'react';

const usePeninsulaNow = () => {
	const [isOnline, setIsOnline] = React.useState(false);
	const [modalOnline, setModalOnline] = React.useState(false);
	const [log, setLog] = React.useState(null);
	const GISAPISAMPLES = React.useMemo(() => GISAPISAMPLES || {}, []);
	const routingKeyObject = React.useMemo(
		() => ({ required: [['BSC', 'SS']], optional: [] }),
		[]
	);

	const clerkAvailableHandler = () => {
		if (
			typeof window !== 'undefined' &&
			typeof window.gisApi !== 'undefined'
		) {
			setLog(
				'Emplifi: Current system supported. Checking clerk availability.'
			);
			setIsOnline(true);
			setModalOnline(true);
			window.gisApi.logEvent(window.gisApi.Events.CALL_CTA_SHOWN);
			setLog('Emplifi: Clerk available');
		}
	};

	const clerkUnavailableHandler = () => {
		setLog('Emplifi: No Clerk unavailable');
		setIsOnline(false);
		setModalOnline(false);
	};

	const checkClerkAvailability = React.useCallback(() => {
		if (
			typeof window !== 'undefined' &&
			typeof window.gisApi !== 'undefined'
		) {
			if (window.gisApi) {
				window.gisApi.checkClerkAvailability(
					clerkAvailableHandler,
					clerkUnavailableHandler,
					JSON.stringify(routingKeyObject)
				);
			} else {
				setLog('Empifi: Current system not supported.');
				setIsOnline(false);
				setModalOnline(false);
			}
		}
	}, [routingKeyObject]);
	// console.log('routingKeyObject: ', routingKeyObject);
	GISAPISAMPLES.BasicCallSample = (function () {
		function init() {
			setLog('Emplify: initializing...');
			if (
				typeof window.gisApi !== 'undefined' &&
				typeof window !== 'undefined'
			) {
				if (window.gisApi) {
					setLog('Emplify: getting gisApi starting...');
					checkClerkAvailability();
				} else {
					setLog('Incompatible version of Emplifi API detected!');
					setIsOnline(false);
					setModalOnline(false);
				}
			} else {
				setIsOnline(false);
				setModalOnline(false);
				setLog('Emplifi: gisApi or windows not enabled.');
			}
		}
		// eslint-disable-next-line no-unused-vars
		function callEndedHandler(_data) {
			setLog('Emplifi: Call ended.');
			setTimeout(checkClerkAvailability, 5000);
		}

		function onConnectClick() {
			setLog('Emplifi: making call to clerk.');
			window.gisApi?.logEvent(window.gisApi.Events.CALL_CTA_CLICK);
			window.gisApi?.startCall(
				callEndedHandler,
				JSON.stringify(routingKeyObject)
			);
		}
		return {
			init,
			onConnectClick,
		};
	})();
	const startEmpilifi = React.useCallback(() => {
		if (typeof window !== 'undefined') {
			const onGisApiReady = function () {
				GISAPISAMPLES.BasicCallSample.init();
			};
			window.onGisApiReady = onGisApiReady();
		}
	}, [GISAPISAMPLES.BasicCallSample]);
	React.useEffect(() => {
		let timer;
		const interval = setInterval(() => {
			startEmpilifi();
			// swap the status every 5 seconds
			timer = setTimeout(() => {
				if (modalOnline) {
					setIsOnline((prev) => !prev);
				}
			}, 5000);
		}, 5000);
		return () => {
			clearInterval(interval);
			clearTimeout(timer);
		};
	}, [isOnline, modalOnline, startEmpilifi]);

	return {
		isOnline,
		modalOnline,
		log,
		GISAPISAMPLES,
		setLog,
		checkClerkAvailability,
	};
};

export default usePeninsulaNow;
