import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { StaticImage } from 'gatsby-plugin-image';
import { PBSLink } from '../../atoms/link';
import { getLocale } from '../../../utils/locale';

const Footer = ({ footerContent }) => {
	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);

	const footerLogos = [
		{
			src: '/images/cyberessentials-certification.png',
			locale: ['en', 'ie'],
			alt: 'Cyber Essential Logo',
			width: '67',
			height: '80',
		},
		{
			src: '/images/bafe-logo.png',
			locale: ['en'],
			alt: 'BAFE SP205 Registration',
			width: '85',
			height: '80',
		},
		{
			src: '/images/iso-logo.png',
			locale: ['en', 'ie'],
			alt: 'ISO 27001 and 9001 accredited company.',
			width: '175',
			height: '80',
		},
		{
			src: '/images/top-track.png',
			locale: ['en', 'ie'],
			alt: 'The Sunday Times - Top Track 250.',
			width: '64',
			height: '80',
		},
		{
			src: '/images/glassdoor.png',
			locale: ['en', 'ie'],
			alt: 'Glassdoor 2018 Best Places To Work.',
			width: '53',
			height: '80',
		},
	];

	const [logoArray, setLogoArray] = useState([footerLogos]);
	useEffect(() => {
		setLogoArray(
			footerLogos.filter((logo) => logo.locale.includes(locale))
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale]);

	return (
		<footer className="font-centra-light">
			<div className="p-5 text-sm text-white md:p-10 bg-brand-blue-400">
				<div className="mx-auto lg:container">
					<div className="flex flex-col gap-8 lg:gap-20 lg:flex-row">
						<div className="flex flex-wrap">
							<h2 className="mr-4 font-centra-medium">
								{footerContent.footerInternationalSitesCopy}
							</h2>
							<ul className="flex flex-wrap w-full mt-4 2xl:mt-0 2xl:w-auto">
								{footerContent.footerInternationalSites.map(
									(site) => (
										<li
											key={`footer-int-sites-${site.link}`}
											className="mb-4 mr-4"
										>
											<PBSLink
												to={site.link}
												variant="Unset"
											>
												{site.title}
											</PBSLink>
										</li>
									)
								)}
							</ul>
							<p className="w-full mt-4 leading-6">
								{footerContent.footerCopyright}
							</p>
						</div>
						<div className="flex flex-wrap items-center gap-4 shrink-0">
							{logoArray.map((logo) => (
								<img
									key={logo.src}
									src={logo.src}
									alt={logo.alt}
									width={`${logo.width}px`}
									height={`${logo.height}px`}
								/>
							))}

							{locale === 'ca' ? (
								<>
									<StaticImage
										src="../../../assets/ppc-awards-footer.png"
										alt="Canadian Awards"
										width={550}
									/>
								</>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

Footer.propTypes = {
	footerContent: PropTypes.shape({
		footerNavigation: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				treeChildren: PropTypes.arrayOf(
					PropTypes.shape({
						link: PropTypes.string.isRequired,
						title: PropTypes.string.isRequired,
					})
				).isRequired,
			})
		).isRequired,
		footerAddressTitle: PropTypes.string.isRequired,
		footerAddress: PropTypes.string.isRequired,
		footerSocialTitle: PropTypes.string.isRequired,
		footerInternationalSitesCopy: PropTypes.string.isRequired,
		footerInternationalSites: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				link: PropTypes.string.isRequired,
			})
		).isRequired,
		footerCopyright: PropTypes.string.isRequired,
	}).isRequired,
};

export { Footer };
