/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';

import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { IconBanner } from '../components/atoms/icon-banner';
import { Title } from '../components/atoms/title';
import { Drawer } from '../components/atoms/drawer';
import { TrustpilotBusinessReviewHorizontal } from '../components/atoms/trustpilot-business-review';
import { CtaBanner } from '../components/atoms/cta-banner';
import { TextWithImageContainer } from '../components/molecules/text-with-image-container';

import { FeefoTestimonials } from '../components/molecules/feefo';
import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';

import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { PostcodeLookupForm } from '../components/organisms/postcode-lookup-form';
import { SuccessForm } from '../components/organisms/success-form';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { AdviceBanner } from '../components/organisms/advice-banner';
// import { CardContainer } from '../components/organisms/card-container';

import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { useDrawer } from '../contexts/drawer-context';
import { useFilters } from '../hooks/events-filters';
import { TextWithVideo } from '../components/atoms/text-with-video';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';
import { EventCardContainer } from '../components/organisms/event-card-container';
import { HrefLang } from '../components/templates/hrefLang';

const ViewAllEventsPage = ({ data, location }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');
	const [hasLocationSet, setHasLocationSet] = useState(false);
	const distances = [0.25, 0.5, 1, 3, 5, 15, 20, 30, 40];

	const {
		loading,
		error,
		eventCards,
		setCurrentLocation,
		setMaxDistance,
		setOrderBy,
	} = useFilters({
		venuesData: data.allDatoCmsEventVenue.nodes,
		distances,
	});

	const handleLocationSearch = (loc) => {
		setHasLocationSet(!!loc);
		setCurrentLocation(loc);
	};
	const {
		noIndex,
		noFollow,
		metaInformation,
		canonicalUrl,
		adviceBanner,
		heading,
		subHeading,
		textWithVideo,
		ctaBanner,
		ctaBannerDisplayOnPage,
		textWithVideoDisplayOnPage,
		textWithImageContainerDisplayOnPage,
	} = data.datoCmsAllEventsPage;

	const metaContent = {
		noIndex,
		noFollow,
		metaInformation,
		fallbackTitle: heading,
		fallbackDescription: subHeading,
		canonicalUrl: canonicalUrl || location.href,
	};

	const textWithVideoContent = {
		...textWithVideo,
		title: textWithVideo.title.value.document.children,
	};

	const adviceBannerContent = {
		...adviceBanner,
		title: adviceBanner.title.value.document.children,
	};

	const eventContainerContent = {
		totalCount: eventCards?.length || 0,
		cards: eventCards,
	};
	const textWithImageContainer = {
		...data.datoCmsAllEventsPage.textWithImageContainer,
		title: data.datoCmsAllEventsPage.textWithImageContainer.title.value
			.document.children,
		firstContent:
			data.datoCmsAllEventsPage.textWithImageContainer.firstContent,
		secondContent:
			data.datoCmsAllEventsPage.textWithImageContainer.secondContent,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.eventsHub,
			location,
			data: data.datoCmsAllEventsPage,
		}),
	};

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsAllEventsPage.internal.type,
		data.datoCmsAllEventsPage
	);
	const { locale: urlLocale } = data.datoCmsAllEventsPage;
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsAllEventsPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />

			<BreadCrumbs breadCrumbItems={breadCrumbItems} />

			<Title
				title={heading}
				subtitle={subHeading}
				className="px-3 mx-auto max-w-m-screen lg:px-8"
			/>
			<PostcodeLookupForm
				setCurrentLocation={(loc) => handleLocationSearch(loc)}
				setHasLocation={setHasLocationSet}
			/>

			<TrustpilotBusinessReviewHorizontal
				className="justify-center !mt-0 mb-10 px-3"
				textClassName="text-center"
				locale={urlLocale}
			/>

			<IconBanner data={data.datoCmsAllEventsPage.iconBanner} />

			{textWithVideoDisplayOnPage && (
				<TextWithVideo
					className="mx-auto mb-14 lg:mb-20 max-w-m-screen md:px-3 lg:px-8"
					textWithVideoContent={textWithVideoContent}
				/>
			)}
			{eventCards ? (
				<EventCardContainer
					variant="ViewAllFilters"
					cardContainerContent={{
						...eventContainerContent,
						id: 'events-container',
						title: 'Events',
						to: '/events',
					}}
					hasLocationSet={hasLocationSet}
					loading={loading}
					error={error}
					filters={{
						distances: [
							{
								label: 'Distance',
								value: '',
							},
							...distances.map((item) => ({
								label: `${item} mile${item !== 1 ? 's' : ''}`,
								value: String(item),
							})),
						],
						orderBy: [
							{
								label: 'Sort by date (closest)',
								value: 'date',
							},
							{
								label: 'Sort by distance (closest)',
								value: 'distance',
							},
						],
					}}
					setMaxDistance={setMaxDistance}
					setOrderBy={setOrderBy}
				/>
			) : null}

			<FeefoTestimonials locale={urlLocale} />

			{ctaBannerDisplayOnPage ? (
				<section
					aria-labelledby={ctaBanner.id}
					className="px-3 mx-auto max-w-[1084px]"
				>
					<CtaBanner
						drawerRef={adviceFormRef}
						className="!px-7 lg:!gap-10"
						headingClassNames="!text-3xl md:!text-4xl md:!leading-snug"
						{...ctaBanner}
					/>
				</section>
			) : null}

			{textWithImageContainerDisplayOnPage && (
				<TextWithImageContainer
					textWithImageContainerContent={textWithImageContainer}
				/>
			)}

			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<AdviceBanner
				drawerRef={adviceFormRef}
				adviceBannerContent={adviceBannerContent}
			/>

			<TrustpilotReviews
				locale={urlLocale}
				className="pt-12 !pb-0"
				wrapperClassName="pb-12 border-b-1 border-b-blue-200"
			/>

			{urlLocale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedAllEventsPage = ({ data, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<ViewAllEventsPage data={data} location={location} />
	</DefaultLayout>
);

export default WrappedAllEventsPage;

export const query = graphql`
	query allEvents($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsAllEventsPage(locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			id
			internal {
				type
			}
			noIndex
			noFollow
			heading
			subHeading
			iconBanner {
				id
				heading
				subHeading
				icon
			}
			textWithVideo {
				id
				title {
					value
				}
				content {
					value
				}
				tickList {
					id
					text
					subText
				}
				vimeoVideo {
					url
				}
			}
			ctaBanner {
				id: originalId
				title: ctaBannerTitle
				content
				linkCopy
				link
				ctaOpenForm
				image {
					alt
					url
					gatsbyImageData(
						width: 415
						height: 315
						layout: CONSTRAINED
					)
				}
			}
			textWithImageContainer {
				id
				title {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				buttonCopy
				buttonLink
			}
			ctaBannerDisplayOnPage
			textWithVideoDisplayOnPage
			textWithImageContainerDisplayOnPage
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
		}
		allDatoCmsEventVenue(filter: { locale: { eq: $locale } }) {
			nodes {
				originalId
				venueLocation {
					latitude
					longitude
				}
			}
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;
