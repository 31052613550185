import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Button } from '../../atoms/button';
import { PBSLink } from '../../atoms/link';
import { SocialShare } from '../social-share';

import {
	datoGatsbyImageProp,
	eventLocationProp,
	refProp,
} from '../../../types';

import PinIcon from '../../../assets/map-pin-outline.inline.svg';
import CalendarIcon from '../../../assets/calendar.inline.svg';
import ClockIcon from '../../../assets/clock.inline.svg';
import HourglassIcon from '../../../assets/hourglass.inline.svg';

import { useDrawer } from '../../../contexts/drawer-context';

import { sendTrackingData } from '../../../utils';

const EventDetails = ({
	image,
	date,
	dateOrdinal,
	time,
	length,
	location,
	className,
	pageUrl,
	drawerRef,
}) => {
	const { setDrawerRef } = useDrawer();
	const mapUrl =
		location.address.value.document.children[0].children[0].value;

	const listItemClasses = 'flex flex-col-reverse relative pl-8';
	const listIconClasses = 'w-[22px] absolute left-0 top-[3px] text-blue-400';

	let alt = '';
	let hasImage;
	let imageData = {};
	if (image && Object.keys(image).length !== 0) {
		alt =
			image.alt ||
			location.venueName ||
			'Peninsula HR & Health and Safty Support';
		imageData = getImage(image);
		hasImage = true;
	} else {
		hasImage = false;
	}

	const submitBtnText = 'Book my place';

	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'event-details',
			click_type: 'primary',
			...trackData,
		});
	};

	return (
		<aside className={clsx('text-xl', className)}>
			{hasImage ? (
				<GatsbyImage
					className={clsx(
						'border-8 drop-shadow-image border-white',
						'max-h-[230px] max-w-full xs:max-h-[320px] md:max-h-[280px]',
						'mb-8 lg:mb-16'
					)}
					image={imageData}
					quality={80}
					alt={alt}
				/>
			) : null}
			<div className="flex-col flex !sticky !top-[18%]">
				<dl className="grid gap-4 mb-8 xs:grid-cols-2 md:!grid-cols-1 md:gap-7 md:mb-10 ">
					<div className={listItemClasses}>
						<dt className="mt-1 text-xs sm:text-sm text-grey-500">
							Date of event
						</dt>
						<dd className="inline-flex items-center text-sm text-brand-blue-400 font-centra-medium sm:text-base-f">
							<CalendarIcon
								className={listIconClasses}
								aria-hidden
							/>
							<time dateTime={date}>{dateOrdinal}</time>
						</dd>
					</div>

					<div className={listItemClasses}>
						<dt className="mt-1 text-xs sm:text-sm text-grey-500">
							Time of event
						</dt>
						<dd className="inline-flex items-center text-sm text-brand-blue-400 font-centra-medium sm:text-base-f">
							<ClockIcon
								className={listIconClasses}
								aria-hidden
							/>
							{time}
						</dd>
					</div>

					{length ? (
						<div className={listItemClasses}>
							<dt className="mt-1 text-xs sm:text-sm text-grey-500">
								Length of event
							</dt>
							<dd className="inline-flex items-center text-sm text-brand-blue-400 font-centra-medium sm:text-base-f">
								<HourglassIcon
									className={listIconClasses}
									aria-hidden
								/>
								{length}
							</dd>
						</div>
					) : null}
				</dl>

				<div className="flex flex-row sm:flex-row md:flex-row  items-center space-x-6 ">
					<Button
						size="Small"
						className="mb-0 sm:mb-0 md:mb-0 "
						onClick={() => {
							setDrawerRef(drawerRef);
							handleClickTracking({
								click_title: `${location.venueName} ${dateOrdinal}`,
								click_text: submitBtnText,
							});
						}}
					>
						<span className="text-sm"> {submitBtnText}</span>
					</Button>

					<PBSLink
						variant="Tiny"
						to={`https://maps.google.com/?q=${mapUrl}`}
						className="w-fit sm:ml-5 md:ml-0 !py-2"
					>
						<PinIcon className="w-[18px] mr-3" aria-hidden="true" />
						Get Directions
					</PBSLink>
				</div>
				{pageUrl ? (
					<>
						<h2 className="mb-4 text-lg mt-9 lg:mt-10 font-centra-medium md:text-xl">
							Share this event:
						</h2>
						<SocialShare
							title={`View Peninsula's event at ${location.venueName} on ${dateOrdinal}`}
							url={pageUrl}
							className="flex items-center [&>li]:mr-6 [&>li:last-child]:mr-0 pl-2"
						/>
					</>
				) : null}
			</div>
		</aside>
	);
};

export { EventDetails };

EventDetails.defaultProps = {
	image: {},
	length: '',
	className: '',
	pageUrl: '',
};

EventDetails.propTypes = {
	image: datoGatsbyImageProp,
	date: PropTypes.string.isRequired,
	dateOrdinal: PropTypes.string.isRequired,
	time: PropTypes.string.isRequired,
	length: PropTypes.string,
	location: eventLocationProp.isRequired,
	pageUrl: PropTypes.string,
	className: PropTypes.string,
	drawerRef: refProp.isRequired,
};
