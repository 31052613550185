// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default/ppc-v2';
import { Head } from '../components/templates/head';
// for modal
import { AdviceShortFormCA } from '../components/organisms/ca/forms/salesforce/advice-short';
import { SuccessModal } from '../components/organisms/ca/forms/salesforce/success-modal';

import { IconCardsPpcV2CA } from '../components/organisms/icon-cards-ppc-v2-ca';
import { HeroPpcV2CA } from '../components/molecules/hero-ppc-v2-ca';
import { Testimonials } from '../components/organisms/testimonials-ppc';
import { TextWithImageContainer } from '../components/molecules/text-with-image-container-ppc-v2-ca';
import { pageTypes } from '../utils/url-helpers';
import { Modal } from '../components/atoms/modal-v2-ca';
import { ClientLogosCA } from '../components/atoms/client-logos-ca';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { useModal } from '../contexts/modal-context';

import { HrefLang } from '../components/templates/hrefLang';
import { CallUs } from '../components/atoms/call-us-ppc-v2';
import CtaBannerPPCV2 from '../components/atoms/cta-banner-ppc-v2';
import { CorCA } from '../components/organisms/cor-ca';

// markup
const PpcV2CAPage = ({ data, location }) => {
	// eslint-disable-next-line no-unused-vars
	const { modalRef, setModalRef } = useModal();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');

	const metaContent = {
		noIndex: data.datoCmsPpcPage.noIndex,
		noFollow: data.datoCmsPpcPage.noFollow,
		metaInformation: data.datoCmsPpcPage.metaInformation,
		fallbackTitle: data.datoCmsPpcPage.title,
		fallbackDescription: data.datoCmsPpcPage.longIntroduction,
		canonicalUrl: data.datoCmsPpcPage?.canonicalUrl || location.href,
	};

	const corContent = {
		title: data.datoCmsPpcPage?.corContainer?.title,
		subText: data.datoCmsPpcPage?.corContainer?.subText,
		corCards: data.datoCmsPpcPage?.corContainer?.corCards,
	};

	const textWithImageContainer = {
		...data.datoCmsPpcPage.textWithImageContainer,
		firsttitle:
			data.datoCmsPpcPage.textWithImageContainer?.firstTitle?.value
				.document.children,
		secondtitle:
			data.datoCmsPpcPage.textWithImageContainer?.secondTitle.value
				.document.children,
		thirdtitle:
			data.datoCmsPpcPage.textWithImageContainer?.thirdTitle.value
				.document.children,
		firstContent: data.datoCmsPpcPage.textWithImageContainer?.firstContent,
		secondContent:
			data.datoCmsPpcPage.textWithImageContainer?.secondContent,
		thirdContent: data.datoCmsPpcPage.textWithImageContainer?.thirdContent,
	};

	const headerCTAContent = {
		...data.datoCmsPpcPage.mainHeader,
		header: data.datoCmsPpcPage.mainHeader.header.value.document.children,
		secondaryHeaderRows: [...data.datoCmsPpcPage.secondaryHeaderRows],
	};

	// const { uspTitle } = data.datoCmsPpcPage;
	const uspBanner = data.datoCmsPpcPage.uspCtaCardsBanner;
	const uspBannerDisplayLinks =
		data.datoCmsPpcPage.uspCtaCardsBannerDisplayLinks;

	const testimonialsContent = {
		...data.datoCmsPpcPage.testimonials,
		title: data.datoCmsPpcPage.testimonials.title.value.document.children,
		slides: data.datoCmsPpcPage.testimonials.slides.map((slide) => ({
			...slide,
			tags: slide.tags.split(','),
		})),
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.service,
			location,
			data: data.datoCmsPpcPage,
		}),
		image: getImageSeoSchema(data.datoCmsPpcPage?.mainHeader?.primaryImage),
		// faq: getFAQSeoSchema(faqContent?.questions),
	};

	const customFormField = {
		customFormTitle:
			data.datoCmsPpcPage?.customFormField[0]?.customFormTitle,
		customFormSubtitle:
			data.datoCmsPpcPage?.customFormField[0]?.customFormSubtitle,
		customFormButton:
			data.datoCmsPpcPage?.customFormField[0]?.customFormButton,
	};

	// for CTA banner
	const ctaBannerContent = {
		bannerContent: data.datoCmsPpcPage?.bannerContent,
		bannerTitle: data.datoCmsPpcPage?.bannerTitle,
		ctaLabel: data.datoCmsPpcPage?.ctaLabel,
	};

	// const { locale: urlLocale } = data.datoCmsPpcPage;
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<div className="block sm:hidden">
				<CallUs />
			</div>
			<HrefLang locales={data.datoCmsPpcPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<HeroPpcV2CA
				modalRef={adviceFormRef}
				{...headerCTAContent}
				ctaLabel={ctaBannerContent.ctaLabel}
			/>

			{/* For largen screens or desktop */}
			{data.datoCmsPpcPage.clientLogosBannerDisplayOnPage && (
				<ClientLogosCA
					pathname={location.pathname}
					className="-mt-5 -mb-20"
				/>
			)}

			{/* For mobile devices */}
			<section
				id="adviceform"
				className="md:hidden lg:hidden xl:hidden border-blue-300 border-2 focus-within:outline-blue-400/[0.20] rounded-sm p-1 mx-4 mb-12 scroll-mt-[175x] md:scroll-mt-[160px] scroll-mt-[140px]"
			>
				{reference === '' ? (
					<AdviceShortFormCA
						setReference={setReference}
						customFormField={customFormField}
						isMobile
					/>
				) : (
					<SuccessModal />
				)}
			</section>

			{/* For mobile devices */}
			{data.datoCmsPpcPage.clientLogosBannerDisplayOnPage && (
				<ClientLogosCA
					pathname={location.pathname}
					isMobile
					className="-mb-10 "
				/>
			)}

			{data.datoCmsPpcPage.uspCtaCardsBannerDisplayOnPage && (
				<>
					<h2 className="mb-12 mt-16 mx-1 text-4xl text-center lg:mr-0 lg:text-4xl font-castledown-regular">
						<span>Here&apos;s how we can support</span>
						<span className="text-brand-red-400 pb-1 bg-no-repeat bg-underline bg-bottom bg-[length:100%_6px] md:bg-[length:100%_8px] index-module--highlight--cabc4">
							{' '}
							your{' '}
						</span>
						<span>business</span>
					</h2>
					<IconCardsPpcV2CA
						// uspTitle={uspTitle}
						iconCardsContent={{
							uspCards: uspBanner,
						}}
						showContentLinks={uspBannerDisplayLinks}
					/>
				</>
			)}

			{data.datoCmsPpcPage.textWithImageContainerDisplayOnPage && (
				<TextWithImageContainer
					textWithImageContainerContent={textWithImageContainer}
					modalRef={adviceFormRef}
				/>
			)}

			{data.datoCmsPpcPage?.corContainerDisplayOnPage && (
				<div className="-mt-10 mb-24 md:-mt-10 md:mb-10 lg:pt-5 lg:mb-0 xl:pb-2 2xl:px-62">
					<CorCA
						modalRef={adviceFormRef}
						corContent={corContent}
						// className="mt-16 mb-22"
					/>
				</div>
			)}

			{data.datoCmsPpcPage?.testimonialsDisplayOnPage && (
				<Testimonials
					modalRef={adviceFormRef}
					testimonialsContent={testimonialsContent}
				/>
			)}

			<CtaBannerPPCV2 ctaBannerContent={ctaBannerContent} />

			{/* For larger screens or deskop */}
			<Modal
				label="Book my free advice call"
				ref={adviceFormRef}
				open={modalRef === adviceFormRef}
				hasClose
				className="!p-0 z-50"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceShortFormCA
						setReference={setReference}
						customFormField={customFormField}
					/>
				) : (
					<SuccessModal className="my-[15%]" />
				)}
			</Modal>
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query service($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsPpcPage(slug: { eq: $slug }, locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			slug
			title
			uspTitle
			clientLogosBannerDisplayOnPage
			feefoTestimonialsBannerDisplayOnPage
			testimonialsDisplayOnPage
			mainHeaderDisplayOnPage
			uspCtaCardsBannerDisplayOnPage
			uspCtaCardsBannerDisplayLinks
			textWithImageContainerDisplayOnPage
			corContainerDisplayOnPage
			mainHeader {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
			}

			secondaryHeaderRows {
				secondaryHeaderRow
			}

			uspCtaCardsBanner {
				title
				content
				linkCopy
				link
				icon
			}
			corContainer {
				title {
					value
				}
				subText
				corCards {
					title
					subText
					buttonCopy
					items {
						text
					}
				}
			}
			textWithImageContainer {
				id
				firstTitle {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				firstButtonCopy
				firstDisplayCtaOnPage
				secondTitle {
					value
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondButtonCopy
				secondDisplayCtaOnPage
				thirdTitle {
					value
				}
				thirdContent {
					value
				}
				thirdImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				thirdButtonCopy
				thirdDisplayCtaOnPage
			}
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}

			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			customFormField {
				customFormTitle
				customFormSubtitle
				customFormButton
			}
			bannerContent
			bannerTitle
			ctaLabel
		}
		datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			logo {
				url
			}
			footerImageCa {
				url
				alt
			}
			footerImage1 {
				url
				alt
			}
			footerImage2 {
				url
				alt
			}
			footerImage3 {
				url
				alt
			}
			footerImage4 {
				url
				alt
			}
			footerImage5 {
				url
				alt
			}
		}
	}
`;

const WrappedPpcV2CA = ({ data, location }) => {
	const footerImages = {
		footerImageCA: data.datoCmsSiteSpecificSetting.footerImageCa,
		footerImage1: data.datoCmsSiteSpecificSetting.footerImage1,
		footerImage2: data.datoCmsSiteSpecificSetting.footerImage2,
		footerImage3: data.datoCmsSiteSpecificSetting.footerImage3,
		footerImage4: data.datoCmsSiteSpecificSetting.footerImage4,
		footerImage5: data.datoCmsSiteSpecificSetting.footerImage5,
	};
	return (
		<DefaultLayout
			siteSettings={data.siteSettings}
			navigation={data.navItems}
			footerData={data.footerItems}
			footerImages={footerImages}
		>
			<PpcV2CAPage data={data} location={location} />
		</DefaultLayout>
	);
};

export default WrappedPpcV2CA;
