// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useDrawer } from '../../contexts/drawer-context';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../../utils/seo-schema';
import { getBreadcrumbs, pageTypes } from '../../utils/url-helpers';
import { HrefLang } from '../../components/templates/hrefLang';
import { Head } from '../../components/templates/head';
import { BreadCrumbs } from '../../components/atoms/breadcrumbs';
import { MainHeader } from '../../components/molecules/main-header';
import { IconCards } from '../../components/organisms/icon-cards';
import { AdviceBanner } from '../../components/organisms/advice-banner';
import { TwoColText } from '../../components/molecules/two-col-text';
import { TrustpilotReviews } from '../../components/molecules/trustpilot-reviews';
import { OurSoftware } from '../../components/molecules/our-software';
import { FeefoTestimonials } from '../../components/molecules/feefo';
import { ReviewBanner } from '../../components/atoms/review-banner';
import { Drawer } from '../../components/atoms/drawer';
import { FAQ } from '../../components/organisms/faq';
import { AdviceForm } from '../../components/organisms/advice-form-sectors';
import { SuccessForm } from '../../components/organisms/success-form';
import { DefaultLayout } from '../../layouts/default';
import { TextWithImageBlock } from '../../components/molecules/text-with-image-block';
import { Testimonials } from '../../components/organisms/testimonials';
import { CardContainer } from '../../components/organisms/card-container';

const SectorPage = ({
	data,
	trustPilotPublicBusinessUnit,
	trustPilotBusinessUnitReviews,
	location,
}) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsChildSectorPage.noIndex,
		noFollow: data.datoCmsChildSectorPage.noFollow,
		metaInformation: data.datoCmsChildSectorPage.metaInformation,
	};
	const testimonialsContent = {
		...data.datoCmsChildSectorPage.testimonials,
		title: data.datoCmsChildSectorPage.testimonials.title.value.document
			.children,
		slides: data.datoCmsChildSectorPage.testimonials.slides.map(
			(slide) => ({
				...slide,
				tags: slide.tags.split(','),
			})
		),
	};
	const adviceBannerContent = {
		...data.datoCmsChildSectorPage.adviceBanner,
		title: data.datoCmsChildSectorPage.adviceBanner.title.value.document
			.children,
	};

	const twoColumnText = {
		...data.datoCmsChildSectorPage.twoColumnText,
		title: data.datoCmsChildSectorPage.twoColumnText.title.value.document
			.children,
		content:
			data.datoCmsChildSectorPage.twoColumnText.content.value.document
				.children,
	};

	const twoColumnTextSecond = {
		...data.datoCmsChildSectorPage.twoColumnTextSecond,
		title: data.datoCmsChildSectorPage.twoColumnTextSecond.title.value
			.document.children,
		content:
			data.datoCmsChildSectorPage.twoColumnTextSecond.content.value
				.document.children,
	};

	const faqContent = {
		title: data.datoCmsChildSectorPage.faqContainer.title,
		subText: data.datoCmsChildSectorPage.faqContainer.subText,
		questions: data.datoCmsChildSectorPage.faqContainer.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};

	const uspBanner = data.datoCmsChildSectorPage.uspCtaCardsBanner;
	const uspBannerDisplayLinks =
		data.datoCmsChildSectorPage.uspCtaCardsBannerDisplayLinks;

	const reviewBannerContent = {
		title: data.datoCmsChildSectorPage.reviewBannerTitle,
		subText: data.datoCmsChildSectorPage.reviewBannerSubtext,
		reviewer: data.datoCmsChildSectorPage.reviewBannerReviewer,
	};
	const parentData = {
		title: data.allDatoCmsSectorsPage.nodes[0].internalTitle,
		slug: data.allDatoCmsSectorsPage.nodes[0].slug,
	};
	const showHealthSafetyTrustpilotReview =
		parentData.slug.includes('health-safety');
	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.childSector,
			location,
			data: data.datoCmsChildSectorPage,
			parentPageData: parentData,
		}),
		image: getImageSeoSchema(
			data.datoCmsChildSectorPage?.mainHeader?.primaryImage
		),
	};
	const blogs = data.allDatoCmsBlogPage.nodes;
	const guides = data.allDatoCmsGuidePage.nodes;
	// add the blog and guide nodes to the related content container
	const mergedContent = [...blogs, ...guides].sort((a, b) =>
		new Date(a.publishedDate) > new Date(b.publishedDate) ? -1 : 1
	);
	const relatedPageHeader = {
		id: data.datoCmsChildSectorPage.id,
		title: data.datoCmsChildSectorPage.relatedPagesTitle,
		subText: '',
		buttonCopy: 'Read more',
	};
	const blogContainerContent = {
		...relatedPageHeader,
		cards: mergedContent.slice(0, 3),
	};
	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsChildSectorPage.internal.type,
		data.datoCmsChildSectorPage,
		parentData
	);
	const { locale } = data.datoCmsChildSectorPage;
	const headerCTAContent = {
		...data.datoCmsChildSectorPage.mainHeader,
		header: data.datoCmsChildSectorPage.mainHeader.header.value.document
			.children,
		trustpilot: showHealthSafetyTrustpilotReview,
	};
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsChildSectorPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			{data.datoCmsChildSectorPage.mainHeaderDisplayOnPage && (
				<MainHeader
					urlLocale={locale}
					drawerRef={adviceFormRef}
					{...headerCTAContent}
				/>
			)}

			{data.datoCmsChildSectorPage.textImageDisplayOnPage && (
				<TextWithImageBlock
					drawerRef={adviceFormRef}
					textWithImageContent={
						data.datoCmsChildSectorPage.textWithImageBlockFirst
					}
					className="!mt-10 "
				/>
			)}

			{data.datoCmsChildSectorPage.uspCtaCardsBannerDisplayOnPage && (
				<IconCards
					iconCardsContent={{
						uspCards: uspBanner,
					}}
					showContentLinks={uspBannerDisplayLinks}
				/>
			)}
			{data.datoCmsChildSectorPage.textImageSecondDisplayOnPage && (
				<TextWithImageBlock
					drawerRef={adviceFormRef}
					textWithImageContent={
						data.datoCmsChildSectorPage.textWithImageBlockSecond
					}
				/>
			)}
			{data.datoCmsChildSectorPage.textImageThirdDisplayOnPage && (
				<TextWithImageBlock
					drawerRef={adviceFormRef}
					textWithImageContent={
						data.datoCmsChildSectorPage.textWithImageBlockThird
					}
					className="!mt-10"
				/>
			)}

			{data.datoCmsChildSectorPage.textImageFourthDisplayOnPage && (
				<TextWithImageBlock
					drawerRef={adviceFormRef}
					textWithImageContent={
						data.datoCmsChildSectorPage.textWithImageBlockFourth
					}
					className="!mt-10"
				/>
			)}
			{data.datoCmsChildSectorPage.testimonialsDisplayOnPage && (
				<section className="px-4 mx-auto max-w-m-screen bg-brand-pale-400">
					<Testimonials
						drawerRef={adviceFormRef}
						hasBottomCtas={false}
						testimonialsContent={testimonialsContent}
					/>
				</section>
			)}
			{data.datoCmsChildSectorPage.relatedPagesDisplayOnPage &&
				mergedContent.length > 0 && (
					<CardContainer
						cardContainerContent={{
							...blogContainerContent,
							to: '/resource-hub/blogs',
							toAll: '/resource-hub/blogs',
						}}
						cardVariant="Blog"
						variant="Center"
						location={location.pathname}
					/>
				)}
			{data.datoCmsChildSectorPage.textImageFifthDisplayOnPage && (
				<TextWithImageBlock
					drawerRef={adviceFormRef}
					textWithImageContent={
						data.datoCmsChildSectorPage.textWithImageBlockFifth
					}
				/>
			)}
			{data.datoCmsChildSectorPage.textImageSixthDisplayOnPage && (
				<TextWithImageBlock
					drawerRef={adviceFormRef}
					textWithImageContent={
						data.datoCmsChildSectorPage.textWithImageBlockSixth
					}
					className="!mt-10"
				/>
			)}
			{data.datoCmsChildSectorPage.twoColumnTextDisplayOnPage && (
				<TwoColText
					drawerRef={adviceFormRef}
					textColTextContent={twoColumnText}
				/>
			)}
			{data.datoCmsChildSectorPage.ourSoftwareDisplayOnPage && (
				<OurSoftware
					drawerRef={adviceFormRef}
					variant="Dark"
					ourSoftwareContent={data.datoCmsChildSectorPage.ourSoftware}
				/>
			)}
			{data.datoCmsChildSectorPage.trustPilotBannerDisplayOnPage && (
				<TrustpilotReviews
					locale={locale}
					businessUnit={trustPilotPublicBusinessUnit}
					reviews={trustPilotBusinessUnitReviews}
				/>
			)}
			{data.datoCmsChildSectorPage.twoColumnTextSecondDisplayOnPage && (
				<TwoColText
					drawerRef={adviceFormRef}
					textColTextContent={twoColumnTextSecond}
				/>
			)}
			{data.datoCmsChildSectorPage.faqContainerDisplayOnPage && (
				<FAQ faqContent={faqContent} />
			)}

			{data.datoCmsChildSectorPage.reviewBannerDisplayOnPage && (
				<ReviewBanner reviewBannerContent={reviewBannerContent} />
			)}
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			{data.datoCmsChildSectorPage
				.feefoTestimonialsBannerDisplayOnPage && (
				<FeefoTestimonials locale={locale} />
			)}
			{data.datoCmsChildSectorPage.adviceBannerDisplayOnPage && (
				<AdviceBanner
					drawerRef={adviceFormRef}
					adviceBannerContent={adviceBannerContent}
				/>
			)}
			<Drawer
				label="Book my free advice call"
				ref={adviceFormRef}
				open={drawerRef === adviceFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceForm
						setReference={setReference}
						setResponse={setResponse}
					/>
				) : (
					<SuccessForm reference={reference} response={response} />
				)}
			</Drawer>
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query sectorPage(
		$slug: String!
		$locale: String!
		$category: String!
		$tag: String!
	) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsChildSectorPage(locale: { eq: $locale }, slug: { eq: $slug }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			internal {
				type
			}
			slug
			title
			category
			adviceBannerDisplayOnPage
			feefoTestimonialsBannerDisplayOnPage
			mainHeaderDisplayOnPage
			ourSoftwareDisplayOnPage
			faqContainerDisplayOnPage
			trustPilotBannerDisplayOnPage
			twoColumnTextDisplayOnPage
			twoColumnTextSecondDisplayOnPage
			uspCtaCardsBannerDisplayOnPage
			uspCtaCardsBannerDisplayLinks
			reviewBannerDisplayOnPage
			relatedPagesDisplayOnPage
			mainHeader {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
			}
			textWithImageBlockFirst {
				id
				headerTitle
				imagePosition
				hasCta
				ctaText
				showTitle
				ctaOpenForm
				ctaLink
				video {
					url
					title
					thumbnailUrl
					provider
					providerUid
					width
					height
				}
				image {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				content {
					links
					value
					blocks {
						... on DatoCmsTextWithLink {
							__typename
							id: originalId
							textContent {
								value
								links
								blocks
							}
							model {
								apiKey
							}
						}
					}
				}
			}
			textImageDisplayOnPage
			textWithImageBlockSecond {
				id
				headerTitle
				imagePosition
				hasCta
				ctaText
				showTitle
				ctaOpenForm
				ctaLink
				video {
					url
					title
					thumbnailUrl
					provider
					providerUid
					width
					height
				}
				image {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				content {
					links
					value
					blocks {
						... on DatoCmsTextWithLink {
							__typename
							id: originalId
							textContent {
								value
								links
								blocks
							}
							model {
								apiKey
							}
						}
					}
				}
			}
			textImageSecondDisplayOnPage
			textWithImageBlockThird {
				id
				headerTitle
				imagePosition
				hasCta
				ctaText
				ctaOpenForm
				ctaLink
				video {
					url
					title
					thumbnailUrl
					provider
					providerUid
					width
					height
				}
				showTitle
				image {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				content {
					links
					value
					blocks {
						... on DatoCmsTextWithLink {
							__typename
							id: originalId
							textContent {
								value
								links
								blocks
							}
							model {
								apiKey
							}
						}
					}
				}
			}
			textImageThirdDisplayOnPage
			textWithImageBlockFourth {
				id
				headerTitle
				imagePosition
				hasCta
				ctaText
				ctaOpenForm
				ctaLink
				showTitle
				video {
					url
					title
					thumbnailUrl
					provider
					providerUid
					width
					height
				}
				image {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				content {
					links
					value
					blocks {
						... on DatoCmsTextWithLink {
							__typename
							id: originalId
							textContent {
								value
								links
								blocks
							}
							model {
								apiKey
							}
						}
					}
				}
			}
			textImageFourthDisplayOnPage
			testimonialsDisplayOnPage
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
			textWithImageBlockFifth {
				id
				headerTitle
				imagePosition
				hasCta
				ctaText
				ctaOpenForm
				ctaLink
				showTitle
				video {
					url
					title
					thumbnailUrl
					provider
					providerUid
					width
					height
				}
				image {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				content {
					links
					value
					blocks {
						... on DatoCmsTextWithLink {
							__typename
							id: originalId
							textContent {
								value
								links
								blocks
							}
							model {
								apiKey
							}
						}
					}
				}
			}
			textImageFifthDisplayOnPage
			textWithImageBlockSixth {
				id
				headerTitle
				imagePosition
				hasCta
				ctaText
				ctaOpenForm
				ctaLink
				showTitle
				video {
					url
					title
					thumbnailUrl
					provider
					providerUid
					width
					height
				}
				image {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				content {
					links
					value
					blocks {
						... on DatoCmsTextWithLink {
							__typename
							id: originalId
							textContent {
								value
								links
								blocks
							}
							model {
								apiKey
							}
						}
					}
				}
			}
			textImageSixthDisplayOnPage
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			twoColumnText {
				id
				title {
					value
				}
				content {
					value
				}
				primaryLinkCopy
				primaryLink
				primaryButtonOpenForm
				secondaryLinkCopy
				secondaryLink
			}
			ourSoftware {
				title
				content
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
				mobileImage {
					alt
					url
					gatsbyImageData(
						width: 230
						height: 277
						layout: CONSTRAINED
					)
				}
				desktopImage {
					alt
					url
					gatsbyImageData(
						width: 280
						height: 568
						layout: CONSTRAINED
					)
				}
				tickList {
					text
					subText
				}
			}
			twoColumnTextSecond {
				id
				title {
					value
				}
				content {
					value
				}
				primaryLinkCopy
				primaryLink
				primaryButtonOpenForm
				secondaryLinkCopy
				secondaryLink
			}
			faqContainer {
				title
				subText
				questions {
					question
					answer {
						value
					}
				}
			}
			uspCtaCardsBanner {
				title
				content
				linkCopy
				link
				icon
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			reviewBannerTitle
			reviewBannerSubtext
			reviewBannerReviewer
			reviewBannerTitle
			reviewBannerSubtext
			reviewBannerReviewer
			relatedPagesTitle
		}
		allDatoCmsBlogPage(
			sort: { order: DESC, fields: created }
			limit: 3
			filter: {
				slug: { ne: null }
				locale: { eq: $locale }
				sectors: { elemMatch: { name: { eq: $tag } } }
			}
		) {
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
				externalLink
			}
		}
		allDatoCmsGuidePage(
			sort: { order: DESC, fields: created }
			limit: 3
			filter: {
				slug: { ne: null }
				locale: { eq: $locale }
				sectors: { elemMatch: { name: { eq: $tag } } }
			}
		) {
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
			}
		}
		allDatoCmsSectorsPage(
			sort: { fields: title, order: ASC }
			filter: { locale: { eq: $locale }, slug: { eq: $category } }
		) {
			nodes {
				slug
				internalTitle
				locale
			}
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;

const WrappedService = ({ data, pageContext, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<SectorPage
			data={data}
			pageDirectory={pageContext.pageDirectory}
			trustPilotPublicBusinessUnit={
				pageContext.trustPilotPublicBusinessUnit
			}
			trustPilotBusinessUnitReviews={
				pageContext.trustPilotBusinessUnitReviews
			}
			location={location}
		/>
	</DefaultLayout>
);

export default WrappedService;
