import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ footerContent, footerImages }) => (
	<footer className="font-centra-light">
		<div className="p-5 text-sm text-white md:p-10 bg-brand-blue-400">
			<div className="mx-auto lg:container">
				<div className="flex flex-col gap-8 lg:gap-20 lg:flex-row">
					<div className="flex flex-wrap">
						<p className="w-full mt-4 leading-6 lg:w-12/12">
							{footerContent.footerCopyright}
						</p>
					</div>
					<div className="flex flex-wrap items-center gap-4 shrink-0">
						{footerImages.footerImageCA && (
							<img
								src={footerImages.footerImageCA.url}
								alt={footerImages.footerImageCA.alt}
								width="550"
							/>
						)}
						{footerImages.footerImage1 && (
							<img
								src={footerImages.footerImage1.url}
								alt={footerImages.footerImage1.alt}
								width="67"
								height="80"
							/>
						)}
						{footerImages.footerImage2 && (
							<img
								src={footerImages.footerImage2.url}
								alt={footerImages.footerImage2.alt}
								width="85"
							/>
						)}
						{footerImages.footerImage3 && (
							<img
								src={footerImages.footerImage3.url}
								alt={footerImages.footerImage3.alt}
								width="175"
							/>
						)}
						{footerImages.footerImage4 && (
							<img
								src={footerImages.footerImage4.url}
								alt={footerImages.footerImage4.alt}
								width="64"
							/>
						)}
						{footerImages.footerImage5 && (
							<img
								src={footerImages.footerImage5.url}
								alt={footerImages.footerImage5.alt}
								width="53"
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	</footer>
);

Footer.propTypes = {
	footerContent: PropTypes.shape({
		footerNavigation: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				treeChildren: PropTypes.arrayOf(
					PropTypes.shape({
						link: PropTypes.string.isRequired,
						title: PropTypes.string.isRequired,
					})
				).isRequired,
			})
		).isRequired,
		footerAddressTitle: PropTypes.string.isRequired,
		footerAddress: PropTypes.string.isRequired,
		footerSocialTitle: PropTypes.string.isRequired,
		footerInternationalSitesCopy: PropTypes.string.isRequired,
		footerInternationalSites: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				link: PropTypes.string.isRequired,
			})
		).isRequired,
		footerCopyright: PropTypes.string.isRequired,
	}).isRequired,

	footerImages: PropTypes.shape({
		footerImageCA: PropTypes.arrayOf(
			PropTypes.shape({
				url: PropTypes.string.isRequired,
				alt: PropTypes.string.isRequired,
			})
		).isRequired,
		footerImage1: PropTypes.arrayOf(
			PropTypes.shape({
				url: PropTypes.string.isRequired,
				alt: PropTypes.string.isRequired,
			})
		).isRequired,
		footerImage2: PropTypes.arrayOf(
			PropTypes.shape({
				url: PropTypes.string.isRequired,
				alt: PropTypes.string.isRequired,
			})
		).isRequired,
		footerImage3: PropTypes.arrayOf(
			PropTypes.shape({
				url: PropTypes.string.isRequired,
				alt: PropTypes.string.isRequired,
			})
		).isRequired,
		footerImage4: PropTypes.arrayOf(
			PropTypes.shape({
				url: PropTypes.string.isRequired,
				alt: PropTypes.string.isRequired,
			})
		).isRequired,
		footerImage5: PropTypes.arrayOf(
			PropTypes.shape({
				url: PropTypes.string.isRequired,
				alt: PropTypes.string.isRequired,
			})
		).isRequired,
	}).isRequired,
};

export { Footer };
