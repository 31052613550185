import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik, Field, FormikProvider } from 'formik';
import clsx from 'clsx';
import loadScript from 'load-script2';

import { Button } from '../../atoms/button';
import { TextInput } from '../../atoms/text-input';
import { CallUs } from '../../atoms/call-us';
import { SidebarHeader } from '../../atoms/sidebar-header';
import { PBSLink } from '../../atoms/link';

import { sleep, sendTrackingData, getCookie } from '../../../utils';
import {
	validateString,
	validateEmail,
	validatePhoneNumber,
} from '../../../utils/marketo';

import { useMarketoFormSubmit } from '../../../hooks/marketo';
import { useMarketo } from '../../../contexts/marketo-context';

const DownloadForm = ({
	setResponseSent,
	downloadId,
	pageUrl,
	locale,
	leadSource = 'Website - Whitepaper Download',
	formId = 4415,
	formType = 'whitepaper',
	title = 'Get your free download',
	subText = 'Tell us a few details and we’ll send your free download straight to your inbox',
}) => {
	const MktoForms2Instances = useMarketo();
	const inputRefs = useRef({
		LastName: undefined, // Full Name
		Company: undefined,
		Title: undefined,
		Email: undefined,
		Phone: undefined,
		PostalCode: undefined,
	});

	const [technicalError, setTechnicalError] = useState(false);

	const [referralCookie, setReferralCookie] = useState('');
	const [keywordCookie, setKeywordCookie] = useState('');
	const [mediumCookie, setMediumCookie] = useState('');
	const [campaignCookie, setCampaignCookie] = useState('');
	const [msclkidCookie, setMsclkidCookie] = useState('');
	const [msclkidLastCookie, setMsclkidLastCookie] = useState('');
	const [gclidCookie, setGclidCookie] = useState('');
	const [gclidLastCookie, setGclidLastCookie] = useState('');
	const [campaignLastCookie, setCampaignLastCookie] = useState('');
	const [contentLastCookie, setContentLastCookie] = useState('');
	const [mediumLastCookie, setMediumLastCookie] = useState('');
	const [sourceLastCookie, setSourceLastCookie] = useState('');
	const [termLastCookie, setTermLastCookie] = useState('');
	const [googleCookie, setGoogleCookie] = useState('');
	const [socialId, setSocialId] = useState('');
	const [formDetails, setformDetails] = useState({
		id: '',
		url: '',
		marketoId: '',
	});

	useEffect(() => {
		if (!locale) return;
		if (formId) {
			switch (locale) {
				case 'en-CA':
					setformDetails({
						id: 4766,
						url: 'https://app-ab31.marketo.com',
						marketoId: '544-LUT-177',
					});
					break;
				case 'en-IE':
					setformDetails({
						id: 7387,
						url: 'https://app-lon04.marketo.com',
						marketoId: '023-IMK-845',
					});
					break;
				default:
					setformDetails({
						id: formId,
						url: 'https://app-lon04.marketo.com',
						marketoId: '023-IMK-845',
					});
			}
		}
	}, [locale, formId]);

	const submitButtonText =
		formType === 'SafeCheck'
			? 'Get my sample report'
			: 'Get my free download';

	useEffect(() => {
		// eslint-disable-next-line compat/compat
		const urlObj = new URL(pageUrl);
		setSocialId(urlObj.searchParams.get('social_id'));
	}, [pageUrl]);

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: title,
		});
	};

	const formik = useFormik({
		initialValues: {
			LastName: '', // Full Name
			Email: '',
			Phone: '',
			Company: '',
			Title: '',
			PostalCode: '',
			PersonSource: leadSource,
			LeadSource: leadSource,
			guideID: downloadId,
			referalSource: referralCookie,
			keyword: keywordCookie,
			utm_medium: mediumCookie,
			utm_campaign: campaignCookie,
			MSCLKID__c: msclkidCookie,
			MSCLKID_last: msclkidLastCookie,
			GCLID__c: gclidCookie,
			gCLID_last: gclidLastCookie,
			utm_campaign_last__c: campaignLastCookie,
			utm_content_last__c: contentLastCookie,
			utm_medium_last__c: mediumLastCookie,
			utm_source_last__c: sourceLastCookie,
			utm_term_last__c: termLastCookie,
			Google_Cookie_ID__c: googleCookie,
			socialCampaignID: socialId,
		},
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: async (data) => {
			await sleep(500);
			useMarketoFormSubmit(data, formDetails.id, MktoForms2Instances)
				.then(() => {
					formik.setStatus('submitted');
					setResponseSent(true);
				})
				.catch(() => {
					formik.setStatus('error');
					setTechnicalError(true);
				})
				.finally(() => {
					handleClickTracking();
				});
		},
	});

	useEffect(() => {
		if (Object.keys(formik.errors).length > 0) {
			const inputRef = inputRefs.current[Object.keys(formik.errors)[0]];
			return inputRef.focus();
		}
	}, [formik.errors, formik.isSubmitting]);

	useEffect(() => {
		const loadForm = () =>
			loadScript(`${formDetails.url}/js/forms2/js/forms2.min.js`)
				.then(() => {
					MktoForms2Instances.get(`${formDetails.url}`).loadForm(
						`${formDetails.url}`,
						`${formDetails.marketoId}`,
						`${formDetails.id}`
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}

		setReferralCookie(getCookie('utm_source'));
		setKeywordCookie(getCookie('utm_term'));
		setMediumCookie(getCookie('utm_medium'));
		setCampaignCookie(getCookie('utm_campaign'));
		setMsclkidCookie(getCookie('msclkid'));
		setMsclkidLastCookie(getCookie('msclkid_last'));
		setGclidCookie(getCookie('gclid'));
		setGclidLastCookie(getCookie('gclid_last'));
		setCampaignLastCookie(getCookie('utm_campaign_last'));
		setContentLastCookie(getCookie('utm_content_last'));
		setMediumLastCookie(getCookie('utm_medium_last'));
		setSourceLastCookie(getCookie('utm_source_last'));
		setTermLastCookie(getCookie('utm_term_last'));
		setGoogleCookie(getCookie('Google_Cookie_ID__c'));
	}, [formDetails, MktoForms2Instances]);

	return technicalError ? (
		<>
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</>
	) : (
		<FormikProvider value={formik}>
			<form
				data-formid={formDetails.id}
				data-forminstance="one"
				onSubmit={formik.handleSubmit}
				className="flex flex-col justify-between w-full mx-auto grow"
			>
				<SidebarHeader heading={title} text={subText} />
				<div className="flex flex-col grow">
					<fieldset className="flex flex-col px-4 pt-10 bg-white grow">
						<legend className="sr-only">
							Enter your personal details
						</legend>

						<Field name="LastName" validate={validateString}>
							{({ field }) => (
								<TextInput
									id="downloadName"
									name="LastName"
									value={formik.values.LastName}
									labelText="Full name"
									aria-required="true"
									className="w-full wrapper-small"
									ref={(node) => {
										inputRefs.current.LastName = node;
									}}
									showValidation={
										!!(
											formik.errors.LastName &&
											formik.touched.LastName
										)
									}
									validationMessage={
										formik.errors.LastName
											? 'Please enter your full name'
											: null
									}
									{...field}
								/>
							)}
						</Field>

						<Field name="Company" validate={validateString}>
							{({ field }) => (
								<TextInput
									id="downloadCompany"
									name="Company"
									value={formik.values.Company}
									labelText="Company"
									aria-required="true"
									className="w-full wrapper-small"
									ref={(node) => {
										inputRefs.current.Company = node;
									}}
									showValidation={
										!!(
											formik.errors.Company &&
											formik.touched.Company
										)
									}
									validationMessage={
										formik.errors.Company
											? 'Please enter your company'
											: null
									}
									{...field}
								/>
							)}
						</Field>

						<Field name="Title" validate={validateString}>
							{({ field }) => (
								<TextInput
									id="downloadJobTitle"
									name="Title"
									value={formik.values.Title}
									labelText="Job title"
									aria-required="true"
									className="w-full wrapper-small"
									ref={(node) => {
										inputRefs.current.Title = node;
									}}
									showValidation={
										!!(
											formik.errors.Title &&
											formik.touched.Title
										)
									}
									validationMessage={
										formik.errors.Title
											? 'Please enter your job title'
											: null
									}
									{...field}
								/>
							)}
						</Field>

						<hr className="mt-1 mb-10 border-blue-300" />

						<Field name="Email" validate={validateEmail}>
							{({ field }) => (
								<TextInput
									id="downloadEmail"
									type="email"
									name="Email"
									value={formik.values.Email}
									labelText="Email address"
									aria-required="true"
									className="w-full wrapper-small"
									ref={(node) => {
										inputRefs.current.Email = node;
									}}
									showValidation={
										!!(
											formik.errors.Email &&
											formik.touched.Email
										)
									}
									validationMessage={formik.errors.Email}
									{...field}
								/>
							)}
						</Field>

						<Field name="Phone" validate={validatePhoneNumber}>
							{({ field }) => (
								<TextInput
									id="downloadPhone"
									name="Phone"
									value={formik.values.Phone}
									labelText="Phone number"
									aria-required="true"
									className="w-full wrapper-small"
									ref={(node) => {
										inputRefs.current.Phone = node;
									}}
									showValidation={
										!!(
											formik.errors.Phone &&
											formik.touched.Phone
										)
									}
									validationMessage={formik.errors.Phone}
									{...field}
								/>
							)}
						</Field>

						{formType === 'whitepaper' && (
							<Field name="PostalCode" validate={validateString}>
								{({ field }) => (
									<TextInput
										id="downloadPostcode"
										name="PostalCode"
										value={formik.values.PostalCode}
										labelText="Postcode"
										aria-required="true"
										className="w-full max-w-[200px] !mb-2 wrapper-small"
										ref={(node) => {
											inputRefs.current.PostalCode = node;
										}}
										showValidation={
											!!(
												formik.errors.PostalCode &&
												formik.touched.PostalCode
											)
										}
										validationMessage={
											formik.errors.PostalCode
												? 'Please enter your postcode'
												: null
										}
										{...field}
									/>
								)}
							</Field>
						)}
					</fieldset>

					<p className="px-4 text-base-f">
						View our{' '}
						<PBSLink
							to="https://www.peninsulagrouplimited.com/privacypolicy/"
							variant="Link"
						>
							privacy notice
						</PBSLink>
						.
					</p>
				</div>

				<div className="px-4 py-12 bg-white">
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								formik.isSubmitting
									? 'justify-center loading'
									: null
							)}
						>
							<span
								className={clsx(
									formik.isSubmitting ? 'invisible' : null
								)}
							>
								{submitButtonText}
							</span>
							{formik.isSubmitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
				</div>
			</form>
		</FormikProvider>
	);
};

DownloadForm.propTypes = {
	setResponseSent: PropTypes.func.isRequired,
	leadSource: PropTypes.string.isRequired,
	downloadId: PropTypes.string.isRequired,
	pageUrl: PropTypes.string.isRequired,
	locale: PropTypes.string.isRequired,
	formId: PropTypes.number.isRequired,
	formType: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	subText: PropTypes.string.isRequired,
};

export { DownloadForm };
