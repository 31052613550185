import { useState } from 'react';

export const useFormValidation = (initialValidations) => {
	const [validations, setValidations] = useState(initialValidations);
	const phoneRegex = /^(?:[+\d].*\d|\d)$/;
	const emailAddressRegex =
		/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/;
	const jobTitleRegex = /^[a-z\d]{2,}/i;
	const noEmployeesRegex = /^\d+$/;
	const formatFormEntry = (entry) => {
		const key = entry[0];
		const value = entry[1];

		if (key === 'phoneNumber' || key === 'Phone') {
			return {
				key,
				value,
				isValid: phoneRegex.test(value),
			};
		}
		if (key === 'noEmployees') {
			return {
				key,
				value,
				isValid: noEmployeesRegex.test(value),
			};
		}

		if (key === 'emailAddress' || key === 'email') {
			return {
				key,
				value,
				isValid: emailAddressRegex.test(value),
			};
		}

		if (key === 'addressLine2' || key === 'jobTitle') {
			return {
				key,
				value,
				isValid: true,
			};
		}

		if (key === 'JobTitle') {
			return {
				key,
				value,
				isValid: jobTitleRegex.test(value),
			};
		}

		return { key, value, isValid: value !== '' };
	};

	const formatFormEntryPeninsulaNow = (entry) => {
		const key = entry[0];
		const value = entry[1];

		if (key === 'phoneNumber' || key === 'Phone') {
			return {
				key,
				value,
				isValid: phoneRegex.test(value),
			};
		}
		if (key === 'noEmployees') {
			return {
				key,
				value,
				isValid: noEmployeesRegex.test(value),
			};
		}

		if (key === 'emailAddress' || key === 'email') {
			return {
				key,
				value,
				isValid: emailAddressRegex.test(value),
			};
		}

		if (
			key === 'addressLine2' ||
			key === 'company' ||
			key === 'jobTitle' ||
			key === 'firstName' ||
			key === 'lastName' ||
			key === 'isEmployer'
		) {
			return {
				key,
				value,
				isValid: true,
			};
		}

		if (key === 'JobTitle') {
			return {
				key,
				value,
				isValid: jobTitleRegex.test(value),
			};
		}

		return { key, value, isValid: value !== '' };
	};

	const formatFormValidation = (entry) => ({
		[entry.key]: { value: entry.value, isValid: entry.isValid },
	});

	const formatSalesforceData = (entry) => ({
		[entry.key]: entry.value,
	});

	const validateForm = (form) => {
		const formData = new FormData(form);
		const formEntries = Array.from(formData.entries()).map(formatFormEntry);
		const formValidations = formEntries.map(formatFormValidation);
		const formEntriesObject = Object.assign({}, ...formValidations);
		const salesforceData = formEntries.map(formatSalesforceData);

		setValidations(formEntriesObject);

		if (formEntries.some((entry) => entry.isValid === false)) {
			return { isValid: false };
		}

		// formEntries needed in case we call off to salesforce after form validation
		return {
			formEntries: Object.assign({}, ...salesforceData),
			isValid: true,
		};
	};

	const validateFormPeninsulaNow = (form) => {
		const formData = new FormData(form);
		const formEntries = Array.from(formData.entries()).map(
			formatFormEntryPeninsulaNow
		);
		const formValidations = formEntries.map(formatFormValidation);
		const formEntriesObject = Object.assign({}, ...formValidations);
		const salesforceData = formEntries.map(formatSalesforceData);

		setValidations(formEntriesObject);

		if (formEntries.some((entry) => entry.isValid === false)) {
			return { isValid: false };
		}

		// formEntries needed in case we call off to salesforce after form validation
		return {
			formEntries: Object.assign({}, ...salesforceData),
			isValid: true,
		};
	};
	return {
		validations,
		validateForm,
		validateFormPeninsulaNow,
	};
};
